import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getStorage } from '../../helpers/Storage';

interface ProtectedRouteProps {
  component: JSX.Element;
}

const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
  const isLogged = getStorage('isLogged') === 'true';
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  if (isLogged || params.get('token')) {
    return component;
  }

  return <Navigate to="/login" />;
};

export default ProtectedRoute;
