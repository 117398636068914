import React from 'react';
import './index.css';

type units = 'em' | 'rem' | 'px' | '%';

interface LoaderProps {
  show: Boolean;
  props?: Object;
  width?: `${number}${units}`;
}

function Loader({ width = '48px', show, props }: LoaderProps) {
  return (
    show && (
      <>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={width}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid meet"
          className="loader-animation-svg"
          {...props}
        >
          <rect
            width="33%"
            height="33%"
            rx="16.5%"
            fill="#A2DD21"
            x="66%"
            className="green-loader-bar"
          ></rect>
          <rect
            width="33%"
            height="33%"
            rx="16.5%"
            fill="#A79CFF"
            x="33%"
            className="purple-loader-bar"
          ></rect>
          <rect
            width="33%"
            height="33%"
            rx="16.5%"
            fill="#5A6BFF"
            className="blue-loader-bar"
          ></rect>
        </svg>
      </>
    )
  );
}

export default Loader;
