import { Outlet } from 'react-router-dom';

import closeIcon from '../../assets/svg/closeIcon.svg';
import LogoHorizontal from '../../assets/svg/coloredHorizontalLogo.svg';
import facebook from '../../assets/svg/facebook.svg';
import hamburgerIcon from '../../assets/svg/hamburguer.svg';
import logo from '../../assets/svg/horizontalLogo.svg';
import instagram from '../../assets/svg/instagram.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import youtube from '../../assets/svg/youtube.svg';

import Footer from '../../components/Footer';
import Header from '../../components/Header';

export function DefaultLayout() {
  return (
    <>
      <div>
        <Header
          logo={logo}
          closeIcon={closeIcon}
          hamburgerIcon={hamburgerIcon}
          hasRecord="Já tem cadastro?"
          login="Faça seu login"
        />
        <main>
          <Outlet />
        </main>
      </div>
      <Footer
        logo={LogoHorizontal}
        instagramSvg={instagram}
        youtubeSvg={youtube}
        facebookSvg={facebook}
        linkedinSvg={linkedin}
        titleSocialMedias="Acompanhe a gente"
        legalText="Via S.A. - CNPJ: 33.041.260/0652-90 Av. Rebouças, 3970 28º andar - Pinheiros - São Paulo, SP - CEP: 05402-918"
        TermsUse="Termos de uso"
        PrivacyPolicy="Política de privacidade"
      />
    </>
  );
}
