import React, { useEffect } from 'react';

import './index.css';

import ImageMobile from '../../assets/svg/panelImageMobile2.svg';
import Image from '../../assets/svg/panelImage.svg';
import Panel from '../../components/Panel';
import { gtmAttributesReceiveEmail } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';
import { gtmAttributesUpdateConfirmationEmailPage } from '../../utils/gtmVariables';

const gtmEventsTracker = useGTMEventsTracker();

const ConfirmationEmail = () => {
  gtmEventsTracker(gtmAttributesUpdateConfirmationEmailPage);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Panel
        title="Confirme o email que enviamos pra você"
        description=""
        panelType="confirmation"
        link="/resend-email"
        linkText="Não recebi o email"
        imageMobile={ImageMobile}
        image={Image}
        className="confirmation-email-panel"
        onClick={() => gtmEventsTracker(gtmAttributesReceiveEmail)}
      >
        <p className="panel-description">
          Por favor, <span className="text-bold">verifique a sua caixa de entrada e de spam. </span>
          Ao encontrar, abra o link que está no email pra confirmar que recebeu.
        </p>
      </Panel>
    </div>
  );
};

export default ConfirmationEmail;
