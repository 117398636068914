import styled from 'styled-components';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import color from '../../utils/colors';

const List = styled.li`
  list-style: none;
  @media (min-width: 1024px) {
    padding: 0 0.5rem;
  }
`;

const Dots = styled.ul`
  &.slick-dots li {
    & button:before {
      margin-top: 1rem;
      font-size: 0.8rem;
      color: ${color.neutral_light};
      opacity: 1;
    }
    &.slick-active button:before {
      color: ${color.primary_dark};
    }
  }
`;

export default {
  List,
  Dots,
};
