import styled from 'styled-components';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import color from '../../utils/colors';

const Div = styled.div`
  position: absolute;
  right: 4.5rem;
  background-color: ${color.secondary_darkest};
  padding: 0.5rem;
  opacity: 1;
  color: ${color.white};

  @media (max-width: 575px) {
    top: -18px;
    right: 2%;
  }

  &:hover {
    opacity: 0.9;
  }
`;

export default {
  Div,
};
