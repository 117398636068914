import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { components, CSSObjectWithLabel } from 'react-select';

import './index.css';

import Search from '../../assets/svg/search.svg';

import StepInterface from '../../interfaces/Step';
import HtmlInput from '../../interfaces/HtmlInput';
import RequestStatus from '../../interfaces/RequestStatus';
import StepClass from '../../entities/Step';

import validations from '../../helpers/Validations';
import { responseMensagemStatus } from '../../helpers/translate';

import ProgressStep from '../../components/ProgressStep';
import ProgressBar from '../../components/ProgressBar';
import TextStyles from '../../components/TextStyles';
import Tooltip from '../../components/Tooltip';
import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  gtmAttributesAcceptedBanks,
  gtmAttributesTooltipAccount,
  gtmAttributesTooltipAgency,
  gtmAttributesUpdateBankDataPage,
} from '../../utils/gtmVariables';

import BankService from '../../services/BankService';

import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';
import AppConfig from '../../config/AppConfig';
import url from '../../url';

interface DataBank {
  bank: string;
  agency: string;
  agency_digit: string;
  account: string;
  account_digit: string;
}

interface BankProps {
  code: string | number;
  label: string;
  value: string;
}

interface BankItemResponse {
  codBank: string | number;
  nameBank: string;
}

interface SuggestionBankProps {
  code: string;
  name: string;
}

const Bank = new BankService();
const Step = new StepClass();

const BankData = () => {
  const [data, setData] = useState<DataBank>({} as DataBank);
  const [selected, setSelected] = useState({ label: null, value: null, code: null });
  const [options, setOptions] = useState<Array<BankProps>>([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('Carregando...');
  const [variant, setVariant] = useState('success');
  const [selectLabel, setSelectLabel] = useState('Carregando opções de bancos');
  const [validBankData, setValidBankData] = useState({
    agency: false,
    account: false,
  });
  const [focused, setFocused] = useState(false);
  const [suggestionBank, setSuggestionBank] = useState<SuggestionBankProps>({
    code: '',
    name: '',
  } as SuggestionBankProps);
  const [disableButton, setDisableButton] = useState(false);

  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();
  const { ValueContainer, Placeholder } = components;

  const acceptBanksLink = `${url.base + url.bank.listAcceptedBanks}`;
  const current: number = 3;
  const steps: Array<StepInterface> = Step.steps();

  const noBankOption = {
    code: '0',
    value: 'Não encontrei meu banco',
    label: 'Não encontrei meu banco',
  };

  useEffect(() => {
    gtmEventsTracker(gtmAttributesUpdateBankDataPage);
  }, []);

  useEffect(() => {
    Bank.getBanks({ limit: 500 })
      .then((response: any) => {
        const banks = response.data;
        const bankOptions = banks.map(({ nameBank, codBank }: BankItemResponse) => ({
          code: codBank,
          value: nameBank,
          label: nameBank,
        }));
        bankOptions.push(noBankOption);
        setOptions(bankOptions);
        setSelectLabel('Selecione o seu banco');
      })
      .catch(() => {
        setSelectLabel('Não foi possível carregar os bancos disponíveis');
      });
  }, []);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      data.account &&
      data.account !== '' &&
      data.account_digit &&
      data.account_digit !== '' &&
      data.agency &&
      data.agency !== '' &&
      selected?.value
    ) {
      setShowMessage(true);
      setDisableButton(true);
      try {
        await Bank.addBankData({
          bank: {
            ...data,
            code: selected.code,
            name: selected.value,
          },
        });
        navigate('/step/product/integration');
      } catch {
        setVariant('danger');
        setMessage('Erro ao atualizar dados bancários.');
        setDisableButton(false);
      }
      return;
    }
    setVariant('warning');
    setMessage('Preencha todos os campos do formulário.');
  };

  const onlyNumber = (number: string) => number.replace(/[^0-9]/g, '');

  const handleChangeBankSuggestion = useCallback(
    (e: any) => {
      const nameInput = e?.target?.name;
      const value = e?.target?.value;
      if (e.target && typeof e.target.value !== undefined) {
        setSuggestionBank({
          ...suggestionBank,
          [nameInput]: ['code'].includes(nameInput) ? onlyNumber(value) : value,
        });
      }
    },
    [suggestionBank]
  );

  const handleChange = useCallback(
    (e: any) => {
      const nameInput = e?.target?.name;
      const value = e?.target?.value;
      setValidBankData({
        ...validBankData,
        [nameInput]: e.isValid,
      });
      if (e.target && typeof e.target.value !== undefined) {
        setData({
          ...data,
          [nameInput]: ['agency', 'agency_digit', 'account', 'account_digit'].includes(nameInput)
            ? onlyNumber(value)
            : value,
        });
      }
    },
    [data]
  );

  const DropdownSelectIndicator = () => {
    return (
      <div className="dropdownIndicator">
        <img src={Search} alt="" width={20} />
      </div>
    );
  };

  const selectChanged = (selectedOptions: any) => {
    setSelected(selectedOptions);
  };

  const bankSuggestionValidation = (error: RequestStatus) => {
    if (error && error?.response?.status !== 200) {
      setDisableButton(false);
      setVariant('danger');
      setMessage(
        error.response.data
          ? responseMensagemStatus[error.response.data]
          : 'Erro ao atualizar dados bancários'
      );
      return;
    }
    setVariant('success');
    setMessage('Sugestão cadastrada com sucesso!');
    setSuggestionBank({
      code: '',
      name: '',
    });
    setTimeout(() => {
      location.reload();
    }, 1000);
  };

  const registerBankSuggestion = async () => {
    setShowMessage(true);
    setDisableButton(true);
    if (
      suggestionBank.code &&
      suggestionBank.code !== '' &&
      suggestionBank.name &&
      suggestionBank.name !== ''
    ) {
      await Bank.registerBankSuggestion(suggestionBank, bankSuggestionValidation);
      return;
    }
    setDisableButton(false);
    setVariant('warning');
    setMessage('Preencha os campos do formulário');
  };

  const loadOptions = (searchValue: string, callback: (options: BankProps[]) => void) => {
    setTimeout(() => {
      const filterOptions = options.filter((option) =>
        option.label.toLowerCase().includes(searchValue.toLowerCase())
      );
      if (filterOptions.length === 0) {
        filterOptions.push(noBankOption);
      }
      callback(filterOptions);
    }, 1000);
  };

  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.key !== 'placeholder' ? child : null
        )}
      </ValueContainer>
    );
  };

  const selectStyle = {
    valueContainer: (provided: CSSObjectWithLabel) => ({
      ...provided,
      overflow: 'visible',
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      display: focused || selected.label != null ? 'none' : 'block',
      color: '#6D6E70;',
      fontSize: 14,
      fontWeight: 400,
    }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      borderRadius: 8,
      boxShadow: selected.label != null ? '0 0 0 1px #ccc0' : '',
      borderColor: selected.label != null ? '#ccc' : '',
      ':hover': {
        borderColor: '#ccc',
      },
      background: '#F9F9F9',
    }),
    menuList: (base: CSSObjectWithLabel) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '6px',
        height: '0px',
        marginRight: 16,
      },
      '::-webkit-scrollbar-track': {
        background: '#DBDCDC',
        marginRight: 16,
      },
      '::-webkit-scrollbar-thumb': {
        background: '#5D4EE8',
        borderRadius: 10,
      },
    }),
    option: (styles: CSSObjectWithLabel, { isFocused, isSelected }: any) => ({
      ...styles,
      background: isFocused ? '#5d4ee8ab' : isSelected ? '#fff' : '',
      color: isSelected ? '#000' : '#000',
      fontWeight: isSelected ? '600' : '400',
      ':active': {
        background: '#5D4EE8',
      },
    }),
    indicatorsContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      margin: 14,
    }),
  };

  const downloadAcceptedBanksPdf = () => {
    gtmEventsTracker(gtmAttributesAcceptedBanks);
    Bank.downloadFile(acceptBanksLink).catch(console.error);
  };

  return (
    <>
      <div>
        <div className="mp__data-bank-progressStep_desktop">
          <ProgressStep steps={steps} current={current} />
        </div>
        <div className="mp__integration-progress_mobile">
          <ProgressBar
            total={steps.length}
            step={current}
            subtitle={steps.find((e) => e.step === current)?.title ?? ''}
          />
        </div>
      </div>
      <div className="mp__bank-data-content">
        <div className="titles-content">
          <div className="mp__bank-data-title_wrapper">
            <TextStyles
              text="Informe os dados bancários do CNPJ cadastrado"
              className="mp__bank-data-title"
            />
          </div>
          <TextStyles
            text="É por meio desta conta que vamos fazer os repasses das suas vendas e atrelar as notas de comissão."
            className="mp__bank-data-subtitle"
          />
        </div>
        <Form noValidate onSubmit={handleSubmit}>
          <div className="mp__data-bank-select_wrapper">
            <div className="mp__select2-infos">
              <label>Banco*</label>
              <button onClick={() => downloadAcceptedBanksPdf()}>Confira os bancos aceitos</button>
            </div>
            <AsyncSelect
              placeholder="Pesquise pelo código ou nome"
              loadOptions={loadOptions}
              onChange={selectChanged}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              defaultOptions={options}
              components={{
                DropdownIndicator: DropdownSelectIndicator,
                ValueContainer: CustomValueContainer,
              }}
              id="mp__bank-select2"
              styles={selectStyle}
              loadingMessage={() => 'Carregando...'}
            />
          </div>
          {selected.label === 'Não encontrei meu banco' && (
            <>
              <div className="mp__no-bankdata-text">
                <p>
                  Para continuar com o cadastro, a gente recomenda que você crie uma conta PJ em um
                  dos bancos aceitos aqui pelo nosso marketplace.{' '}
                  <button onClick={() => downloadAcceptedBanksPdf()}>
                    Confira aqui a lista completa de bancos aceitos.
                  </button>
                </p>
                <p>
                  Você pode sugerir um banco ainda não aceito. Assim, a gente te avisa quando ele
                  estiver cadastrado.
                </p>
              </div>
              <div className="mp__suggestion-bank_wrapper">
                <div className="mp__bank-code_wrapper">
                  <Input
                    label="Código do banco"
                    id="mp__bank-code"
                    type="text"
                    name="code"
                    className="mp__bank-code_input"
                    maxLength={3}
                    onChange={handleChangeBankSuggestion}
                    value={suggestionBank.code}
                    rules={[(val: HtmlInput) => validations.required(val, 'Código do banco')]}
                  />
                </div>
                <div className="mp__bank-name_wrapper">
                  <Input
                    label="Nome do banco"
                    id="mp__bank-name"
                    type="text"
                    name="name"
                    className="mp__bank-name_input"
                    maxLength={12}
                    onChange={handleChangeBankSuggestion}
                    value={suggestionBank.name}
                    rules={[(val: HtmlInput) => validations.required(val, 'Nome do banco')]}
                  />
                </div>
              </div>
              <div className="mp__bank-data_buttons">
                <Button
                  type="button"
                  text="Sugerir banco"
                  color="blue"
                  classNames="mp__bank-next-button"
                  id="mp__bank-data-next_button"
                  onClick={registerBankSuggestion}
                  disabled={disableButton}
                />
              </div>
            </>
          )}
          {selected.label && selected.label !== 'Não encontrei meu banco' && (
            <>
              <div className="mp__bank-data_wrapper">
                <div className="mp__agency-wrapper">
                  <div className="mp__agency_input_wrapper">
                    <Input
                      label="Agência"
                      id="mp__bank-data-agency_input"
                      type="text"
                      name="agency"
                      className="mp__agency_input"
                      rules={[
                        (val: HtmlInput) => validations.required(val, 'Agência'),
                        (val: HtmlInput) => validations.isNotZero(val, 'Agência'),
                      ]}
                      onChange={handleChange}
                      value={(data && data.agency) || ''}
                      maxLength={4}
                    />
                  </div>
                  <Input
                    label="Dígito"
                    id="mp__bank-data-agency-digit_input"
                    type="text"
                    name="agency_digit"
                    children={
                      <Tooltip
                        text={
                          <>
                            Se o dígito for "X", <strong>substitua por 0</strong>
                          </>
                        }
                        className="mp__agency-digit_tooltip"
                        onClick={() => gtmEventsTracker(gtmAttributesTooltipAgency)}
                      />
                    }
                    className="mp__agency-digit_input"
                    maxLength={1}
                    onChange={handleChange}
                    value={(data && data.agency_digit) || ''}
                  />
                </div>
                <div className="mp__account-wrapper">
                  <div className="mp__account_input_wrapper">
                    <Input
                      label="Conta corrente"
                      id="mp__bank-data-account_input"
                      type="text"
                      name="account"
                      className="mp__account_input"
                      maxLength={12}
                      rules={[
                        (val: HtmlInput) => validations.required(val, 'Conta corrente'),
                        (val: HtmlInput) => validations.isNotZero(val, 'Conta corrente'),
                      ]}
                      onChange={handleChange}
                      value={(data.account && data.account) || ''}
                    />
                  </div>
                  <div className="mp__account-digit_input_wrapper">
                    <Input
                      label="Dígito"
                      id="mp__bank-data-account-digit_input"
                      type="text"
                      name="account_digit"
                      children={
                        <Tooltip
                          text={
                            <>
                              Se o dígito for "X", <strong>substitua por 0</strong>
                            </>
                          }
                          className="mp__account-digit_tooltip"
                          onClick={() => gtmEventsTracker(gtmAttributesTooltipAccount)}
                        />
                      }
                      className="mp__account-digit_input"
                      maxLength={1}
                      rules={[(val: HtmlInput) => validations.required(val, 'Digito da conta')]}
                      onChange={handleChange}
                      value={(data.account_digit && data.account_digit) || ''}
                    />
                  </div>
                </div>
              </div>
              <div className="mp__bank-data_buttons">
                <Button
                  type="submit"
                  text="Continuar"
                  color="blue"
                  classNames="mp__bank-next-button"
                  id="mp__bank-data-next_button"
                  disabled={disableButton}
                />
              </div>
            </>
          )}
        </Form>
      </div>
      <div className="mp__bank-data__notify-container">
        <Alert
          show={showMessage}
          variant={variant}
          onClose={() => {
            setShowMessage(false);
            setMessage('Carregando...');
          }}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      </div>
    </>
  );
};

export default BankData;
