import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import Panel from '../../components/Panel';

import Image from '../../assets/svg/cadastroManualProdutos.svg';
import ImageMobile from '../../assets/svg/cadastroManualProdutosMobile.svg';

const NonApprovedIntegrators = () => {
  const navigate = useNavigate();

  const goToEnvvias = () => {
    navigate('/step/freight/envvias', { replace: true });
  };

  return (
    <div className="mp__pas-panel_wrapper">
      <Panel
        panelType="information"
        title="Emissão manual de etiquetas Envvias"
        className="mp__pas-panel mp__pas-non-approved-integrators_panel"
        tooltipText={
          <>Envvias é a nossa solução logística, que ajuda na operação e gestão das entregas.</>
        }
        description={
          <div className="mp__pas-non-approved-integrators_desc">
            Sua integradora não é homologada no Envvias, por esse motivo{' '}
            <strong>é só emitir as etiquetas de envios no nosso Portal do Lojista.</strong>{' '}
            <a className="know-more-link" href="manualEnvvias.pdf" target="_blank" download>
              Clique aqui e saiba mais
            </a>
          </div>
        }
        image={Image}
        imageMobile={ImageMobile}
        buttonText="Ok, entendi!"
        onClick={goToEnvvias}
        buttonId="mp__pas-non-approved-integrators_button"
      />
    </div>
  );
};

export { NonApprovedIntegrators };
