import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import validations from '../../../../helpers/Validations';

import HtmlInput from '../../../../interfaces/HtmlInput';
import ServiceRegistration from '../../../../services/RegisterService';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { Alert } from 'react-bootstrap';

import { gtmAttributesInvalidEmail } from '../../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../../hooks/useGTMEventsTracker';
import { gtmAttributesUpdateForgotPasswordPage } from '../../../../utils/gtmVariables';

interface Props {
  next: Function;
}

const EmailStep = ({ next }: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [valid, setValid] = useState(false);
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState(<></>);
  const [disableButton, setDisableButton] = useState(false);

  const serviceRegistration = new ServiceRegistration();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateForgotPasswordPage);

  const change = () => {
    setDisableButton(true);
    if (email && email !== '' && valid) {
      serviceRegistration
        .recoverPassword(email)
        .then(() => {
          return next(2);
        })
        .catch((error) => {
          setVariant('warning');
          gtmEventsTracker(gtmAttributesInvalidEmail);
          setDisableButton(false);
          const errorMessage = error.response.data.motivos
            ? error.response.data.motivos[0]
            : 'Erro desconhecido';
          if (errorMessage === 'Erro desconhecido') {
            setMessage(
              <>
                Email incorreto.
                <strong> Por favor, tente novamente!</strong>
              </>
            );
          } else {
            setMessage(<>{errorMessage}</>);
          }
          setShow(true);
          setTimeout(() => {
            setShow(false);
          }, 3000);
        });
    }
  };
  const changeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target) {
      const email = validations.toLowerCase(e);
      setEmail(email);
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <div className="mp__forgot_password-container" id="mp__recovar-password">
      <span className="mp__forgot_password-title">Recuperação de senha</span>
      <p className="mp__forgot_password-text">
        Por favor, digite seu email para redefinição de senha:
      </p>
      <div className="mp__forgot_password-input">
        <Input
          id={'mp__input-email_mail'}
          label="Email"
          type="email"
          name="email"
          value={email}
          rules={[(val: HtmlInput) => validations.isValidEmail(val, true)]}
          onChange={(e: any) => changeEmail(e)}
        />
      </div>
      <div className="mp__forgot_password-action">
        <Button
          id={'mp__btn-email_redefine'}
          text={'Redefinir senha'}
          type={'submit'}
          color={'blue'}
          classNames={'mp__forgot_password-action_button'}
          onClick={change}
          disabled={disableButton}
        />
        <Button
          id={'mp__btn-email_back'}
          text={'Voltar'}
          type={'reset'}
          classNames={'mp__forgot_password-action_button cancel'}
          onClick={() => navigate('/login')}
        />
      </div>
      <div className="notify-container">
        <Alert
          show={show}
          variant={variant}
          onClose={() => setShow(false)}
          dismissible
          closeVariant="white"
        >
          <p>{message}</p>
        </Alert>
      </div>
    </div>
  );
};

export default EmailStep;
