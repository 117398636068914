import React, { useState } from 'react';
import SentStep from './slots/SentStep';
import EmailStep from './slots/EmailStep';
import '../index.css';

const ForgotPassword = () => {
  let [step, setStep] = useState(1);

  return (
    <div id="mp__forgot-password">{step === 1 ? <EmailStep next={setStep} /> : <SentStep />}</div>
  );
};

export default ForgotPassword;
