import axios from '../http';
import Integration from '../interfaces/Integration';
import { getStorage, setStorageWithTtl } from '../helpers/Storage';

import url from '../url';
import DefaultErrorData from '../pages/Register/DefaultError';

export default class RegisterService {
  async activate(jwt: string, params?: object) {
    setStorageWithTtl('token', window.atob(jwt), 1440);
    const response = await axios.patch(`${url.register.sellerActivate}`, {}, { params });
    return response.data;
  }

  async activateHunting(jwt: string, password?: string) {
    const response = await axios.patch(`${url.register.sellerHuntingActivate}`, {
      token: jwt,
      password,
    });
    return response.data;
  }

  confirmContract(params?: object) {
    return axios.patch(`${url.register.sellerDefaultContract}`, {}, { params });
  }

  confirmContractHunting(params?: object) {
    return axios.patch(`${url.register.sellerCustomContract}`, {}, { params });
  }

  recoverPassword(email: string) {
    const recoverPassword = axios.post(`${url.auth.recoveryPassword}`, { email: email });
    return recoverPassword.then((response) => response.data);
  }

  updatePassword(password: string, token: string | null) {
    const updatePassword = axios.post(`${url.auth.newPassword}`, {
      newPassword: password,
      token,
    });
    return updatePassword.then((response) => response.data);
  }

  async getIntegrations(params: object): Promise<Integration[]> {
    return await axios.get(`${url.register.getProductIntegration}`).then((response) => {
      return response.data.map((item: { descricao: string }) => {
        return {
          ...item,
          label: item.descricao,
        };
      });
    });
  }

  async createSeller(body: object, statusMessage: Function) {
    try {
      return await axios.post(`${url.register.createSeller}`, {
        ...body,
      });
    } catch (err: any) {
      if (err.response && err.response.data) {
        const errorData = err.response.data;
        statusMessage(new DefaultErrorData(errorData));
      }
    }
  }

  updateProductIntegration(body: any) {
    return axios.patch(`${url.register.updateProductIntegration}`, body);
  }

  registrationCompleted(params?: object) {
    return axios.patch(`${url.register.complete}`, {}, { params });
  }

  registerInBlackFriday(body: object) {
    return axios.post(`${url.register.freezing}`, body);
  }

  resendEmail(sellerId: string) {
    return axios.post(`${url.register.resendEmail}`, {
      sellerId,
      retry_topic: 'SEND_CONFIRMATION_ACCOUNT',
    });
  }
}
