import React from 'react';
import { CheckCircle } from '@mui/icons-material';

import './index.css';

const Loading = () => {
  return (
    <>
      <div className="mp_skeleton mp_skeleton-header"></div>
      <div id="mp__skeleton-register">
        <div className="mp__skeleton-header flex-col mt-6 sm:mt-12 px-5">
          <span className="mp_skeleton mp__skeleton-header_title"></span>
          <p className="mp_skeleton mp__register-header_text"></p>
        </div>
        <section className="mp__register-form max-w-2xl px-5 sm:px-24 mb-44 m-auto">
          <div className="mp__register-form_content max-w-screen-sm mx-auto">
            <label className="mp_skeleton mp__skeleton-label-1"></label>
            <div className="mp_skeleton mp__skeleton-input"></div>
          </div>
          <div className="mp__register-form_content max-w-screen-sm mx-auto">
            <label className="mp_skeleton mp__skeleton-label-2"></label>
            <div className="mp_skeleton mp__skeleton-input"></div>
          </div>
          <div className="mp__register-form_content grid sm:grid-cols-2 gap-3">
            <div>
              <label className="mp_skeleton mp__skeleton-label-3"></label>
              <div className="mp_skeleton mp__skeleton-input-medium"></div>
            </div>
            <div>
              <label className="mp_skeleton mp__skeleton-label-4"></label>
              <div className="mp_skeleton mp__skeleton-input-medium"></div>
            </div>
          </div>
          <div className="mp__register-form_content max-w-screen-sm mx-auto">
            <label className="mp_skeleton mp__skeleton-label-5"></label>
            <div className="mp_skeleton mp__skeleton-input"></div>
          </div>
          <div className="mp__register-form_content max-w-screen-sm mx-auto">
            <label className="mp_skeleton mp__skeleton-label-6"></label>
            <div className="mp_skeleton mp__skeleton-input"></div>
          </div>
          <div className="max-w-screen-sm mx-auto">
            <div className="d-flex align-items-center">
              <div className="mp_skeleton skeleton-progress-strength position-relative mr-3" />
              <span className="mp_skeleton skeleton-strength-password"></span>
            </div>
          </div>
          <div className="max-w-screen-sm mt-6 mb-4">
            <div className="d-flex">
              <div className="mp_skeleton skeleton-password-needs position-relative mr-3" />
            </div>
          </div>
          <ul className="pl-0">
            <div className="flex items-center mb-2.5">
              <div className="mp_skeleton skeleton-circle"></div>
              <div className="mp_skeleton skeleton-role-password-needs-1"></div>
            </div>
            <div className="flex items-center mb-2.5">
              <div className="mp_skeleton skeleton-circle"></div>
              <div className="mp_skeleton skeleton-role-password-needs-2"></div>
            </div>
            <div className="flex items-center mb-2.5">
              <div className="mp_skeleton skeleton-circle"></div>
              <div className="mp_skeleton skeleton-role-password-needs-3"></div>
            </div>
            <div className="flex items-center">
              <div className="mp_skeleton skeleton-circle"></div>
              <div className="mp_skeleton skeleton-role-password-needs-4"></div>
            </div>
          </ul>
          <div className="skeleton-checkBox-wrapper flex items-center">
            <div className="mp_skeleton skeleton-checkBox"></div>
            <div className="mp_skeleton skeleton-checkBox-text"></div>
          </div>
          <div className="skeleton-button-wrapper max-w-screen-sm mx-auto">
            <div className="mp_skeleton mp__skeleton-button"></div>
          </div>
        </section>
      </div>
      <div className="mp_skeleton mp_skeleton-footer"></div>
    </>
  );
};

export default Loading;
