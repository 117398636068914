import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getStorage } from '../../helpers/Storage';
import AuthService from '../../services/AuthService';
import styled from './index.styles';
import Image from '../Image';

import { gtmAttributesMakeLogin, gtmAttributesMakeLogout } from '../../utils/gtmVariables';

import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

interface HeaderProps {
  logo: string;
  hamburgerIcon: string;
  closeIcon: string;
  hasRecord: string;
  login: string;
  maintenance?: boolean;
}

const Header = ({ logo, hamburgerIcon, closeIcon, hasRecord, login, maintenance }: HeaderProps) => {
  const [clicked, setClicked] = useState(false);
  const [hasLogin, setHasLogin] = useState(false);
  const [userName, setUserName] = useState(() => {
    const name = getStorage('sellerName');

    if (typeof name !== undefined && name != '') {
      return name;
    }

    return 'Nome do usuário';
  });

  const gtmEventsTracker = useGTMEventsTracker();

  const navigate = useNavigate();

  const handleClick = () => {
    setClicked(!clicked);
  };

  const makeLogin = () => {
    gtmEventsTracker(gtmAttributesMakeLogin);
    navigate('/login');
  };

  const makeLogout = () => {
    gtmEventsTracker(gtmAttributesMakeLogout);
    AuthService.logout();
    navigate('/');
  };

  useEffect(() => {
    if (/true/i.test(getStorage('isLogged'))) {
      setHasLogin(true);
      setUserName(getStorage('sellerName'));
    } else {
      setHasLogin(false);
    }
  });

  return (
    <styled.Header
      id="mp__header"
      clicked={clicked ? 'clicked' : undefined}
      collapseOnSelect
      expand="lg"
    >
      <styled.ContentHeader>
        <styled.WrapperLogo>
          <Image images={[{ link: '/', img: `${logo}`, alt: 'marketplace-logo' }]} />
        </styled.WrapperLogo>
        {!maintenance && (
          <styled.WrapperHamburguer>
            <styled.Hamburguer onClick={handleClick} aria-controls="responsive-navbar-nav">
              <styled.MenuIcon src={clicked ? closeIcon : hamburgerIcon} alt="menu-icon" />
            </styled.Hamburguer>
          </styled.WrapperHamburguer>
        )}
      </styled.ContentHeader>
      {!maintenance && (
        <styled.WrapperLogin id="responsive-navbar-nav" login={hasLogin ? 'hasLogin' : undefined}>
          <styled.UserInformation login={hasLogin ? 'hasLogin' : undefined}>
            {hasLogin ? userName : hasRecord}
          </styled.UserInformation>
          {hasLogin ? (
            <styled.Logout onClick={makeLogout}>Sair</styled.Logout>
          ) : (
            <styled.Login onClick={makeLogin}>{login}</styled.Login>
          )}
        </styled.WrapperLogin>
      )}
    </styled.Header>
  );
};

export default Header;
