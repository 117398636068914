import React, { useState } from 'react';

import { Alert } from 'react-bootstrap';

import './index.css';

import Input from '../Input';
import Button from '../Button';

import { gtmAttributesCopyToken } from '../../utils/gtmVariables';

import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

interface Props {
  buttonText: string;
  inputValue: string;
  id: string;
}

const InputCopy = ({ buttonText, inputValue, id }: Props) => {
  const [show, setShow] = useState(false);
  const gtmEventsTracker = useGTMEventsTracker();

  function copyTextInput() {
    gtmEventsTracker(gtmAttributesCopyToken);
    const val = (document.getElementById('input-copy') as HTMLInputElement).value;
    navigator.clipboard?.writeText(val).then(function () {
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    });
  }

  return (
    <div id="mp__input" className="wrapper-input-copy">
      <Input
        type="text"
        name="input-copy"
        label="Token de ativação"
        value={inputValue}
        className="input-copy"
        disabled={true}
        readOnly={true}
        id="input-copy"
      />
      <Button
        type="button"
        text={buttonText}
        classNames="button-copy"
        onClick={() => copyTextInput()}
      />
      <div className="notification-container">
        <Alert
          show={show}
          variant="success"
          onClose={() => setShow(false)}
          dismissible
          closeVariant="white"
          className="input-successfully-copied"
          id={id}
        >
          <p>Token copiado!</p>
        </Alert>
      </div>
    </div>
  );
};

export default InputCopy;
