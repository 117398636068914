import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import './index.css';

import Welcome from './slots/Welcome';
import imgCard1 from '../../../assets/svg/imgCard1.svg';
import imgCard2 from '../../../assets/svg/imgCard2.svg';
import imgCard3 from '../../../assets/svg/imgCard3.svg';
import imgCard4 from '../../../assets/svg/imgCard4.svg';
import Card from '../../../components/Card/Index';
import Carousel from '../../../components/Carousel';
import { gtmAttributesUpdatePasHomePage } from '../../../utils/gtmVariables';

import {
  gtmAttributesSalesManagement,
  gtmAttributesAttendance,
  gtmAttributesTrainings,
  gtmAttributesFinancial,
} from '../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../hooks/useGTMEventsTracker';

const Home: FC<any> = () => {
  const location = useLocation();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdatePasHomePage);

  const cards = [
    <Card
      title="Gestão de Vendas"
      subtitle="Saiba como funciona"
      color="#5D4EE8"
      imageUrl={imgCard1}
      body="Conheça a tela de gestão de vendas da sua loja."
      link="https://escola.via.com.br/cursos/gestao-de-vendas-conheca-a-tela-de-gestao-de-vendas"
      key="sales-management"
      onClick={() => gtmEventsTracker(gtmAttributesSalesManagement)}
    />,
    <Card
      title="Treinamentos aplicados"
      subtitle="Cadastro de produtos"
      color="#00D3C3"
      imageUrl={imgCard2}
      body="Entenda como cadastrar os produtos da sua loja."
      link="https://escola.via.com.br/cursos/treinamentos-aplicados-catalogo-cadastro-de-produtos"
      key="trainings"
      onClick={() => gtmEventsTracker(gtmAttributesTrainings)}
    />,
    <Card
      title="Atendimento"
      subtitle="Ações que você deve tomar"
      color="#00D3C3"
      imageUrl={imgCard3}
      body="Saiba como fazer a gestão dos protocolos da sua loja."
      link="https://escola.via.com.br/cursos/atendimento-acoes-do-lojista"
      key="attendance"
      onClick={() => gtmEventsTracker(gtmAttributesAttendance)}
    />,
    <Card
      title="Financeiro"
      subtitle="Fluxo de repasse"
      color="#5D4EE8"
      imageUrl={imgCard4}
      body="Saiba como funciona o fluxo de repasse das suas vendas."
      link="https://escola.via.com.br/cursos/financeiro-fluxo-de-repasse"
      key="financial"
      onClick={() => gtmEventsTracker(gtmAttributesFinancial)}
    />,
  ];

  return (
    <Container fluid className="container-max-width">
      <Row className="mt-10 mb-32">
        <Col className="d-flex align-items-center justify-content-center px-lg-5" lg={5}>
          <Welcome />
        </Col>
        <Col className="justify-content-center mt-lg-0 mt-4" lg={7}>
          <Carousel items={cards} />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
