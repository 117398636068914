import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import './index.css';

import Search from '../../assets/svg/search.svg';
import StepInterface from '../../interfaces/Step';
import Integration from '../../interfaces/Integration';
import StepClass from '../../entities/Step';

import ProgressStep from '../../components/ProgressStep';
import ProgressBar from '../../components/ProgressBar';
import TextStyles from '../../components/TextStyles';
import Tooltip from '../../components/Tooltip';
import Button from '../../components/Button';
import { Select2 } from '../../components/Select2';

import RegisterService from '../../services/RegisterService';

import {
  gtmAttributesAboutIntegration,
  gtmAttributesUpdateProductIntegrationPage,
} from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

const Service = new RegisterService();
const Step = new StepClass();

const ProductIntegration = () => {
  const [selected, setSelected] = useState({
    label: null,
    value: {
      id: null,
      _id: null,
      _v: 0,
      codigo: null,
      name: null,
      label: undefined,
      type: null,
      homologated: false,
      created_at: null,
      updated_at: null,
    },
  });
  const [disableEnableBtn, setDisableEnableBtn] = useState<boolean>(true);
  const [disableNotHas, setDisableNotHas] = useState<boolean>(false);
  const [variant, setVariant] = useState('success');
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('Carregando...');
  const [width, setWidth] = useState<number>(0);
  const [options, setOptions] = useState<Integration[]>([]);
  const [focused, setFocused] = useState(false);

  const newWidth = window.innerWidth;
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateProductIntegrationPage);

  useEffect(() => {
    setDisableEnableBtn(true);
    if (selected.value.codigo) {
      setDisableEnableBtn(false);
    }
  }, [selected]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(newWidth);
    });
  }, []);

  useEffect(() => {
    Service.getIntegrations({ limit: 500 }).then((integration) => {
      setOptions(integration.filter((item) => item.type !== 'FREIGHT'));
    });
  }, []);

  const enable = async () => {
    setShowMessage(true);
    setDisableEnableBtn(true);
    setDisableNotHas(true);
    await Service.updateProductIntegration({
      code: selected.value.codigo,
    })
      .then((response: any) => {
        const { integratingToken } = response.data;
        if (integratingToken) {
          if (selected.value.homologated) {
            navigate('/step/product/integration/token', {
              state: {
                to: '/step/freight/envvias',
                token: integratingToken,
              },
            });
          } else {
            navigate('/step/product/integration/token', {
              state: {
                to: '/non-approved-integrators',
                token: integratingToken,
              },
            });
          }
        } else {
          if (selected.value.homologated) {
            navigate('/integration-error', {
              state: {
                to: '/step/freight/envvias',
              },
            });
          } else {
            navigate('/integration-error', {
              state: {
                to: '/non-approved-integrators',
              },
            });
          }
        }
      })
      .catch((e) => {
        setVariant('danger');
        setMessage('Erro ao cadastrar a integradora.');
        setDisableEnableBtn(false);
        setDisableNotHas(false);
      });
  };

  const notHas = () => {
    setShowMessage(true);
    Service.updateProductIntegration({ code: 'sem_integradora' })
      .then(() => {
        navigate('/product/manual/registration');
      })
      .catch((e) => {
        setVariant('danger');
        setMessage('Erro ao fazer o cadastro.');
        setDisableEnableBtn(false);
      });
  };

  const DropdownSelectIndicator = () => {
    return (
      <div className="dropdownIndicator">
        <img src={Search} alt="" width={20} />
      </div>
    );
  };

  const current: number = 4;
  const steps: Array<StepInterface> = Step.steps();

  return (
    <>
      <div id="mp__product-integration">
        <div className="progress-step-wrapper">
          <ProgressStep steps={steps} current={current} />
        </div>
        <div className="mp__integration-progress_mobile">
          <ProgressBar
            total={steps.length}
            step={current}
            subtitle={steps.find((e) => e.step === current)?.title ?? ''}
          />
        </div>
        <div className="product-integration-wrapper">
          <div className="product-integration-info">
            <TextStyles text="Habilite a sua integradora" className="product-integration-title" />
            <Tooltip
              text={
                <>
                  Integradoras são{' '}
                  <strong>plataformas que agrupam, em um só lugar, os marketplaces</strong> que você
                  usa.
                </>
              }
            />
          </div>
          <div className="select-integrator-wrapper">
            <Select2
              placeholder="Selecione a sua integradora"
              data={options}
              change={setSelected}
              component={DropdownSelectIndicator}
              label="Integradora"
              linkprops={{
                text: width <= 720 ? 'Saiba mais sobre a integração' : 'Saiba mais',
                link: 'https://suportemarketplace.viavarejo.com.br/pt-BR/integradora/',
              }}
              onClick={() => gtmEventsTracker(gtmAttributesAboutIntegration)}
              id="mp__integration-integrator-select2"
              isFocused={false}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              noOptionsMessage={() => 'Integradora não encontrada.'}
              styles={{
                valueContainer: (provided, _) => ({
                  ...provided,
                  overflow: 'visible',
                }),
                placeholder: (base, _) => ({
                  ...base,
                  display: focused || selected.label != null ? 'none' : 'block',
                  color: '#202121',
                  fontSize: 14,
                }),
                control: (base, _) => ({
                  ...base,
                  borderRadius: 8,
                  boxShadow: selected.label != null ? '0 0 0 1px #ccc0' : '',
                  borderColor: selected.label != null ? '#ccc' : '',
                  ':hover': {
                    borderColor: '#ccc',
                  },
                }),
                menuList: (base) => ({
                  ...base,
                  '::-webkit-scrollbar': {
                    width: '6px',
                    height: '0px',
                    marginRight: 16,
                  },
                  '::-webkit-scrollbar-track': {
                    background: '#DBDCDC',
                    marginRight: 16,
                  },
                  '::-webkit-scrollbar-thumb': {
                    background: '#5D4EE8',
                    borderRadius: 10,
                  },
                }),
                option: (styles, { isFocused, isSelected }) => ({
                  ...styles,
                  background: isFocused ? '#5d4ee8ab' : isSelected ? '#fff' : '',
                  color: isSelected ? '#000' : '#000',
                  fontWeight: isSelected ? '600' : '400',
                  ':active': {
                    background: '#5D4EE8',
                  },
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  margin: 14,
                }),
              }}
            />
          </div>
          <div className="button-content">
            <Button
              type="button"
              text="Não tenho integradora"
              classNames="no-integrator"
              id="mp__integration_no-integrator-button"
              onClick={notHas}
              disabled={disableNotHas}
            />
            <Button
              type="button"
              text="Habilitar"
              classNames="enable-button"
              disabled={disableEnableBtn}
              id="mp__integration_enable-button"
              onClick={enable}
            />
          </div>
        </div>
      </div>
      <div className="mp__integration__notify-container">
        <Alert
          show={showMessage}
          variant={variant}
          onClose={() => {
            setShowMessage(false);
            setMessage('Carregando...');
          }}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      </div>
    </>
  );
};

export default ProductIntegration;
