import React, { ChangeEventHandler } from 'react';
import style from './index.styles';

interface CheckboxProperties {
  label: string;
  value: boolean;
  disabled?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onClick?: () => void;
}

const Checkbox = (props: CheckboxProperties) => {
  return (
    <style.Label htmlFor={props.label}>
      {props.label}
      <style.Input
        type="checkbox"
        id={props.label}
        checked={props.value}
        onChange={props.onChange}
        onClick={props.onClick}
        disabled={props.disabled}
      />
      <style.Span />
    </style.Label>
  );
};

export default Checkbox;
