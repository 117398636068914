import { NavLink, Outlet } from 'react-router-dom';
import { Header, Footer, SocialMedias, CompanyInformation } from './index.styles';

import logo from '../../assets/svg/logo-bf.svg';
import logoFooter from '../../assets/svg/logo-white.svg';
import instagram from '../../assets/svg/instagram-bf.svg';
import youtube from '../../assets/svg/youtube-bf.svg';
import linkedin from '../../assets/svg/linkedin-bf.svg';

export function BlackFridayLayout() {
  return (
    <>
      <Header>
        <NavLink to="/">
          <img src={logo} alt="logo-header" />
        </NavLink>
      </Header>
      <Outlet />
      <Footer>
        <NavLink to="/">
          <img src={logoFooter} alt="logo-footer" />
        </NavLink>
        <CompanyInformation>
          <p>
            Via Varejo S.A. Rua Samuel Klein, n° 83, São Caetano do Sul - SP - CEP: 09520-010 CNPJ:
            33.041.260/0652-90
          </p>
        </CompanyInformation>
        <SocialMedias>
          <a href="https://www.instagram.com/marketplacevia" target="_blank">
            <img src={instagram} alt="" />
          </a>
          <a href="https://www.linkedin.com/company/viaimaginecaminhos" target="_blank">
            <img src={linkedin} alt="" />
          </a>
          <a href="https://www.youtube.com/c/ViaMarketplace" target="_blank">
            <img src={youtube} alt="" />
          </a>
        </SocialMedias>
      </Footer>
    </>
  );
}
