import styled from 'styled-components';

interface styleProps {
  styles: string;
}

const Container = styled.div``;

const WrapperLogo = styled.div``;

const Link = styled.a``;

const Image = styled.img``;

export default {
  Container,
  WrapperLogo,
  Link,
  Image,
};
