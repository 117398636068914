import styled from 'styled-components';

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #000000;
  padding: 2rem 8.625rem;
  @media (max-width: 768px) {
    padding: 2rem 6.25rem;
    justify-content: center;
  }
`;

export const Footer = styled.footer`
  display: grid;
  grid-template: 1fr / 25% 60% 15%;
  align-items: center;
  background-color: #000000;
  padding: 2rem 8.625rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0.875rem 1.5rem;
    padding-top: 3rem;
    gap: 1.5rem;
    img {
      margin: 0 auto;
    }
  }

  @media (min-width: 769px) and (max-width: 1023px) {
    grid-template: 1fr / 30% 50% 20%;
    padding: 2rem 1.625rem;
    img {
      margin: 0 auto;
    }
  }
`;

export const CompanyInformation = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4rem;
  p {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #ffffff;
    padding-right: 9.5rem;
  }
  @media (max-width: 768px) {
    p {
      padding-right: 0;
      margin-bottom: 1.5rem;
    }
  }
`;

export const SocialMedias = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2.563rem;
  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 769px) and (min-width: 1023px) {
    gap: 1.563rem;
  }
`;
