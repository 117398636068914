import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Password from '../../../../components/Password';
import Button from '../../../../components/Button';
import Notification from '../../../../components/Notification/Notification';

import ServiceRegistration from '../../../../services/RegisterService';

import {
  gtmAttributesError,
  gtmAttributesRecoverPassword,
  gtmAttributesUpdatePasswordPage,
} from '../../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../../hooks/useGTMEventsTracker';

interface Props {
  next: Function;
}

const PasswordStep = ({ next }: Props) => {
  const [showWarning, setNotificationWarning] = useState(false);
  const [showError, setNotificationError] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [showErrorConfirmation, setNotificationErrorConfirmation] = useState(false);
  const [password, setPassword] = useState<string | undefined>('');
  const [disableButton, setDisableButton] = useState(false);
  const [message, setMessage] = useState(<></>);
  const time = 5000;
  const navigate = useNavigate();
  const serviceRegistration = new ServiceRegistration();
  const param = useParams();
  const token = param.token?.toString();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdatePasswordPage);

  const change = () => {
    setDisableButton(true);
    if (password && showConfirmation && token) {
      serviceRegistration
        .updatePassword(password, token)
        .then(() => {
          gtmEventsTracker(gtmAttributesRecoverPassword);
          setNotificationWarning(true);
          return next(2);
        })
        .catch((error) => {
          setDisableButton(false);
          if (error.response.status === 400) {
            const errorMessage = error.response.data.motivos
              ? error.response.data.motivos[0]
              : 'A senha deve respeitar todos os critérios.';
            setMessage(<>{errorMessage}</>);
            setNotificationError(true);
            setTimeout(() => {
              setNotificationErrorConfirmation(true);
            }, 400);
          } else {
            if (error.response.data.mensagem.includes('JWT expired at')) {
              setMessage(
                <>
                  O toke de redefinição de senha já expirou, faça o pedido de um novo email de reset
                  de senha.
                </>
              );
              setNotificationError(true);
              setTimeout(() => {
                setNotificationErrorConfirmation(true);
              }, 4000);
            }
          }
          gtmEventsTracker(gtmAttributesError);
        });
    }
    setDisableButton(false);
  };
  const changePassword = (e: any) => {
    setConfirmation(e?.isValid);
    if (e.target) {
      setPassword(e.target.value);
    }
  };

  return (
    <>
      <div className="mp__forgot_password-toast">
        {showErrorConfirmation ? (
          <div
            style={{
              position: 'absolute',
              width: '400px',
              borderRadius: '9px',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%)',
              backgroundColor: '#e5e7eb',
              padding: '20px',
              zIndex: 1000,
            }}
          >
            <p>O token de redefinição de senha expirou. Você gostaria de solicitar um novo?</p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <button
                onClick={() => navigate('/forgot-password')}
                style={{
                  backgroundColor: '#5d4ee8',
                  width: '40%',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: '9px',
                  padding: '10px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Sim
              </button>
              <button
                onClick={() => setNotificationErrorConfirmation(false)}
                style={{
                  backgroundColor: 'gray',
                  width: '40%',
                  color: 'white',
                  fontWeight: 'bold',
                  borderRadius: '9px',
                  padding: '10px',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                Não
              </button>
            </div>
          </div>
        ) : (
          <></>
        )}
        {showError ? (
          <Notification
            delay={time}
            variant={'error'}
            extraClass={'mp__forgot_password-notification'}
          >
            <p>{message}</p>
          </Notification>
        ) : (
          <></>
        )}
      </div>
      <div className="mp__forgot_password-container">
        <div className="mp__forgot_password-toast_mobile">
          {showWarning ? (
            <Notification
              delay={time}
              variant={'warning'}
              extraClass={'mp__forgot_password-notification'}
            >
              Aguarde enquanto processamos suas informações.
            </Notification>
          ) : (
            <></>
          )}
          {showError ? (
            <Notification
              delay={time}
              variant={'error'}
              extraClass={'mp__forgot_password-notification'}
            >
              A senha precisa respeitar todas as regras!
            </Notification>
          ) : (
            <></>
          )}
        </div>
        <span className="mp__forgot_password-title password">Cadastre sua nova senha</span>
        <p className="mp__forgot_password-text">No campo abaixo insira a sua nova senha.</p>
        <div className="mp__forgot_password-input">
          <Password label="Nova senha" onChange={(e: any) => changePassword(e)} />
        </div>
        <div className="mp__forgot_password-action">
          <Button
            id={'mp__btn-password_keep'}
            text={'Continuar'}
            type={'submit'}
            color={'blue'}
            classNames={'mp__forgot_password-action_button'}
            onClick={change}
            disabled={disableButton}
          />
          <Button
            id={'mp__btn-password_cancel'}
            text={'Cancelar'}
            type={'submit'}
            classNames={'mp__forgot_password-action_button cancel'}
            onClick={() => navigate('/login')}
          />
        </div>
      </div>
    </>
  );
};

export default PasswordStep;
