import HtmlInput from '../../interfaces/HtmlInput';

const email =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const testPattern = {
  email: (v: string | number | undefined | null) => email.test(<string>v ?? ''),
};

const isSelectOrNot = (val: HtmlInput) => {
  let finalValue: string | number | undefined | null = '';

  if (typeof val.target.value === 'object') {
    finalValue = val.target.value.value;
  } else {
    finalValue = val.target.value;
  }

  return finalValue;
};

const isValidEmail = (val: HtmlInput, required = false) => {
  const v = isSelectOrNot(val);

  if ((!required && v === '') || v === undefined) {
    return true;
  }

  if (testPattern.email(v)) {
    return true;
  }

  if (v === '') {
    return 'Você precisa preencher o campo Email.';
  }

  return 'O formato de email é inválido';
};

const required = (val: HtmlInput, name: string) => {
  const v = isSelectOrNot(val);

  if (v === '' || v === undefined || v === null) {
    return `Você precisa preencher o campo ${name}.`;
  }
  return true;
};

const notNegativeNumber = (val: HtmlInput, name: string) => {
  const v = isSelectOrNot(val);

  if (v === null || v === '' || Number(v) >= 0 || v === undefined) {
    return true;
  }

  return `O campo ${name} não pode ser menor que zero.`;
};

const minLength = (val: HtmlInput, length: number, name: string, acceptEmpty = false) => {
  const v = isSelectOrNot(val);

  if (acceptEmpty && v === '') {
    return true;
  }

  if (!v || v.toString().length < length) {
    return `O campo ${name} deve conter no mínimo ${length} caracteres.`;
  }
  return true;
};

const maxLength = (val: HtmlInput, length: number, name: string) => {
  const v = isSelectOrNot(val);

  if (v === null || v === undefined || v.toString().length > length) {
    return `O campo ${name} deve conter no máximo ${length} caracteres.`;
  }
  return true;
};

const length = (val: HtmlInput, length: number | any[], name: string) => {
  const v = isSelectOrNot(val);

  if (!Array.isArray(length)) {
    length = [length];
  }
  if (v && !length.includes(v.toString().length)) {
    return `O formato do campo ${name} é inválido.`;
  }
  return true;
};

const isNotZero = (val: HtmlInput, name: string) => {
  if (/^0+$/.test(val.target.value.toString().replace(/[^a-zA-Z0-9s]/g, '')))
    return `Valor do campo ${name} inválido`;
  return true;
};

const isNotPhoneNumber = (val: HtmlInput, name: string) => {
  if (
    /^1+$/.test(val.target.value.toString().replace(/[^a-zA-Z0-9s]/g, '')) ||
    /^9+$/.test(val.target.value.toString().replace(/[^a-zA-Z0-9s]/g, ''))
  )
    return `Valor do campo ${name} inválido`;
  return true;
};

const onlyNumbers = (number: string) => number.replace(/[^0-9]/g, '');
const onlyLetters = (text: string) => text.replace(/[^a-zA-Z\s]/gi, '');
const toLowerCase = (e: React.ChangeEvent<HTMLInputElement>) =>
  e.target.value.replace(/[A-Z]/g, (letter) => letter.toLowerCase());

const validations = {
  isValidEmail,
  required,
  notNegativeNumber,
  minLength,
  maxLength,
  length,
  isNotZero,
  isNotPhoneNumber,
  onlyNumbers,
  onlyLetters,
  toLowerCase,
};

export default validations;
