import StepInterface from '../interfaces/Step';

class Step {
  private REGISTER = '/';
  private CONTRACT = '/step/contract/';
  private BANK_DATA = '/step/bank';
  private INTEGRATOR = '/step/product/integration';
  private FREIGHT = '/step/freight/';
  private PAS_ACCESS = '/pas/home';

  steps(): Array<StepInterface> {
    return [
      {
        id: 'Cadastro',
        title: 'Cadastro',
        step: 1,
        url: this.REGISTER,
      },
      {
        id: 'Contrato',
        title: 'Contrato',
        step: 2,
        url: this.CONTRACT,
      },
      {
        id: 'Dados bancários',
        title: 'Dados bancários',
        step: 3,
        url: this.BANK_DATA,
      },
      {
        id: 'Integradora',
        title: 'Integração',
        step: 4,
        url: this.INTEGRATOR,
      },
      {
        id: 'Frete',
        title: 'Frete',
        step: 5,
        url: this.FREIGHT,
      },
      {
        id: 'Modal ao PAS',
        title: 'Acesso',
        step: 6,
        url: this.PAS_ACCESS,
      },
    ];
  }

  getStep(step: string | number) {
    return this.steps().find((i) => [i.id, i.step].includes(step));
  }
}

export default Step;
