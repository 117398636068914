import axios from 'axios';

import AppConfig from '../config/AppConfig';
import AuthService from '../services/AuthService';
import { getStorage } from '../helpers/Storage';

const baseUrl = AppConfig.get('API_BASE_URL_MP_BEM_VINDO');
const apiKey = AppConfig.get('REACT_APP_API_KEY');

const instance = axios.create({
  baseURL: baseUrl,
});

instance.interceptors.request.use(function (config) {
  const token = getStorage('token');

  if (!!token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.apikey = apiKey;

  return config;
});

instance.interceptors.response.use(
  function (config) {
    return config;
  },
  function (error) {
    if (error?.response?.status === 401) AuthService.userNotAllowed();

    return Promise.reject(error);
  }
);

export default instance;
