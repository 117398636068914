import React from 'react';

import './index.css';

import TextStyles from '../../../components/TextStyles';
import Image from '../../../components/Image';

import bahianinho_mobile from '../../../assets/images/bahianinho_mobile.png';
import bahianinho from '../../../assets/images/bahianinho.png';

import closeIcon from '../../../assets/svg/closeIcon.svg';
import LogoHorizontal from '../../../assets/svg/coloredHorizontalLogo.svg';
import facebook from '../../../assets/svg/facebook.svg';
import hamburgerIcon from '../../../assets/svg/hamburguer.svg';
import logo from '../../../assets/svg/horizontalLogo.svg';
import instagram from '../../../assets/svg/instagram.svg';
import linkedin from '../../../assets/svg/linkedin.svg';
import youtube from '../../../assets/svg/youtube.svg';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

interface MaintenanceProps {
  maintenance: boolean;
}

const Maintenance = ({ maintenance }: MaintenanceProps) => {
  function handleClick() {
    window.location.href = 'https://marketplace.via.com.br/';
  }

  return (
    <>
      <Header
        logo={logo}
        closeIcon={closeIcon}
        hamburgerIcon={hamburgerIcon}
        hasRecord="Já tem cadastro?"
        login="Faça seu login"
        maintenance={maintenance}
      />
      <div className="maintenance-page-container">
        <div className="maintenance-text-content">
          <div className="title-wrapper">
            <TextStyles text="Voltamos em breve!" className="title" />
          </div>
          <div className="maintenance-text-wrapper">
            <TextStyles
              text="Estamos evoluindo alguns pontos em nosso website e ficaremos em manutenção nas próximas horas."
              className="maintenance-text"
            />
          </div>
          <div className="about-via-text-wrapper">
            <TextStyles
              text="Enquanto isso, que tal descobrir um pouco mais sobre a Via?"
              className="about-via-text"
            />
          </div>
          <div className="mktplace-link-wrapper">
            <span className="mktplace-link" onClick={handleClick}>
              Conheça nosso site
            </span>
          </div>
        </div>
        <div className="image-mobile-content">
          <Image images={[{ img: `${bahianinho_mobile}`, alt: 'marketplace-logo-mobile' }]} />
        </div>
        <div className="image-content">
          <Image images={[{ img: `${bahianinho}`, alt: 'marketplace-logo' }]} />
        </div>
      </div>
      <Footer
        logo={LogoHorizontal}
        instagramSvg={instagram}
        youtubeSvg={youtube}
        facebookSvg={facebook}
        linkedinSvg={linkedin}
        titleSocialMedias="Acompanhe a gente"
        legalText="Via S.A. - CNPJ: 33.041.260/0652-90 Av. Rebouças, 3970 28º andar - Pinheiros - São Paulo, SP - CEP: 05402-918"
        TermsUse="Termos de uso"
        PrivacyPolicy="Política de privacidade"
      />
    </>
  );
};

export default Maintenance;
