import { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import AppConfig from '../../config/AppConfig';

import { getStorage } from '../../helpers/Storage';
const apiKey = AppConfig.get('REACT_APP_API_KEY');

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import './index.css';

interface Props {
  pdf?: string;
}

const RenderDocument = ({ pdf }: Props) => {
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  return (
    <div className="wrapper-document">
      <Document
        file={{
          url: pdf,
          httpHeaders: { apiKey: apiKey, authorization: `Bearer ${getStorage('token')}` },
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => console.error('Erro no carregamento do documento', error)}
        loading="Carregando contrato."
        noData="Contrato não encontrado."
        className="contract"
      >
        {numPages &&
          Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} height={800} />
          ))}
      </Document>
    </div>
  );
};

export default RenderDocument;
