import React from 'react';
import { useState } from 'react';
import { Toast } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import './index.css';

export type Variant = 'success' | 'error' | 'warning';

export interface NotificationProps {
  onClose?: Function;
  delay?: number;
  props?: Object;
  children?: React.ReactNode;
  variant?: Variant;
  extraClass?: string;
}

function Notification({
  onClose,
  props,
  children,
  variant = 'success',
  extraClass,
  delay,
}: NotificationProps) {
  const [show, setShow] = useState(true);

  setTimeout(() => {
    setShow(false);
  }, delay);

  const onClick = () => {
    onClose instanceof Function && onClose();
    setShow(false);
  };

  return (
    <Toast
      show={show}
      delay={delay}
      onClose={() => onClose instanceof Function && onClose()}
      autohide
    >
      <Toast.Body>
        <Alert className={`custom-alert ${variant} ${extraClass}`} show={show} {...props}>
          <div>
            {children}
            <div className="close" onClick={onClick}></div>
          </div>
        </Alert>
      </Toast.Body>
    </Toast>
  );
}

export default Notification;
