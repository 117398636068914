import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import Panel from '../../components/Panel';
import Image from '../../assets/svg/panelImage.svg';
import AuthService from '../../services/AuthService';
import { gtmAttributesUpdateComplianceErrorPage } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

const InvalidCNPJ = () => {
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateComplianceErrorPage);

  const logout = () => {
    AuthService.logout();
    navigate('/', { replace: true });
  };

  return (
    <>
      <Panel
        title="Inconsistência no CNPJ cadastrado"
        description={<>Para continuar seu cadastro, faça seu login.</>}
        panelType="confirmation"
        buttonText="Fechar e sair"
        image={Image}
        onClick={logout}
        className="invalid-cnpj-panel"
        buttonId="mp__invalid-cnpj_understood-button"
      >
        <p className="description">
          Orientamos que você procure a Receita Federal pra entender o motivo dessa inconsistência.
        </p>
        <p className="description bold">
          Com isso resolvido, você pode falar com a gente pelo chat e fazer o cadastro.
        </p>
      </Panel>
    </>
  );
};

export default InvalidCNPJ;
