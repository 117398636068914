import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import './index.css';

import Panel from '../../components/Panel';
import Button from '../../components/Button';
import Input from '../../components/Input';

import ServiceRegistration from '../../services/RegisterService';

import { setStorageWithTtl, getStorage } from '../../helpers/Storage';
import {
  gtmAttributesActiveAlert,
  gtmAttributesProcessingShipping,
  gtmAttributesNotMyEmail,
  gtmAttributesResend,
  gtmAttributesUpdateResendEmailPage,
} from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

const serviceRegistration = new ServiceRegistration();
const gtmEventsTracker = useGTMEventsTracker();

const ResendEmail = () => {
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState(<></>);
  const [waitingIdwall, setWaitingIdwall] = useState(false);
  const [email] = useState(getStorage('sellerEmail') || '');
  const navigate = useNavigate();

  gtmEventsTracker(gtmAttributesUpdateResendEmailPage);

  const handleSubmit = async () => {
    setStorageWithTtl('waiting_request_processing', email, 1);
    setWaitingIdwall(true);
    try {
      const sellerId = JSON.parse(getStorage('seller')).id;
      serviceRegistration
        .resendEmail(sellerId)
        .then(() => {
          setVariant('success');
          setMessage(<>Email enviado.</>);
          setShow(true);
          gtmEventsTracker(gtmAttributesActiveAlert);
        })
        .catch(() => {
          setVariant('warning');
          setMessage(
            <>
              Estamos processando seu pedido de reenvio. Seu email poderá chegar em até 8 minutos.
              <strong>Se não chegar, tente novamente ou entre em contato com nosso chat.</strong>
            </>
          );
          setShow(true);
          gtmEventsTracker(gtmAttributesProcessingShipping);
          gtmEventsTracker(gtmAttributesResend);
        });
    } catch (error) {}
  };

  const notMyEmail = () => {
    navigate('/update-email', { replace: true });
    gtmEventsTracker(gtmAttributesNotMyEmail);
  };

  useEffect(() => {
    getStorage('waiting_request_processing') ? setWaitingIdwall(true) : setWaitingIdwall(false);
  }, []);

  return (
    <>
      <Panel
        title="Não recebeu o nosso email?"
        description=""
        panelType="confirmation"
        className="panel-resend-email"
      >
        <p className="description">
          Pode levar até 8 minutos pra ele chegar. Lembre-se de checar a caixa de spam e, se mesmo
          assim não receber, acesse o chat.
        </p>
        <Input
          label="Email cadastrado"
          type="email"
          name="registered-email"
          className="w-100"
          disabled={true}
          value={email}
        />
        <div className="container-btns">
          <Button
            color="blue"
            type="button"
            text="Esse não é meu email"
            outline={true}
            onClick={() => notMyEmail()}
          />
          <Button
            color="blue"
            type="button"
            text="Reenviar email"
            onClick={() => handleSubmit()}
            disabled={waitingIdwall}
          />
        </div>
      </Panel>
      {/* arrumar essa classe de baixo */}
      <div className="mp__resend-email-notify-container">
        <Alert
          show={show}
          variant={variant}
          onClose={() => setShow(false)}
          dismissible
          className="mp__resend-email-notify"
        >
          <p>{message}</p>
        </Alert>
      </div>
    </>
  );
};

export default ResendEmail;
