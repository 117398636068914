import styled from 'styled-components';

interface StyledCheckboxProps {
  checked: boolean;
}

export const ContainerBlackFriday = styled.div`
  background: #0002db;
`;

export const ContentBlackFriday = styled.div`
  max-width: 90rem;
  padding: 4.25rem 12.313rem 0 1.25rem;
  display: grid;
  grid-template: 1fr / 1fr 0.7fr;
  grid-template-areas: 'logo user-form' 'logo-happyKids user-form';
  column-gap: 3rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas: 'logo' 'user-form' 'logo-happyKids';
    padding: 0.875rem 1.5rem 0;
    gap: 0;
  }
`;

export const LogoBlackFriday = styled.img`
  grid-area: logo;
`;

export const FormBlackFriday = styled.div`
  h1 {
    color: #fae209;
    font-weight: 700;
    font-size: 2.75rem;
    line-height: 3.3rem;
    margin-bottom: 1.5rem;
  }
  p {
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }
  label {
    color: #ffffff;
    font-size: 1rem;
    margin: 0 0 0.5rem;
  }
  .mp-bf__register-form_checkbox {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  #mp-bf__input-register_email {
    color: #000000;
  }
  .mp-bf__form {
    display: grid;
    gap: 2rem;
  }
  .form-control.is-invalid {
    background-color: #fcfdfc;
    border: 2px solid #ff502d;
  }
  .validated {
    color: #ffffff;
  }
  @media (max-width: 768px) {
    margin-bottom: 4.188rem;
  }
`;

export const ContainerCheckBox = styled.div<StyledCheckboxProps>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  background-color: white;
  input {
    &:checked ~ span:after {
      display: ${(props) => (props.checked ? 'block' : 'none')};
    }
  }
`;

export const StyledCheckbox = styled.span<StyledCheckboxProps>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  &:after {
    content: '';
    display: none;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fae209;
  border-radius: 8px;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 0.875rem;
`;

export const LogoHappyKids = styled.img`
  grid-area: logo-happyKids;
`;

export const AlertContainer = styled.div`
  position: fixed;
  top: 3.688rem;
  right: 5.5rem;
  width: 23.625rem;
  button[type='button'] {
    background-image: url('./close.png');
    &:focus {
      outline: 0;
      box-shadow: none;
      opacity: 1;
    }
  }
`;
