import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import './App.css';

import Skeleton from './pages/Skeleton';

import ApplicationStatusService from './services/ApplicationStatusService';

import AlternativeRoutes from './routes/AlternativeRoutes';
import DefaultRoutes from './routes/DefaultRoutes';
import AppConfig from './config/AppConfig';

ReactGA.initialize(`${AppConfig.get('GOOGLE_ANALYTICS')}`, {
  testMode: process.env.NODE_ENV === 'test',
});

AppConfig.get('GOOGLE_ANALYTICS');

const ApplicationStatus = new ApplicationStatusService();
const TagManagerArgs = {
  gtmId: `${AppConfig.get('GOOGLE_TAG_MANAGER')}`,
};

TagManager.initialize(TagManagerArgs);

function App() {
  const [applicationStatus, setApplicationStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.pageview('/onboarding');
    ApplicationStatus.getApplicationStatus(applicationStatusValidate);
  }, []);

  const applicationStatusValidate = (response: { isFreezing: boolean; success: boolean }) => {
    setIsLoading(false);
    if (!response.success) {
      return;
    }
    setApplicationStatus(response.isFreezing);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      {applicationStatus ? (
        <AlternativeRoutes applicationStatus={applicationStatus} />
      ) : (
        <DefaultRoutes />
      )}
    </>
  );
}

export default App;
