import React, { FC } from 'react';
import styled from 'styled-components';
import './ImageCard.css';
interface ImageCardInterface {
  src: string;
  colorBackground?: string;
  alt?: string;
}

const BackgroundColor = styled.div`
  overflow: hidden;
  width: 332px;
  height: 14.5rem;
  margin-bottom: 24px;
  border-radius: 20px 20px 20px 320px;
  background: ${(props) => (props.color ? props.color : '#DBDCDC')};
`;

const CustomImg = styled.img`
  width: 180px;
  heigth: 180px;
  padding-top: 15%;
  padding-right: 10%;
`;

const ImageCard: FC<ImageCardInterface> = ({ src, colorBackground, alt }) => {
  return (
    <div className="img-card">
      <BackgroundColor color={colorBackground}>
        <div className="d-flex justify-content-end">
          <CustomImg src={src} alt={alt} />
        </div>
      </BackgroundColor>
    </div>
  );
};

export default ImageCard;
