const OPTIONAL_PREFIX = 'REACT_APP_';
import _envs from './envs';

class AppConfigClass {
  public get(key: string): string | null {
    if (key.startsWith(OPTIONAL_PREFIX)) {
      key = key.substr(OPTIONAL_PREFIX.length);
    }

    const properties = [key, OPTIONAL_PREFIX + key];

    const envs = [(window as any)['ONBOARDING_ENV'], _envs, process.env];

    for (const env of envs) {
      if (!env) {
        continue;
      }
      if (typeof env !== 'object') {
        continue;
      }

      for (const property of properties) {
        if (!(property in env)) {
          continue;
        }
        if (!env[property]) {
          continue;
        }
        if (typeof env[property] !== 'string') {
          continue;
        }
        if (typeof env[property] == 'string' && env[property].startsWith('${')) {
          continue;
        }

        return env[property];
      }
    }

    return null;
  }
}

const AppConfig = new AppConfigClass();
export default AppConfig;
