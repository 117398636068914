import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Input from '../Input';
import Strength from './Strength';
import Hint from './Hint';
import './index.css';
import HtmlInput from '../../interfaces/HtmlInput';
import validations from '../../helpers/Validations';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

interface AttributesGTM {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
}
interface PasswordProps {
  inputProps?: any;
  label?: string;
  showValidation?: boolean;
  onChange: Function;
  loginPassword?: boolean;
  required?: boolean;
  gtmAttributes?: AttributesGTM;
}

function Password({
  inputProps,
  label,
  showValidation = true,
  onChange,
  loginPassword,
  required = false,
  gtmAttributes,
}: PasswordProps) {
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [invalid, setInvalid] = useState(false);

  const [hasUpperAndLower, setHasUpperAndLower] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [minAndMaxLength, setMinAndMaxLength] = useState(false);

  const matchPassword: Function = (regex: RegExp) => regex.test(password);

  const strongPassword: Boolean =
    hasNumber && hasSpecialChar && minAndMaxLength && hasUpperAndLower;
  const rules = required ? { rules: [(val: HtmlInput) => validations.required(val, 'senha')] } : '';

  useEffect(() => {
    onChange({
      isValid: hasNumber && hasSpecialChar && minAndMaxLength && hasUpperAndLower,
      value: password,
    });
  }, [hasUpperAndLower, hasNumber, hasSpecialChar, minAndMaxLength]);

  useEffect(() => {
    setHasNumber(matchPassword(/\d/));
    setHasSpecialChar(matchPassword(/([!@#$%^&*-+=[\]{}|:',?/`~"();]|_|-)/));
    setMinAndMaxLength(password.length >= 8 && password.length <= 16);
    setHasUpperAndLower(matchPassword(/[a-z]/) && matchPassword(/[A-Z]/));
  }, [password]);

  const gtmMEventsTracker = useGTMEventsTracker();

  const hidePassword = () => {
    setVisible(!visible);
    if (gtmAttributes) gtmAttributes.eventLabel = visible ? 'ocultou a senha' : 'exibiu a senha';
    gtmMEventsTracker(gtmAttributes);
  };

  return (
    <div id="mp__password" className="password-container">
      <div className="position-relative">
        <Input
          id={'mp__input-password'}
          type={visible ? 'text' : 'password'}
          errors="Senha inválida"
          name="password"
          label={label ?? 'Senha'}
          onChange={(e: any) => {
            const target = e.target as HTMLInputElement;
            if (target) {
              setPassword(target.value);
              onChange({
                ...e,
                isValid: minAndMaxLength && hasUpperAndLower && hasSpecialChar && hasNumber,
              });
            } else if (typeof e.isValid !== undefined) {
              setInvalid(!e.isValid);
            } else {
              setInvalid(false);
            }
          }}
          loginPassword={loginPassword}
          {...inputProps}
          {...rules}
        >
          <span
            onClick={() => hidePassword()}
            className={`position-absolute cursor-pointer bottom-1 icon-visible-password ${
              invalid ? 'icon-visible-password-invalid' : ''
            }`}
          >
            {visible ? <Visibility /> : <VisibilityOff />}
          </span>
        </Input>
      </div>
      {showValidation ? (
        !loginPassword && (
          <>
            <Strength strong={strongPassword} />
            <p className="mb-1 mp__password-needs">Sua senha precisa ter:</p>
            <ul className="pl-0">
              <Hint text="Entre 8 e 16 caracteres" valid={minAndMaxLength} />
              <Hint text="Letras minúsculas e maiúsculas" valid={hasUpperAndLower} />
              <Hint
                text={
                  'Um dos caracteres especiais ! @ # $ % ^ & * - _ + = [ ] { } | : \' , ? / ` ~ " ( ) ;'
                }
                valid={hasSpecialChar}
              />
              <Hint text="Pelo menos um número" valid={hasNumber} />
            </ul>
          </>
        )
      ) : (
        <></>
      )}
    </div>
  );
}

export default Password;
