import React from 'react';
import Image from '../../assets/svg/panelImage2.svg';
import ImageMobile from '../../assets/svg/panelImageMobile.svg';
import Panel from '../../components/Panel';
import './index.css';

const LoadingDefault = () => {
  return (
    <Panel
      panelType="loading"
      title="Só mais alguns segundos..."
      description={<>Parece que você já está logado, então iremos redirecionar você.</>}
      imageMobile={ImageMobile}
      image={Image}
      className="loading-default-panel"
    />
  );
};

export default LoadingDefault;
