import React from 'react';
import Button from '../../../../components/Button';
import panelImage3 from '../../../../assets/svg/panelImage3.svg';
import panelImage2 from '../../../../assets/svg/panelImage2.svg';
import Panel from '../../../../components/Panel';
import { useNavigate } from 'react-router-dom';

const PasswordStep = () => {
  const navigate = useNavigate();
  const title = 'Sua senha foi alterada';
  const description = 'Para continuar seu cadastro, faça seu login.';
  return (
    <>
      <div className="mp__forgot_password-container confirmation__mobile">
        <div className="mp__forgot_password-image">
          <img src={panelImage3} alt="Confirmação de resete de senha" />
        </div>
        <span className="mp__forgot_password-title">{title}</span>
        <p className="mp__forgot_password-text">{description}</p>
        <div className="mp__forgot_password-action">
          <Button
            id={'mp__btn-confirmation_login'}
            text={'Fazer login'}
            type={'button'}
            color={'blue'}
            classNames={'mp__forgot_password-action_button'}
            onClick={() => navigate('/login')}
          />
        </div>
      </div>
      <div className="confirmation__desktop">
        <Panel
          buttonId={'mp__btn-confirmation_login'}
          buttonText={'Fazer login'}
          onClick={() => navigate('/login')}
          className={'confirmation__desktop-panel'}
          image={panelImage2}
          panelType={'confirmation'}
          title={`${title} com sucesso`}
          description={description}
        />
      </div>
    </>
  );
};

export default PasswordStep;
