export default {
  REACT_APP_PAS_ACCESS: '${REACT_APP_PAS_ACCESS}',
  REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY: '${REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY}',
  REACT_APP_API_BASE_URL_MP_BEM_VINDO: '${REACT_APP_API_BASE_URL_MP_BEM_VINDO}',
  REACT_APP_API_BASE_URL: '${REACT_APP_API_BASE_URL}',
  REACT_APP_BASIC_TOKEN: '${REACT_APP_BASIC_TOKEN}',
  REACT_APP_API_KEY: '${REACT_APP_API_KEY}',
  REACT_APP_CHAT_REGISTER_TOKEN_PRE_REGISTER: '${REACT_APP_CHAT_REGISTER_TOKEN_PRE_REGISTER}',
  REACT_APP_CHAT_REGISTER_TOKEN_AGREEMENT: '${REACT_APP_CHAT_REGISTER_TOKEN_AGREEMENT}',
  REACT_APP_CHAT_REGISTER_TOKEN_FINANCIAL: '${REACT_APP_CHAT_REGISTER_TOKEN_FINANCIAL}',
  REACT_APP_CHAT_REGISTER_TOKEN_INTEGRATION: '${REACT_APP_CHAT_REGISTER_TOKEN_INTEGRATION}',
  REACT_APP_CHAT_REGISTER_TOKEN_SHIPPING: '${REACT_APP_CHAT_REGISTER_TOKEN_SHIPPING}',
  REACT_APP_CHAT_SERVICE_TOKEN: '${REACT_APP_CHAT_SERVICE_TOKEN}',
  REACT_APP_CHAT_SERVICE_URL: '${REACT_APP_CHAT_SERVICE_URL}',
  REACT_APP_GOOGLE_TAG_MANAGER: '${REACT_APP_GOOGLE_TAG_MANAGER}',
  REACT_APP_GOOGLE_ANALYTICS: '${REACT_APP_GOOGLE_ANALYTICS}',
  REACT_APP_GOOGLE_OPTIMIZE: '${REACT_APP_GOOGLE_OPTIMIZE}',
  REACT_APP_ENVIRONMENT: '${REACT_APP_ENVIRONMENT}',
  REACT_APP_LINK_SUPORT_CONTRACT: '${REACT_APP_LINK_SUPORT_CONTRACT}',
  REACT_APP_LINK_CADASTRAR_SENHA: '${REACT_APP_LINK_CADASTRAR_SENHA}',
};
