import React, { LiHTMLAttributes } from 'react';
import './index.css';

interface OrderedListProps {
  children: JSX.Element | JSX.Element[];
}

function Loader({ children }: OrderedListProps) {
  return (
    <ol className="custom-ordered-list">
      {React.Children.map(children, ({ props, type }) => {
        if (type !== 'li') return null;
        return (
          <li className="custom-ordered-item">
            <span>{props.children}</span>
          </li>
        );
      })}
    </ol>
  );
}

export default Loader;
