import React from 'react';
import { CheckCircle, ErrorRounded } from '@mui/icons-material';
import './index.css';

interface HintProps {
  valid: Boolean;
  text: string;
}

function Hint({ valid, text }: HintProps) {
  return (
    <li className="hint d-flex align-items-start">
      {valid ? (
        <CheckCircle fontSize="small" className="check-password-hint" />
      ) : (
        <ErrorRounded fontSize="small" className="error-password-hint" />
      )}{' '}
      <span className="text-roles">{text}</span>
    </li>
  );
}

export default Hint;
