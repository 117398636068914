class DefaultErrorData {
  motivos: Array<string>;
  valido: boolean;
  codigo: string;
  timestamp: any;
  mensagem: string;
  constructor(errorData: any) {
    this.valido = errorData.valido || false;
    this.motivos = errorData.motivos || [];
    this.codigo = errorData.codigo || '';
    this.mensagem = errorData.mensagem || '';
    this.timestamp = errorData.timestamp || '';
  }
}

export default DefaultErrorData;
