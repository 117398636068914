import React from 'react';

import styled from './index.styles';

interface Props {
  img: string;
  link?: string;
  alt: string;
  onClick?: () => void;
}

interface Images {
  images: Array<Props>;
  styles?: string;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
}

const Image = ({ images, styles, target }: Images) => {
  return (
    <styled.Container className={`${styles || ''}`}>
      {images.map((image, index) => (
        <styled.WrapperLogo key={index}>
          <styled.Link href={image.link} target={`${target ?? '_self'}`} onClick={image.onClick}>
            <styled.Image src={`${image.img}`} alt={image.alt} />
          </styled.Link>
        </styled.WrapperLogo>
      ))}
    </styled.Container>
  );
};

export default Image;
