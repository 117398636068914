import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Panel from '../../components/Panel';
import Image from '../../assets/svg/panelImage.svg';
import Button from '../../components/Button';
import OrderedList from '../../components/OrderedList';
import './index.css';
import ChatService from '../../services/ChatService.jsx';
import AppConfig from '../../config/AppConfig';

const Chat = new ChatService();

const ResendEmail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    Chat.createScriptChat(AppConfig.get('REACT_APP_CHAT_REGISTER_TOKEN_PRE_REGISTER'), 3000);
  }, []);

  return (
    <>
      <Panel
        description=""
        title="Como alterar email cadastrado"
        image={Image}
        panelType="confirmation"
        className="panel-update-email"
      >
        <p className="description">
          Acesse o nosso chat no canto inferior direito e insira os dados.
        </p>
        <OrderedList>
          <li>Acesse o nosso chat no canto inferior direito e insira os dados.</li>
          <li>
            <span>
              Em <span className="text-bold">Você já é parceiro do Via Varejo Marketplace?</span>,
              escolha a opção não.
            </span>
          </li>
          <li>
            <span>
              Em{' '}
              <span className="text-bold">Sua dúvida é em relação ao cadastro no Marketplace?</span>
              , escolha a opção sim.
            </span>
          </li>
        </OrderedList>
        <Button
          color="blue"
          text="Ok, entendi"
          type="button"
          classNames={'button-update-panel'}
          onClick={() => navigate('/resend-email', { replace: true })}
        />
      </Panel>
      <a href="/resend-email" className="update-panel-link">
        Voltar
      </a>
    </>
  );
};

export default ResendEmail;
