export type MaskTypes = 'cnpj' | 'phone' | 'cellphone' | 'bankAccount';

type Masks = Record<MaskTypes, (event: React.FormEvent<HTMLInputElement>) => string>;

export const phone = (event: React.FormEvent<HTMLInputElement>) => {
  const { value } = event.currentTarget;

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4})(\d{4})/, '$1-$2')
    .slice(0, 14);
};

export const cellphone = (event: React.FormEvent<HTMLInputElement>) => {
  const { value } = event.currentTarget;
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d{4})/, '$1-$2')
    .slice(0, 15);
};

export const cnpj = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 18;
  const { value } = event.currentTarget;
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{2})/, '$1-$2');
};

export const bankAccount = (event: React.FormEvent<HTMLInputElement>) => {
  event.currentTarget.maxLength = 7;
  const { value } = event.currentTarget;
  return value.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2');
};

const masks: Masks = {
  cnpj,
  phone,
  cellphone,
  bankAccount,
};

export default masks;
