import React, { FC } from 'react';

interface BodyCardInterface {
  body: string;
}

const BodyCard: FC<BodyCardInterface> = ({ body }) => {
  return (
    <div className="body-card">
      <div>{body}</div>
    </div>
  );
};

export default BodyCard;
