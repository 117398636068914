import React from 'react';
import { useNavigate } from 'react-router-dom';

import Panel from '../../components/Panel';
import ImageMobile from '../../assets/svg/panelImageMobile.svg';
import Image from '../../assets/svg/panelImage.svg';
import { gtmAttributesUpdateConfirmationAccountPage } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

import './index.css';

const ConfirmationAccount = () => {
  const navigator = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateConfirmationAccountPage);

  return (
    <div id="mp__confirmation-account">
      <Panel
        title="Sua conta já foi confirmada!"
        description="Para continuar seu cadastro, faça seu login."
        panelType="confirmation"
        buttonText="Fazer login"
        imageMobile={ImageMobile}
        image={Image}
        className="confirmation-account-panel"
        buttonId="mp__button-confirmation-email"
        onClick={() => navigator('/login', { replace: true })}
      />
    </div>
  );
};

export default ConfirmationAccount;
