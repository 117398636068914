import React, { useState } from 'react';
import PasswordStep from './slots/PasswordStep';
import ConfirmationStep from './slots/ConfirmationStep';
import '../index.css';

const ForgotPassword = () => {
  let [step, setStep] = useState(1);

  return (
    <div id="mp__forgot-password">
      {step == 1 ? <PasswordStep next={setStep} /> : <ConfirmationStep />}
    </div>
  );
};

export default ForgotPassword;
