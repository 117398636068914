import axios from '../http';
import { removeStorage, setStorageWithTtl } from '../helpers/Storage';
import url from '../url';

class AuthService {
  setLocalSeller(data: any) {
    setStorageWithTtl('sellerEmail', data.seller.responsible.email, 1440);
    setStorageWithTtl('sellerId', data.seller.id, 1440);
    setStorageWithTtl('token', data.token, 1440);
    setStorageWithTtl('sellerName', data.seller.responsible.name, 1440);
    setStorageWithTtl('seller', JSON.stringify(data), 1440);
    setStorageWithTtl('isLogged', 'true', 1440);
  }

  async login(body: object) {
    return await axios.post(`${url.login.auth}`, body).then((response) => {
      this.setLocalSeller(response.data);
    });
  }

  logout() {
    removeStorage('sellerEmail');
    removeStorage('sellerId');
    removeStorage('token');
    removeStorage('sellerName');
    removeStorage('seller');
    removeStorage('isLogged');
  }

  userNotAllowed() {
    this.logout();
    location.href = location.origin + '/login';
  }
}

export default new AuthService() as any;
