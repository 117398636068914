import { useCallback, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';

import {
  ContainerBlackFriday,
  ContentBlackFriday,
  LogoBlackFriday,
  FormBlackFriday,
  StyledCheckbox,
  ContainerCheckBox,
  Button,
  LogoHappyKids,
  AlertContainer,
} from './index.styles';

import bf2022 from '../../../assets/svg/bf-2022.svg';
import happyKids from '../../../assets/svg/bf-2022-happy-kids.svg';

import HtmlInput from '../../../interfaces/HtmlInput';
import validations from '../../../helpers/Validations';

import Input from '../../../components/Input';

import ServiceRegistration from '../../../services/RegisterService';

interface Usuario {
  nome: string;
  email: string;
  celular: string;
  optin: boolean;
}

export function BlackFriday() {
  const [user, setUser] = useState<Usuario>({
    email: '',
    nome: '',
    celular: '',
  } as Usuario);
  const [checked, setChecked] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [validCellNumber, setValidCellNumber] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [variant, setVariant] = useState('success');
  const [message, setMessage] = useState(<></>);

  const serviceRegistration = new ServiceRegistration();
  const onlyNumber = (number: string) => number.replace(/[^0-9]/g, '');

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validCellNumber || !validEmail) {
      setVariant('warning');
      setShowMessage(true);
      return setMessage(<>Por favor, insira os dados corretamento.</>);
    }
    if (
      user.email &&
      user.email !== '' &&
      user.nome &&
      user.nome !== '' &&
      user.celular &&
      user.celular !== ''
    ) {
      try {
        await preRegister();
      } catch {
        setVariant('warning');
        setShowMessage(true);
        setMessage(<>Não foi possível efetuar o cadastro, tente mais tarde.</>);
      }
      return;
    }
    setVariant('warning');
    setShowMessage(true);
    setMessage(<>Preencha os campos do formulário</>);
  };

  const preRegister = async () => {
    const body = {
      celular: user.celular,
      email: user.email,
      nome: user.nome,
      optinWhats: user.optin ?? checked,
    };
    await serviceRegistration.registerInBlackFriday(body).then(() => {
      setVariant('success');
      setShowMessage(true);
      setMessage(<>Cadastro realizado com sucesso!</>);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    });
  };

  const handleClickCheckBox = () => {
    setChecked(!checked);
  };

  const handleChange = useCallback(
    (e: any) => {
      if (e.target && typeof e.target.value !== undefined) {
        setUser({
          ...user,
          [e.target.name]: e.target.value,
        });
      }
    },
    [user]
  );

  const validCellPhone = (e: any) => {
    if (e?.target?.value) {
      setUser({
        ...user,
        celular: onlyNumber(e.target.value),
      });
    }
    setValidCellNumber(e.isValid);
  };

  const setEmailValue = (e: any) => {
    if (e.target && typeof e.target.value !== undefined) {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      });
    }
    setValidEmail(e.isValid);
  };

  return (
    <>
      <ContainerBlackFriday>
        <ContentBlackFriday>
          <LogoBlackFriday src={bf2022} alt="" className="logo" />
          <FormBlackFriday className="user-form">
            <h1>No momento, pausamos os novos cadastros.</h1>
            <p>
              Do dia 04/11 até 28/11, estaremos focados em realizar a maior Black Friday do Brasil.
              <br />
              Até lá, preencha seus dados abaixo e assim que voltarmos, entraremos em contato com
              você.
            </p>
            <Form noValidate onSubmit={handleSubmit} className="w-full mp-bf__form">
              <Input
                id={'mp-bf__input-register_email'}
                label="E-mail"
                type="email"
                name="email"
                rules={[(val: HtmlInput) => validations.isValidEmail(val, true)]}
                onChange={(e: any) => setEmailValue(e)}
              />
              <Input
                id={'mp-bf__input-register_name'}
                label="Nome e sobrenome"
                type="text"
                name="nome"
                rules={[(val: HtmlInput) => validations.required(val, 'nome e sobrenome')]}
                onChange={handleChange}
              />
              <Input
                id={'mp-bf__input-register_cell'}
                label="Telefone celular"
                type="phone"
                name="celular"
                maskType="cellphone"
                rules={[
                  (val: HtmlInput) => validations.length(val, 15, 'celular'),
                  (val: HtmlInput) => validations.required(val, 'celular'),
                  (val: HtmlInput) => validations.isNotPhoneNumber(val, 'celular'),
                ]}
                onChange={(e: any) => validCellPhone(e)}
              />
              <div className="mp-bf__register-form_checkbox">
                <ContainerCheckBox checked={checked} onClick={handleClickCheckBox}>
                  <input type="checkbox" hidden defaultChecked={checked} />
                  <StyledCheckbox checked={checked} />
                </ContainerCheckBox>
                <label>Aceito receber informações via Whatsapp</label>
              </div>
              <Button id="mp-bf__btn-submit_register" type="submit">
                Enviar dados
              </Button>
            </Form>
          </FormBlackFriday>
          <LogoHappyKids src={happyKids} alt="" className="logo-happyKids" />
        </ContentBlackFriday>
      </ContainerBlackFriday>
      <AlertContainer>
        <Alert
          show={showMessage}
          variant={variant}
          onClose={() => {
            setShowMessage(false);
          }}
          dismissible
        >
          <p>{message}</p>
        </Alert>
      </AlertContainer>
    </>
  );
}
