import React from 'react';

import Panel from '../../components/Panel';
import ImageMobile from '../../assets/svg/panelImageMobile2.svg';
import Image from '../../assets/svg/panelImage.svg';

import './index.css';

const EmailSent = () => {
  return (
    <div id="mp__email-sent">
      <Panel
        title="Seu email foi confirmado"
        description={
          <>
            Por favor, <strong>verifique a sua caixa de entrada e de spam.</strong> Ao encontrar,
            abra o link que está no email pra confirmar que recebeu.
          </>
        }
        panelType="confirmation"
        imageMobile={ImageMobile}
        image={Image}
        className="email-sent-panel"
      />
    </div>
  );
};

export default EmailSent;
