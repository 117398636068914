import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './index.css';

import PanelImage from '../../assets/svg/panelImage2.svg';
import ImageMobile from '../../assets/svg/panelImageMobile.svg';
import { gtmAttributesUpdateTokenErrorPage } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

import Panel from '../../components/Panel';

interface LocationState {
  to: string;
}

const IntegrationError = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { to } = location.state as LocationState;
  const gtmEventsTracker = useGTMEventsTracker();
  gtmEventsTracker(gtmAttributesUpdateTokenErrorPage);

  return (
    <Panel
      panelType="confirmation"
      title="Integradora habilitada"
      description={
        <>
          A integradora de produtos da sua loja foi habilitada em nossa plataforma. Você{' '}
          <strong>receberá o token de ativação por email.</strong>
        </>
      }
      image={PanelImage}
      imageMobile={ImageMobile}
      buttonText="Continuar"
      buttonId="mp__integration-error_btn"
      onClick={() => navigate(to)}
      className="mp__integration-error-panel"
    />
  );
};

export default IntegrationError;
