import React, { useState } from 'react';
import Select, { DropdownIndicatorProps, components, StylesConfig } from 'react-select';
import './index.css';

interface Props {
  placeholder?: string;
  label?: string;
  linkprops?: {
    text: string;
    link: string;
  };
  onClick?: () => void;
  data: readonly unknown[];
  isMulti?: boolean;
  noOptionsMessage?: (object: { inputValue: string }) => string;
  change: (e: any) => any;
  component?: React.ComponentType<DropdownIndicatorProps>;
  id?: string;
  filterOption?: () => boolean;
  onInputChange?: (e: any) => Event;
  isFocused?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  styles?: StylesConfig;
}

export function Select2({
  placeholder,
  data,
  isMulti,
  noOptionsMessage,
  change,
  component,
  label,
  linkprops,
  id,
  filterOption,
  onInputChange,
  isFocused,
  styles,
  onFocus,
  onBlur,
}: Props) {
  const [selected, setSelected] = useState({ label: null, value: null });
  const set = (e: any) => {
    setSelected(e);
    change(e);
  };

  const options = data.map((option: any) => {
    return {
      label: option.label,
      value: option,
    };
  });

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.key !== 'placeholder' ? child : null
        )}
      </ValueContainer>
    );
  };

  return (
    <div id="mp__select2-container">
      <div className="mp__select2-infos">
        {label && <label>{label}</label>}
        {linkprops && (
          <a href={linkprops.link} target="_blank">
            {linkprops.text}
          </a>
        )}
      </div>
      <Select
        placeholder={placeholder}
        options={options}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        onChange={set}
        value={selected}
        components={{
          DropdownIndicator: component,
          ValueContainer: CustomValueContainer,
        }}
        id={id}
        filterOption={filterOption}
        onInputChange={onInputChange}
        onFocus={onFocus}
        onBlur={onBlur}
        styles={styles}
      />
    </div>
  );
}
