import styled from 'styled-components';
import { Navbar, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import color from '../../utils/colors';

interface IsClickedProps {
  clicked: string | undefined;
}

interface IsLoginProps {
  login: string | undefined;
}

const Header = styled(Navbar)<IsClickedProps>`
  width: 100%;
  background-color: ${color.primary_dark};
  border-radius: 0px;
  padding: 16px 48px 16px 120px;
  justify-content: space-between;

  @media (max-width: 992px) {
    ${(props) => props.clicked && `border-radius: 0px 0px 9px 9px;`}
    padding: 0px 0px;
    background-color: transparent;
  }
`;

const ContentHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 992px) {
    width: 100%;
    padding: 16px 24px;
    background-color: ${color.primary_dark};
  }
`;

const WrapperLogo = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 256px) {
    img {
      width: 75%;
      margin: 0 auto;
    }
  }

  @media (max-width: 992px) {
    justify-content: center;
  }
`;

const WrapperLogin = styled(Navbar.Collapse)<IsLoginProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: initial;
  border-radius: 0px;

  @media (max-width: 992px) {
    height: 120px;
    box-shadow: 0px 4px 4px rgba(33, 33, 33, 0.12);
    border-radius: 0px 0px 8px 8px;

    padding: 25px;
    justify-content: ${({ login }) => (login ? 'space-between' : 'center')};
    background-color: ${color.neutral_lightest};
    transition: all 0s ease -2s;
  }
`;

const UserInformation = styled.span<IsLoginProps>`
  font-size: 14px;
  color: ${color.neutral_lightest};
  font-family: 'Poppins';
  margin: ${({ login }) => (login ? '0 48px 0 0' : '0')};

  @media (max-width: 992px) {
    color: ${color.neutral_darkest};
    font-weight: 400;
    margin: 0;
  }
`;

const Login = styled.a`
  font-size: 14px;
  color: ${color.neutral_lightest};
  font-family: 'Poppins';
  font-weight: 600;
  cursor: pointer;
  margin-left: 4px;
  text-decoration: none;

  &:hover {
    color: ${color.neutral_lightest};
  }
  @media (max-width: 992px) {
    color: ${color.primary_dark};
  }
`;

const WrapperHamburguer = styled.div`
  position: relative;
`;

const MenuIcon = styled.img`
  width: 21px;
  height: 18px;
`;

const Hamburguer = styled(Navbar.Toggle)`
  padding: 0;
  color: rgba(0, 0, 0, 0) !important;
  border-color: rgba(0, 0, 0, 0) !important;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 0rem;
  }
`;

const Logout = styled(Button)<IsLoginProps>`
  border-radius: 8px;

  color: ${color.neutral_lightest};
  border-color: ${color.neutral_lightest};
  background-color: transparent;
  font-family: Poppins;
  font-weight: 600;
  font-size: 14px;
  padding: 12px 38px;

  &:hover {
    background-color: transparent;
    border-color: ${color.neutral_lightest};
  }

  @media (max-width: 992px) {
    color: ${color.neutral_lightest};
    background-color: ${color.primary_dark};
    border-color: ${color.primary_dark};
  }
`;

export default {
  Header,
  ContentHeader,
  WrapperLogo,
  WrapperLogin,
  UserInformation,
  Login,
  WrapperHamburguer,
  Hamburguer,
  MenuIcon,
  Logout,
};
