import AppConfig from '../config/AppConfig';

const url = {
  base: AppConfig.get('API_BASE_URL_MP_BEM_VINDO'),
  register: {
    sellerActivate: 'api/v2/site/seller/activate',
    sellerHuntingActivate: 'api/v2/site/seller/activate/hunting',
    sellerDefaultContract: 'api/v2/site/seller/contract/default',
    sellerCustomContract: 'api/v2/site/seller/contract/custom',
    getProductIntegration: 'api/v2/site/product/integrator',
    updateProductIntegration: 'api/v2/site/product/integrator',
    complete: 'api/v2/site/seller/registration/completed',
    createSeller: 'api/v2/site/seller',
    freezing: 'api/v2/site/seller/freezing',
    resendEmail: 'api/v2/site/seller/add-to-retry',
  },
  bank: {
    updateBank: 'api/v2/site/seller/bank',
    getBanks: 'api/v2/site/bank',
    suggestedBanks: 'api/v2/site/bank/suggested',
    listAcceptedBanks: 'api/v2/site/bank/get-accepted-list-pdf',
  },
  contract: {
    getContractCustom: 'api/v2/site/seller/contract/:contractHash',
  },
  auth: {
    recoveryPassword: 'api/v2/site/seller/recover-password',
    newPassword: 'api/v2/site/seller/recover-password/new-password',
  },
  login: {
    auth: 'api/v2/site/auth',
  },
  applicationStatus: {
    freezing: 'api/v2/site/configuration/freezing',
  },
};

export default url;
