import { ChangeEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import './index.css';

import ProgressBar from '../../components/ProgressBar';
import ProgressStep from '../../components/ProgressStep';
import TextStyles from '../../components/TextStyles';
import RenderDocuments from '../../components/RenderDocuments';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import StepInterface from '../../interfaces/Step';
import StepClass from '../../entities/Step';
import DefaultContract from './DefaultContract';
import ServiceRegistration from '../../services/RegisterService';
import { gtmAttributesSignedContract } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';
import { gtmAttributesUpdateContractPage } from '../../utils/gtmVariables';
import AppConfig from '../../config/AppConfig';
import { getStorage } from '../../helpers/Storage';

const serviceRegistration = new ServiceRegistration();

interface Contract {
  sellerEntrance: string;
  type: string;
  url: string;
}

interface Seller {
  contract: Contract;
  contractHash: string;
}
interface SellerProps {
  seller: Seller;
}

const Contract = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [warning, setWarning] = useState(false);
  const [acceptContract, setAcceptContract] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [seller, setSeller] = useState<SellerProps>();
  const [defaultContractText, setDefaultContractText] = useState('Assinar e continuar');

  const Step = new StepClass();

  const seller_entrance = seller?.seller.contract.sellerEntrance;
  const contract_hash = seller?.seller.contractHash;
  const BASE_URL_CONTRACT_HUNTING = `${AppConfig.get(
    'API_BASE_URL_MP_BEM_VINDO'
  )}api/v2/site/seller/contract/`;
  const contract_url = `${BASE_URL_CONTRACT_HUNTING}${contract_hash}`;

  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  useEffect(() => {
    gtmEventsTracker(gtmAttributesUpdateContractPage);
    setSeller(JSON.parse(getStorage('seller')));
  }, []);

  const handleAcceptContract = (e: ChangeEvent<HTMLInputElement>) => {
    setAcceptContract(e.target.checked);
  };

  const handleClick = async () => {
    setAcceptContract(true);
    setDisableButton(true);
    setDefaultContractText('Carregando...');
    gtmEventsTracker(gtmAttributesSignedContract);
    try {
      await serviceRegistration.confirmContract({});
      setShow(true);
      setDefaultContractText('Assinar e continuar');
      setTimeout(() => {
        navigate('/step/bank');
      }, 3000);
    } catch (error) {
      setError(true);
      setDefaultContractText('Assinar e continuar');
    }
  };

  const handleConfirmContractHunting = async () => {
    setShow(true);
    gtmEventsTracker(gtmAttributesSignedContract);
    try {
      await serviceRegistration.confirmContractHunting({});
      navigate('/step/bank');
    } catch (error) {
      setError(true);
    }
  };

  const current: number = 2;
  const steps: Array<StepInterface> = Step.steps();

  const contractCustom = (
    <>
      <RenderDocuments pdf={contract_url} />
      <div className="mp__contract-button-wrapper">
        <Button
          text="Validar e continuar"
          color="blue"
          type="button"
          onClick={handleConfirmContractHunting}
          id="mp__validate-contract-custom-button"
        />
      </div>
    </>
  );

  const contractDefault = (
    <>
      <DefaultContract />
      <div className="mp__acceptance-checkbox-wrapper">
        <Checkbox
          label="Li todo o contrato e aceito as condições"
          value={acceptContract}
          onChange={handleAcceptContract}
          disabled={disableButton}
        />
      </div>
      <div className="mp__contract-button-wrapper">
        <Button
          text={defaultContractText}
          color="blue"
          type="button"
          id="mp__validate-contract-default-button"
          onClick={handleClick}
          disabled={disableButton || !acceptContract}
        />
      </div>
    </>
  );

  const contractHunting: any = {
    default: contractDefault,
    custom: contractCustom,
  };

  const sellerContract =
    seller_entrance == 'hunting'
      ? contractHunting[seller?.seller.contract.type ?? 'default']
      : contractDefault;

  return (
    <>
      <div className="mp__contract-content">
        <div className="mp__contract-progressbar">
          <ProgressStep steps={steps} current={current} />
        </div>
        <div className="mp__contract-progress_mobile">
          <ProgressBar
            total={steps.length}
            step={current}
            subtitle={steps.find((e) => e.step === current)?.title ?? ''}
          />
        </div>
        <div className="mp__contract-text-wrapper">
          <TextStyles text="Leia todo o contrato com atenção." className="mp__contract-title" />
          <TextStyles
            text="Se ficar com alguma dúvida, fale com a gente através do chat."
            className="mp__contract-subtitle"
          />
        </div>
        <div className="mp__contract-text-mobile-wrapper">
          <TextStyles text="Leia todo o contrato com atenção." className="mp__contract-title" />
          <TextStyles
            text="Se ficar com alguma dúvida, fale com a gente através do chat."
            className="mp__contract-subtitle"
          />
        </div>
        {seller && <section className="mp__contract">{sellerContract}</section>}
      </div>
      <div className="mp__validated-contract-notify-container">
        <Alert
          show={show && !error}
          variant="success"
          onClose={() => setShow(false)}
          dismissible
          closeVariant="white"
          className="mp__validated-contract-notify"
        >
          {sellerContract == 'default' ? (
            <p>
              <strong>Contrato assinado!</strong> Enviamos uma cópia do contrato para seu email.
            </p>
          ) : (
            <p>
              <strong>Contrato validado!</strong> Enviamos uma cópia do contrato para seu email.
            </p>
          )}
        </Alert>
        <Alert
          show={show && error}
          variant="error"
          onClose={() => {
            setError(false);
            setShow(false);
          }}
          dismissible
          closeVariant="white"
          className="mp__error-notify"
        >
          <p>Erro ao assinar o contrato</p>
        </Alert>
        <Alert
          show={warning}
          variant="error"
          onClose={() => {
            setWarning(false);
          }}
          dismissible
          closeVariant="white"
          className="mp__error-notify"
        >
          <p>Token inválido, faça o login para acessar seu contrato!</p>
        </Alert>
      </div>
    </>
  );
};

export default Contract;
