import React from 'react';
import TagManager from 'react-gtm-module';

const useGTMEventsTracker = () => {
  const trackEvent = (dataLayer?: object) => {
    TagManager.dataLayer({
      dataLayer: dataLayer,
    });
  };
  return trackEvent;
};

export default useGTMEventsTracker;
