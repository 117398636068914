import { ChangeEvent, useState, useEffect } from 'react';
import './index.css';
import AppConfig from '../../../config/AppConfig';

const DefaultContract = () => {
  const LINK_FOR_CALLED = `${AppConfig.get('REACT_APP_LINK_SUPORT_CONTRACT')}`;
  return (
    <div className="mp__default-contract-section">
      <p className="mp__document-title font-bold">
        CONDIÇÕES GERAIS PARA COMERCIALIZAÇÃO EM AMBIENTE ELETRÔNICO – MARKETPLACE
      </p>
      <p className="text-justify break-words">
        Termos e condições gerais aplicáveis à utilização do ambiente virtual da
        <span className="font-bold"> VIA S.A.</span>, sociedade anônima, com sede na cidade e Estado
        de São Paulo, na Avenida Rebouças nº 3.970, 28º andar, Pinheiros, CEP: 05.402-918, inscrita
        no CNPJ/ME sob o nº 33.041.260/0652-90 (“VIA”) e{' '}
        <span className="font-bold">VVLog Logística Ltda.,</span>
        sociedade limitada, com sede na cidade de Jundiaí, Estado de Sadio Paulo, na Rodovia
        Anhanguera s/nº, Km 52, Vila Militar, CEP: 13.203-850, inscrita no CNPJ/ME nº
        04.221.023/0001-87 (“VVLOG”), neste ato representadas na forma de seus atos societários por
        Abel Ornelas Vieira, brasileiro, casado, economista, portador da Cédula de Identidade RG nº
        13.877.647 SSP/SP, inscrito no CPF/ME sob o nº 075.240.338-93 e Helisson Brigido Andrade
        Lemos, brasileiro, casado, administrador de empresas, portador da Cédula de Identidade RG nº
        32.454.05 SSP/GO, inscrito no CPF/ME sob o nº 816.205.781-15, ambos com domicílio
        profissional na cidade e Estado de São Paulo, na Avenida Rebouças nº 3.970, 28º andar,
        Pinheiros, CEP: 05.402-918, para oferta e comercialização de produtos e/ou serviços de
        terceiros (“Condições do Marketplace”).
      </p>
      <p className="font-bold underline">1. DEFINIÇÕES</p>
      <div>
        <p>
          Os termos dispostos neste instrumento com a primeira letra em maiúsculo terão o
          significado que a eles é atribuído nesta ou nas demais Cláusulas deste instrumento:
        </p>
        <p>
          a) <span className="font-bold">“Acordo de Nível de Serviço”</span> ou
          <span className="font-bold">“ANS”</span>: condições estabelecidas entre as Partes para
          garantia dos níveis de qualidade a serem observados pelo PARCEIRO na utilização do
          Marketplace. O ANS será definido pela VIA e comunicado ao PARCEIRO na Plataforma;
        </p>
        <p>
          b) <span className="font-bold">“Avaliação”</span>: análise das informações prestadas e
          documentos entregues pelo PARCEIRO, bem como consulta à base de dados de terceiros, quanto
          à regularidade, performance e qualidade do PARCEIRO para aprovação do cadastro e/ou,
          durante a Parceria, para manter ativo o cadastro do PARCEIRO no Marketplace da VIA;
        </p>
        <p>
          c) <span className="font-bold">“Cliente”</span>: consumidores que utilizam o Site para
          adquirir Produtos e Serviços;
        </p>
        <p>
          d) <span className="font-bold">“Concorrentes Diretos do Site”</span>: quaisquer sites ou
          ambientes de comércio eletrônico que têm por objeto o anúncio, comercialização, pesquisa
          de preços, comparação de produtos e serviços, avaliação de empresas e decisão de compra;
        </p>
        <p>
          e) <span className="font-bold">“Dados de Acesso”</span>: login e senha do Usuário do
          PARCEIRO devidamente cadastrado na Plataforma;
        </p>
        <p>
          f) <span className="font-bold">“Encargos”</span>: quaisquer custos, despesas incorridas
          pela VIA ou montantes a serem pagos pelo PARCEIRO em razão da utilização das ferramentas
          do Marketplace, incluindo, sem se limitar, aos valores devidos a título de ressarcimento
          em razão de prejuízos e/ou danos iminentes ou causados;
        </p>
        <p>
          g) <span className="font-bold">“Envvias” </span>: programa de serviços logísticos
          prestados pela VVLOG que, inclui, sem se limitar, os Serviços de Entrega (conforme abaixo
          definido) disponibilizados ao PARCEIRO;
        </p>
        <p>
          h) <span className="font-bold">“Formas de Pagamento”</span>: meios de pagamento
          disponibilizados pela VIA no Marketplace, que poderão ser utilizadas pelo Cliente para
          pagamento dos Itens adquiridos com o PARCEIRO;
        </p>
        <p>
          i) <span className="font-bold">“Formulário de Adesão”</span>: documento preenchido com os
          dados completos de qualificação do PARCEIRO para solicitar o cadastro e a adesão para
          poder anunciar e comercializar seus Itens no Marketplace da VIA;
        </p>
        <p>
          j) <span className="font-bold">“Gestão de Pagamentos” </span>: atividades de recebimento,
          gestão e organização dos Pagamentos realizados pelos Clientes, desenvolvidas pela VIA em
          benefício do Marketplace e dos PARCEIROS;
        </p>
        <p>
          k) <span className="font-bold">“Itens”</span>: Produtos e/ou Serviços comercializados pelo
          PARCEIRO no Marketplace da VIA;
        </p>
        <p>
          l) <span className="font-bold">“Layout”</span>: desenho, plano, esquema, forma ou design
          do Site, ou de quaisquer sites e ferramentas utilizadas pela VIA para organização e gestão
          do Marketplace;
        </p>
        <p>
          m) <span className="font-bold">“Manual do Envvias”</span>: Documento da VVLOG que contém
          todas as condições e procedimentos operacionais relacionados aos Serviço de Entrega e
          estará disponível para consulta, pelo PARCEIRO, na Plataforma. A VIA poderá atualizar o
          Manual do Envvias, a seu exclusivo critério, comunicando ao PARCEIRO cada vez que uma
          alteração ocorrer;
        </p>
        <p>
          n) <span className="font-bold">“Marketplace”</span>: plataforma disponível no Site da VIA
          para anúncio e comercialização de Itens de terceiros que, após a Avaliação e cumprimento
          de todos os requisitos deste Contrato, estejam aptos para efetivação do cadastro pela VIA;
        </p>
        <p>
          o) <span className="font-bold">“Pagamentos”</span>: montantes pagos pelos Clientes
          decorrentes da compra de Produtos e/ou Serviços, bem como o valor do frete relacionado à
          entrega do Item adquirido.
        </p>
        <p>
          p) <span className="font-bold">“Parceiro”</span>: pessoa jurídica, regularmente
          constituída, que tenha preenchido o Formulário de Adesão e, após a Avaliação e cumprimento
          de todos os requisitos deste Contrato, tenha seu cadastro aceito pela VIA para anunciar e
          comercializar seus Itens no Marketplace;
        </p>
        <p>
          q) <span className="font-bold">“Parceria”</span>: relação efetivada entre o PARCEIRO e a
          VIA para a oferta e comercialização dos Itens do PARCEIRO no Marketplace da VIA;
        </p>
        <p>
          r) <span className="font-bold">“Plataforma</span>: ferramenta disponibilizada e indicada
          pela VIA ao PARCEIRO para (i) efetuar a gestão da comercialização de seus Itens no
          Marketplace; e, (ii) integração e comunicação entre a VIA e PARCEIRO;
        </p>
        <p>
          s) <span className="font-bold">“Produto”</span>: qualquer bem, mercadoria ou produto
          legalmente fabricado, desenvolvido, importado, distribuído ou de qualquer maneira
          comercializado pelo PARCEIRO;
        </p>
        <p>
          t) <span className="font-bold">“Remuneração VIA</span>: valor devido pelo PARCEIRO em
          favor da VIA em razão dos recursos e ferramentas disponibilizados no Marketplace, bem como
          pela realização das atividades de Gestão dos Pagamentos, a ser pago mediante emissão da
          respectiva nota fiscal pela VIA;
        </p>
        <p>
          u) <span className="font-bold">“Remuneração VVLOG”</span>: valor do frete determinado pela
          VVLOG e pago pelo Cliente em razão dos Serviços de Entrega;
        </p>
        <p>
          v) <span className="font-bold">“Repasse”</span>: transferência, pela VIA, VVLOG, ou por
          instituição de pagamento regularmente contratada pela VIA, ao PARCEIRO, dos Pagamentos, já
          efetuada a Retenção;
        </p>
        <p>
          w) <span className="font-bold">“Retenção”</span>: faculdade da VIA para reter, antes do
          Repasse, a Remuneração VIA, a Remuneração VVLOG e demais Encargos devidos pelo PARCEIRO à
          VIA ou à VVLOG, diretamente do valor recebido em razão dos Pagamentos realizados;
        </p>
        <p>
          x) <span className="font-bold">“Serviço”</span>: significa qualquer serviço legalmente
          desenvolvido, prestado ou de qualquer outra forma fornecido pelo PARCEIRO;
        </p>
        <p>
          y) <span className="font-bold">“Serviços de Entrega”</span>: significa serviço de entrega
          prestado pela VVLOG, diretamente ou por terceiro subcontratado, ao Parceiro, para a
          entrega do Item adquirido no Marketplace da VIA no endereço indicado pelo Cliente,
          incluindo, o serviço de atendimento ao Cliente, bem como gestão e atualização das
          informações de entrega do pedido na Plataforma, ambos, restritamente relacionados ao
          transporte e entrega do Item;
        </p>
        <p>
          z) <span className="font-bold">“Site”</span>: um ou mais endereços eletrônicos
          desenvolvidos e gerenciados pela VIA e utilizados como ferramenta para operacionalização
          do Marketplace, acessível por meio de domínio registrado e de titularidade da VIA;
        </p>
        <p>
          aa) <span className="font-bold">“Termo de Uso do Site”</span>: termos de uso aos quais os
          Clientes se submetem ao adquirir Produtos ou Serviços comercializados no Site;
        </p>
        <p>
          bb) <span className="font-bold">“Território”</span>: nacional brasileiro;
        </p>
        <p>
          cc) <span className="font-bold">“Usuário”</span>: pessoa indicada pelo COMERCIANTE, com
          autonomia e todos os poderes necessários para assumir quaisquer obrigações e
          responsabilidades, perante a VIA, que será cadastrado e possuirá os Dados de Acesso para a
          Plataforma.
        </p>
        <p>
          1.1. Os termos que não foram acima relacionados, porém que tenham significado técnico
          usualmente aplicado no mercado, ou que no decorrer da relação contratual venham a ser
          utilizados nos usos e costumes comerciais, inclusive aqueles grafados em idioma
          estrangeiro, deverão ser compreendidos e interpretados em consonância com o conceito
          internacionalmente consagrado, no que não conflitarem com as definições aqui
          convencionadas.
        </p>
      </div>
      <p className="font-bold underline">2. ADESÃO E RELACIONAMENTO DAS PARTES</p>
      <div>
        <p>
          2.1. As Condições do Marketplace são aplicáveis e deverão ser integralmente observadas e
          cumpridas por toda e qualquer empresa, devidamente qualificada no Formulário de Adesão,
          que tenha interesse em anunciar e comercializar seus Itens no Marketplace da VIA.
        </p>
        <p>
          2.2. <span className="font-bold">Adesão</span>. Neste ato, o PARCEIRO toma ciência,
          concorda e adere às Condições do Marketplace descritas neste instrumento.
        </p>
        <p>
          2.2.1. A adesão pelo PARCEIRO às Condições do Marketplace é indispensável para a
          utilização do Site para anunciar os Itens que serão comercializados, bem como utilizar os
          serviços tecnológicos da plataforma da VIA para a intermediação da relação entre o
          PARCEIRO e os Clientes.
        </p>
        <p className="font-bold">Cadastro e Avaliação do PARCEIRO</p>
        <p>
          2.3. O PARCEIRO seguirá todas as orientações da VIA para cadastro, acesso e utilização de
          toda e qualquer plataforma disponibilizada para utilização em razão da Parceria, se
          comprometendo a adotar todas as práticas de segurança da informação e sigilo para tal
          acesso.
        </p>
        <p>
          2.4. O PARCEIRO desde já se compromete, por seu Usuário, a não compartilhar seu login e
          senha (“Dados de Acesso”), e/ou dados de que são pessoais e intransferíveis. Os Dados de
          Acesso pertencerão ao Usuário do PARCEIRO, que será cadastrado na Plataforma para
          utilização do Marketplace da VIA e terá todos os poderes necessários para assumir
          quaisquer obrigações e responsabilidades, perante a VIA, nos termos deste instrumento.
        </p>
        <p>
          2.4.1. Diante de qualquer uso ou acesso à conta, por terceiros não autorizado ou extravio,
          perda, roubo, além de qualquer quebra de segurança de seu conhecimento, o PARCEIRO
          compromete-se em realizar o procedimento de alteração imediata da senha de acesso e a
          notificar a VIA imediatamente, e através de seus canais de comunicação, para adoção das
          medidas de segurança necessárias.
        </p>
        <p>
          2.5. O PARCEIRO não transmitirá materiais contendo vírus de software ou outros códigos,
          arquivos, scripts, agentes ou programas de computador prejudiciais ou nocivos.
        </p>
        <p>
          2.6. O PARCEIRO se responsabiliza por todas as atividades que ocorrerem, sob a sua conta
          de acesso na Plataforma.
        </p>
        <p>
          2.7. Após o preenchimento correto e integral, pelo PARCEIRO, do Formulário de Adesão ao
          Marketplace da VIA, o PARCEIRO passará pela Avaliação, que poderá, no prazo de até 30
          (trinta) dias rejeitar a inclusão do PARCEIRO no Marketplace, suspendendo imediatamente
          seus acessos e as configurações na plataforma para (i) que sejam prestados esclarecimentos
          ou providenciados ajustes; ou, (ii) tornar sem efeitos este instrumento, encerrando a
          Parceria.
        </p>
        <p>
          2.8. O PARCEIRO se responsabiliza pelo preenchimento de todos os campos do Formulário de
          Adesão e da Plataforma, com informações exatas e verdadeiras, respondendo civil e
          criminalmente, pela veracidade e autenticidade das informações prestadas, bem como, uma
          vez aprovada, pela VIA, a inclusão do PARCEIRO no Marketplace, a manutenção de todas as
          informações devidamente atualizadas.
        </p>
        <p>
          2.9. A Avaliação e aprovação do PARCEIRO está condicionada à apresentação obrigatória de
          todos os documentos e informações necessárias para demonstração de sua regularidade junto
          a todas e quaisquer autoridades ou entidades públicas ou privadas que legalmente possam
          ser exigidos para realização de suas atividades de comercialização dos Itens. A VIA
          poderá, a qualquer momento, solicitar informações e documentos adicionais para conclusão
          da Avaliação, bem como, poderá, a qualquer tempo, durante a Parceria, realizar novas
          Avaliações do PARCEIRO.
        </p>
        <p>
          2.9.1. Caso a VIA identifique, em qualquer Avaliação, qualquer irregularidade nas
          informações ou na documentação fornecida pelo PARCEIRO, a VIA suspenderá a comercialização
          dos Itens do PARCEIRO no Marketplace, realizará, se aplicável, a Retenção dos Repasses, e
          o notificará para que regularize as pendências, no prazo de 2 (dois) dias corridos
          contados da entrega da notificação ou, se for o caso, em prazo inferior comunicado pela
          VIA na própria notificação. Caso a irregularidade permaneça após o prazo indicado, a VIA
          poderá, a seu exclusivo critério, rescindir o Contrato de pleno direito.
        </p>
        <p>
          2.9.2. Além de analisar a documentação do PARCEIRO, a VIA, durante a vigência do contrato
          poderá consultar bases de dados de terceiros que contenham avaliação do PARCEIRO, tais
          como, órgãos públicos, entidades de proteção ao crédito e instituições que avaliam o
          compromisso com o atendimento e proteção ao consumidor.
        </p>
        <p>
          <span className="font-bold">Contrato.</span> O relacionamento entre as Partes será regido
          por este instrumento, pelo Manual do Envvias e pelas condições comerciais e específicas
          acordadas pelas Partes na Plataforma do Marketplace, de modo que as Partes ajustarão e
          manterão diretamente na Plataforma, ao menos: (i) informações completas de contatos e
          cadastrais do PARCEIRO; (ii) descrição dos Itens que o PARCEIRO pretende comercializar
          no(s) Site(s); (iii) Site(s) em que o PARCEIRO poderá comercializar seus Itens no
          Marketplace; (iv) percentual de comissão a ser paga à VIA a título de Remuneração VIA (
          <a href="https://marketplace.via.com.br/taxas" target="_blank" rel="noopener noreferrer">
            Comissão
          </a>
          ); (v) gestão dos pagamentos; (vi) ANS e índice de qualidade do PARCEIRO (
          <a
            href="https://marketplace.via.com.br/termos-e-condicoes/acordo-de-nivel-de-servico"
            target="_blank"
            rel="noopener noreferrer"
          >
            Acordo de Nível de Serviço
          </a>
          ) ; (vii) condições de ressarcimento e indenizações; (viii) Manual do Envvias e, (ix)
          condições específicas da contratação, quando aplicável.
        </p>
        <p>
          2.10. Após o cadastro, aprovação do PARCEIRO e ajuste das condições específicas na
          Plataforma, o PARCEIRO poderá iniciar todas as configurações necessárias para incluir o
          anúncio dos Itens que comercializará no Marketplace da VIA.
        </p>
      </div>
      <p className="font-bold underline">3. OBJETO</p>
      <div>
        <p>
          3.1. O objeto deste instrumento é estabelecer os termos e condições para (i) anúncio e
          comercialização dos Itens pelo PARCEIRO, por meio da Plataforma, no Marketplace da VIA; e,
          (ii) a prestação, pela VVLOG ao Parceiro, dos Serviços de Entrega dos Itens aos Clientes,
          quando aplicável.
        </p>
        <p>
          3.2. A comercialização dos Itens no Marketplace da VIA e os Serviços de Entrega se darão,
          exclusivamente, dentro dos limites do Território. Para os Serviços de Entrega, deverão ser
          respeitadas as restrições em alguns perímetros de entrega da VVLOG, conforme condições
          previstas neste Contrato.
        </p>
        <p>
          3.3. O Marketplace da VIA poderá ser operacionalizado em um ou mais Sites das bandeiras de
          suas marcas. A comercialização dos Itens do PARCEIRO será realizada no Site cadastrado na
          Plataforma pela VIA.
        </p>
        <p>
          3.4. Este instrumento não tem por objeto a distribuição ou representação comercial pela
          VIA de quaisquer Itens fornecidos pelo PARCEIRO, que se obriga, restritamente, a gerir e
          operar suas atividades no Marketplace conforme as condições aqui previstas.
        </p>
      </div>
      <p className="font-bold underline">4. CONDIÇÕES OPERACIONAIS DA PARCERIA</p>
      <div>
        <p className="font-bold">Comercialização de Itens no Marketplace</p>
        <p>
          4.1. A VIA se compromete a disponibilizar ao PARCEIRO durante a vigência do presente
          Contrato acesso à Plataforma, pela qual o PARCEIRO terá acesso às suas operações e
          informações relativas aos Pagamentos, Repasses e Retenções, Envvias, aos dados
          operacionais do Site, instruções, orientações e informações relativas às condições
          específicas e comerciais da Parceria.
        </p>
        <p>
          4.2. O PARCEIRO se obriga a cadastrar os Itens que serão comercializados no Marketplace
          observando as regras de divulgação e publicidades estabelecidas na Plataforma e na
          legislação vigente, sendo o único e exclusivo responsável, com relação às divergências
          e/ou falta de informações, devendo publicar informações completas, exatas e fidedignas com
          relação a todos os Itens que anunciar.
        </p>
        <p>
          4.3. A definição das condições comerciais a serem observadas na comercialização dos Itens
          é de exclusiva responsabilidade do PARCEIRO, sendo sugerido apresentar condições
          comerciais iguais ou melhores que aquelas existentes em seus próprios estabelecimentos
          e/ou sites.
        </p>
        <p>
          4.4. O PARCEIRO se obriga a expedir o pedido do Cliente (i) no prazo de até 02 (dois) dias
          úteis, o qual pode ser ajustado sempre que o Cliente tenha optado pela entrega normal do
          seu Pedido, devendo, para tanto, previamente comunicar tanto a VIA quanto o Cliente; (ii)
          no mesmo dia da efetivação do Pedido pelo Cliente, caso o PARCEIRO opte por realizar
          entregas na modalidade rápida; ou, (iii) na hipótese de entrega pelo Envvias, o PARCEIRO
          deverá observar o prazo de expedição previsto no Manual do Envvias; sempre devendo
          observar os prazos e condições de entrega conforme definições dos serviços ajustados para
          o Produto, bem como as condições estabelecidas na legislação vigente.
        </p>
        <p>
          4.5. O PARCEIRO se compromete a comercializar por meio do Marketplace apenas Produtos que
          já estejam em estoque, salvo em relação a determinados Produtos específicos cuja prática
          comercial determine a prévia encomenda junto aos respectivos fabricantes.
        </p>
        <p>
          4.5.1. Os Produtos que poderão ser objeto de encomenda junto ao fabricante serão
          anunciados com prazo de entrega compatível e estarão devidamente destacados na Plataforma
          e no anúncio no Marketplace.
        </p>
        <p>
          4.5.2. Caso o PARCEIRO não cumpra com o envio do Produto, objeto de encomenda junto ao
          fabricante, por falta em estoque, este deverá arcar com a remuneração devida à VIA e, se
          for o caso, à VVLOG, bem como, com todos os custos desembolsados e devidamente comprovados
          pela VIA/VVLOG.
        </p>
        <p>
          4.5.3. Caso a VIA constate que o PARCEIRO permaneceu sem estoque ou abandonou a sua
          operação deixando de interagir e/ou movimentar a plataforma pelo período superior a 30
          (trinta) dias, a VIA poderá, a seu exclusivo critério, considerar este Contrato rescindido
          de pleno direito, nos termos da Cláusula 10.4.
        </p>
        <p>
          4.6. Caberá à VIA processar todos os Pagamentos, Repasses, Retenções, Encargos, estornos e
          ajustes em razão das operações concretizadas no Marketplace ou, quando for o caso, no
          Envvias. A VIA poderá exercer tais atividades por meio de empresa terceira (instituição
          financeira ou de pagamento) regularmente contratada.
        </p>
        <p>
          4.6.1. A VIA realizará a cobrança dos Pagamentos na forma prevista no momento da
          concretização da operação pelo Cliente, não se obrigando a aceitar Formas de Pagamento
          diversas daquelas disponibilizadas e aceitas no momento da concretização da compra,
          podendo ainda se recusar a aceitar descontos, cupons, vales e/ou incentivos concedidos
          pelo PARCEIRO fora do Marketplace.
        </p>
        <p>
          4.7. A VIA realizará a coleta, por meio das ferramentas disponibilizadas no Marketplace,
          das informações necessárias para concretização e entrega, das operações realizadas pelos
          Clientes no Site.
        </p>
        <p className="font-bold">
          Envio de Produtos aos Clientes – Envvias ou diretamente pelo PARCEIRO
        </p>
        <p>
          4.8. O envio dos Produtos aos Clientes poderá ser realizado pelo Envvias, ou seja, pelos
          Serviços de Entrega da VVLOG ou por meios próprios diretamente contratados pelo PARCEIRO.
        </p>
        <p>
          4.9. A definição quanto à entrega dos Produtos ocorrerá de forma sistêmica, após a seleção
          dos Produtos pelo Cliente e preenchimento dos dados necessários, levando em consideração:
          (i) as dimensões da embalagem (largura, altura e profundidade); (ii) o peso do produto
          embalado; (iii) o Produto a ser entregue e sua categoria; (iv) local de entrega; e, (v)
          endereço do centro de distribuição cadastrado pelo PARCEIRO (origem da entrega).
        </p>
        <p>
          4.10. Preenchidos todos os requisitos, o Produto será entregue por meio dos Serviços de
          Entrega do Envvias, conforme termos e condições específicos disponíveis e detalhados no
          Apêndice I, parte integrante e inseparável, deste Contrato.
        </p>
        <p>
          4.11. Caso o pedido do Cliente, de acordo com os critérios previstos na Cláusula 4.9.
          acima, não se enquadre nos Serviços de Entrega, o PARCEIRO deverá se responsabilizar e
          providenciar a entrega do Produto ao Cliente, conforme termos e condições previstos neste
          Contrato e nas políticas de entrega constante no Site.
        </p>
        <p>
          4.12. Na hipótese acima, sem prejuízo das demais obrigações e condições previstas na
          legislação aplicável e neste Contrato, o PARCEIRO se compromete a:
        </p>
        <p>
          4.12.1. Fornecer para a VIA, por meio da Plataforma do Marketplace, as informações do
          despacho dos Produtos, bem como manter atualizado o status do pedido, a cada etapa, até a
          entrega ao Cliente, sendo elas: (i) nota fiscal emitida, (ii) produto enviado à
          transportadora, (iii) entrega realizada; assim como informar o(s) dados cadastrais da(s)
          transportadora(s) e disponibilizar o(s) link(s) e código(s) de rastreamento do(s)
          Produto(s) disponibilizado(s) pela transportadora responsável pela entrega, viabilizando a
          integração sistêmica para a possibilidade de extração de informações do rastreamento e
          entrega dos Itens.
        </p>
        <p>
          4.12.2. Arcar com os custos operacionais de envio para todo o estoque informado na
          Plataforma, como frete, entrega agendada e/ou re-tentativa de entrega, entre outros.
        </p>
        <p>
          4.12.3. Caso o Cliente recuse o recebimento do Produto por conta de qualquer não
          conformidade, informar a VIA de imediato, bem como tomar todas as providências para
          entregar ao Cliente outro Produto em perfeitas condições.
        </p>
        <p>
          4.12.4. Na hipótese de não ser possível entregar o Produto em razão de ausência do Cliente
          ou de alguém que possa receber o Produto em seu lugar, informar de imediato à VIA,
          devendo, ainda, realizar, no mínimo, 02 (duas) novas tentativas de contato e entrega ao
          Cliente, de acordo com a política constante do Site.
        </p>
        <p className="font-bold">Condições Comuns Aplicáveis à Parceria e ao Envvias</p>
        <p>
          4.13. O PARCEIRO obriga-se a comercializar os Itens observando a melhor técnica existente,
          sendo que, em relação à comercialização dos Produtos deverá observar as seguintes
          condições:
        </p>
        <p>
          a) Embalar e empacotar o Produto de maneira adequada e compatível para envio ao Cliente,
          de forma a manter a sua integridade durante o transporte.
        </p>
        <p>
          b) Abster-se de cancelar, injustificadamente, o pedido realizado pelo Cliente e, caso o
          cancelamento seja inevitável, por motivo de caso fortuito ou força maior, informar a VIA e
          ao Cliente, de imediato, sobre o cancelamento.
        </p>
        <p>
          c) Despachar e/ou entregar o Produto nas condições ofertadas e no prazo marcado quando da
          concretização da compra, respeitando, inclusive, o turno de entrega no caso de o Cliente
          optar por entrega agendada.
        </p>
        <p>d) Não encaminhar qualquer tipo de confirmação de expedição do pedido ao Cliente.</p>
        <p>
          e) Enviar via física da Nota Fiscal Eletrônica, devidamente emitida no CNPJ de
          titularidade do PARCEIRO cadastrado no Marketplace da VIA, junto com o Produto, garantindo
          o cumprimento das normas técnicas aplicáveis, que determinam as especificações para a
          emissão da nota fiscal eletrônica.
        </p>
        <p>
          f) O PARCEIRO deverá cumprir rigorosamente a Política de Trocas e Devoluções do Site, bem
          como todas as demais regras e políticas informadas ao Cliente no Site.
        </p>
        <p>
          4.14. Em caso de qualquer dúvida e/ou ausência de informações com relação aos prazos e
          condições operacionais que deverão ser praticados, o PARCEIRO se obriga a consultar a
          central de ajuda disponibilizada na Plataforma utilizada pela VIA para a gestão do
          Marketplace.
        </p>
        <p>
          4.15. É proibida ao PARCEIRO a inclusão de qualquer material publicitário, salvo
          previamente autorizado por escrito pela VIA, dentro ou fora das embalagens ou pacotes
          encaminhados aos Clientes.
        </p>
        <p>
          4.16. A Plataforma é o meio de comunicação e transmissão de informações pela VIA/VVLOG ao
          PARCEIRO, de forma que o PARCEIRO está obrigado a realizar o acesso e consultar a
          Plataforma diariamente, serão válidas, eficazes, bem como consideradas parte integrante
          deste Contrato, todas as orientações e determinações da VIA/VVLOG transmitidas pela
          Plataforma.
        </p>
        <p>
          4.16.1. O descumprimento de qualquer determinação dada pela VIA/VVLOG ao PARCEIRO por meio
          da Plataforma será considerado descumprimento contratual, para todos os fins e efeitos,
          sendo aplicáveis as penalidades previstas neste Contrato.
        </p>
        <p>
          4.17. O PARCEIRO se obriga a guardar e conservar de todo e qualquer documento relacionado
          às transações efetuadas pelo prazo de 05 (cinco) anos a contar da data da transação,
          devendo disponibilizar e encaminhar à VIA/VVLOG os documentos sempre que solicitado, no
          prazo de 02 (dois) dias ou em prazo inferior caso a solicitação tenha ocorrido em razão de
          determinação de órgãos fiscalizadores e/ou pelo poder administrativo ou judiciário.
        </p>
        <p>
          4.18. A VIA poderá, a seu critério, utilizar mecanismos de avaliação e auditoria de
          vendas, ranking de avaliações e performance do PARCEIRO.
        </p>
        <p>
          4.19. Os Clientes poderão, a seu exclusivo critério, avaliar a experiência de compra com o
          PARCEIRO no Site. Em nenhuma hipótese, a VIA/VVLOG poderá ser responsabilizada pela
          avaliação registrada pelo Cliente no Site ou em qualquer plataforma de avaliação e/ou
          reclamação disponível para os Clientes e consumidores em geral.
        </p>
      </div>
      <p className="font-bold underline">5. ITENS COMERCIALIZADOS PELO PARCEIRO</p>
      <div>
        <p>
          5.1. O PARCEIRO declara que seus Itens não violam nenhum dispositivo deste Contrato, e/ou
          direitos de terceiros, estando de acordo com a moral e os bons costumes, ofertando Itens
          que não sejam vedados pela legislação brasileira, inclusive Itens que não violem de
          qualquer modo direitos de propriedade intelectual (tais como marcas, patentes, desenhos
          industriais, modelos, logotipos etc.). Do mesmo modo, declara, ainda, que zela pela
          legalidade da oferta e da venda de seus Itens, estando ciente de que a VIA não assume
          nenhuma responsabilidade, inclusive perante terceiros, pela inclusão deles no Site, pelos
          anúncios publicados ou pelas negociações realizadas direta e indevidamente entre PARCEIRO
          e Clientes. Ocorrendo desatendimento de alguma dessas disposições a VIA poderá suspender a
          eficácia ou extinguir este Contrato, nos termos da Cláusula 10.5 das Condições do
          Marketplace, sem prejuízo da indenização pelas perdas e danos decorrentes.
        </p>
        <p>
          5.2. É vedada a comercialização de qualquer produto ilícito, de origem ilícita, que atente
          ou possa atentar contra a dignidade humana, os bons costumes, as práticas normais de
          comércio, o direito a livre concorrência, atentem ou possam atentar contra direitos de
          terceiros, sejam considerados falsos ou falsificados, não possuam as devidas autorizações,
          sejam defeituosos ou impróprios para consumo, violem as normas aplicáveis ou de qualquer
          maneira ou forma estejam impedidos de serem comercializados por lei, estatuto ou norma de
          ordem pública ou privada.
        </p>
        <p>
          5.3. Nos termos da Cláusula acima é expressamente vedada a comercialização, pelo PARCEIRO
          no Marketplace, dos Itens abaixo relacionados:
        </p>
        <p>
          a)Armas e correlatos, incluindo, armas de brinquedo que possam ser confundidas com armas
          verdadeiras;
        </p>
        <p>b)Fumígeros (cigarrilhas, charutos, cachimbos e demais produtos derivados do tabaco);</p>
        <p>c) Medicamentos;</p>
        <p>d) Bebidas alcoólicas aos menores de 18 (dezoito) anos;</p>
        <p>
          e) Produtos não homologados ou registrados pela ANAC, ANATEL, ANVISA e Ministério da
          Agricultura ou outros órgãos reguladores;
        </p>
        <p>f) Seres vivos;</p>
        <p>g) Serviços liberais;</p>
        <p>
          h) Fotografias, vídeos ou outros registros que contenham cena de sexo explícito ou
          pornografia;
        </p>
        <p>
          i) Produtos com origem no trabalho escravo, de crime, clandestinos e/ou importados de
          forma irregular ou fraudulenta.
        </p>
        <p>
          5.3.1. O rol de Itens da Cláusula acima é meramente exemplificativo, sendo que o PARCEIRO
          se responsabiliza integral e exclusivamente por obter e manter válida toda e qualquer
          autorização e/ou licenças necessárias para comercialização dos Itens no Marketplace, se
          responsabilizando, ainda, por informar em seus anúncios os números dos processos
          administrativos relativos à sua autorização para o exercício de suas atividades, bem como
          do registro dos Itens que dependam de autorização prévia por órgãos reguladores
          específicos, cumprindo integralmente com a legislação aplicável.
        </p>
        <p>
          5.3.2. O PARCEIRO se obriga e declara que está aderente e cumpre com todas as
          recomendações constantes no
          <span className="italic">
            ‘Guia Boas práticas e orientações às plataformas de comércio eletrônico para
            implementação de medidas de combate à venda de produtos piratas, contrabandeados ou, de
            qualquer modo, em violação à propriedade intelectual’
          </span>
          publicado pelo Conselho Nacional de Combate à Pirataria e Delitos de Propriedade
          Intelectual (“CNCP”).
        </p>
        <p>
          5.4. Caso o PARCEIRO venha a comercializar no Marketplace Itens importados,
          fabricados/disponibilizados por terceiros no exterior, o PARCEIRO será integralmente
          responsável pelos Itens perante os consumidores e os Órgãos Públicos competentes,
          isentando a VIA de qualquer providência ou responsabilidade a qualquer título.
        </p>
        <p>
          5.5. O PARCEIRO assume, também, a responsabilidade de comercializar no Marketplace somente
          Itens cujo fabricante, interno ou externo, o tenha licenciado o direito de uso dos
          direitos autorais, bem como o direito de sublicenciamento para fins publicitários e/ou o
          tenha autorizado a distribuir e comercializar no Território. O PARCEIRO concede à VIA a
          autorização para utilização dos materiais anunciados para fins publicitários na veiculação
          dos Itens no Marketplace.
        </p>
        <p>
          5.6. A VIA poderá, a qualquer tempo, solicitar uma auditoria sobre os documentos de compra
          relacionados às transações efetuadas entre o PARCEIRO e seus fornecedores e/ou parceiros,
          para apurar a procedência dos Itens, bem como para apuração do cumprimento das obrigações
          contratuais.
        </p>
        <p>
          5.7. A VIA poderá suspender a comercialização e o cadastro do PARCEIRO, nos termos da
          Cláusula 10.5, caso inicie uma auditoria e encontre alguma inconformidade quanto à
          veracidade de qualquer informação enviada pelo PARCEIRO ou qualquer indício de fraude em
          uma transação ou, ainda, em razão do descumprimento de uma ou mais condições deste
          Contrato.
        </p>
      </div>
      <p className="font-bold underline">6. DIREITOS E OBRIGAÇÕES DAS PARTES</p>
      <div>
        <p>
          6.1. Sem prejuízo das demais condições previstas neste Contrato e na legislação aplicável,
          são direitos e obrigações do PARCEIRO:
        </p>
        <p>
          a)Respeitar as cláusulas deste Contrato e de todos os documentos que venham a ser firmados
          entre as Partes, em razão da relação contratual ora estabelecida.
        </p>
        <p>
          b) Sendo solicitados quaisquer documentos pela VIA, o PARCEIRO se compromete a
          disponibilizá-los no prazo de 02 (dois) dias ou prazo inferior se assim indicado na
          respectiva solicitação.
        </p>
        <p>
          c) Manter seus dados cadastrais e de contato devidamente atualizados, bem como garantir a
          manutenção de sua regularidade perante todos e quaisquer órgãos, autoridades ou entidades
          a que esteja legalmente vinculado. Todos os documentos fiscais emitidos pelo PARCEIRO
          deverão estar de acordo com os dados cadastrados nos sistemas da VIA, Plataforma e no
          Formulário de Adesão.
        </p>
        <p>
          d) Cumprir ANS estabelecido pela VIA na Plataforma e/ou
          <a href="https://pas.viavarejo.com.br/" target="_blank" rel="noopener noreferrer">
            Plataforma
          </a>
          ;
        </p>
        <p>
          e) Utilizar a língua portuguesa de forma culta, polida e suficientemente clara nas
          comunicações escritas e orais realizadas entre o PARCEIRO e os Clientes de maneira a não
          causar confusão ou dificuldade ao Cliente.
        </p>
        <p>
          f) Concretizar as operações de comercialização realizadas no Marketplace, salvo as causas
          de completa impossibilidade decorrente de caso fortuito ou força maior.
        </p>
        <p>
          g) Não utilizar de trabalho escravo e/ou mão-de-obra infantil, salvo, nesse último caso,
          nas condições previstas em lei para contratação de menores aprendizes.
        </p>
        <p>
          h) Na contração de quaisquer colaboradores, fornecedores ou parceiros pelo PARCEIRO
          deverão ser observadas as normas vigentes, em especial as normas trabalhistas e
          previdenciárias.
        </p>
        <p>
          i) É vedado ao PARCEIRO direcionar o Cliente para quaisquer outros canais de comércio
          eletrônico e/ou físico, salvo nos termos e condições previstos neste Contrato.
        </p>
        <p>
          6.2. Caso o PARCEIRO deixe de cumprir qualquer item do ANS ajustado, nos termos do item
          (d) da Cláusula 6.1. acima o PARCEIRO deverá apresentar de imediato um plano de ação
          contemplando a implantação de medidas corretivas para esta ocorrência (“Plano de Ação”),
          no prazo de 2 (dois) dias a contar da data do recebimento o comunicado escrito da VIA,
          indicando o que foi descumprido pelo PARCEIRO.
        </p>
        <p>
          6.2.1. O PARCEIRO deverá implantar as medidas definidas no Plano de Ação e sanar o
          descumprimento do ANS. Havendo novo descumprimento do ANS, a VIA poderá aplicar suspensão,
          reter valore e/ou rescindir imediatamente o Contrato nos termos da Cláusula 10.4.
        </p>
        <p>
          6.2.2. O PARCEIRO declara que leu, está ciente e de pleno acordo, bem como que observará e
          cumprirá com todos os termos e condições deste Contrato, com as condições específicas e
          comerciais estabelecidas na Plataforma, nos Termos de Uso do Site, na Política de
          Privacidade e na Política de Trocas e Devoluções do Site.
        </p>
        <p>
          6.2.3. O PARCEIRO deverá adotar e cumprir todas as normas em vigor, incluindo, sem se
          limitar, relativamente à proteção dos direitos do Consumidor, o Código de Defesa do
          Consumidor, bem como as normas específicas aplicáveis à comercialização de cada Item e/ou
          regulatórias que determinem procedimentos fiscais e operacionais que se apliquem às
          atividades do PARCEIRO.
        </p>
        <p>
          6.3. Sem prejuízo das demais condições previstas neste Contrato e na legislação aplicável,
          são direitos e obrigações da VIA:
        </p>
        <p>
          a) Providenciar as ferramentas necessárias para disponibilização das Formas de Pagamento
          aos Clientes, respondendo pela contratação de quaisquer serviços ou mecanismos para
          controle e validação dos Pagamentos.
        </p>
        <p>
          b) Alterar, adequar ou remodelar do conteúdo, Layout, funcionalidades e/ou ferramentas
          relacionadas ao Site, à Plataforma e/ou ao Marketplace.
        </p>
        <p>
          c) Incluir, a seu exclusivo critério, os Itens do PARCEIRO em outros Sites relacionados ao
          Marketplace ou que tenham por objeto a divulgação do Site.
        </p>
        <p>
          d) Restringir, limitar ou impedir, a seu exclusivo critério, por qualquer meio ou forma o
          acesso de um ou mais Clientes ao Site.
        </p>
        <p>
          6.3.1. A VIA não está obrigada a aceitar Forma de Pagamento distinta das disponibilizadas
          e/ou escolhida pelo Cliente, não podendo o PARCEIRO restringir ou negar a opção do Cliente
          quanto à Forma de Pagamento.
        </p>
        <p>
          6.3.2. A VIA se reserva o direito de não anunciar, divulgar ou disponibilizar as
          ferramentas do Marketplace para um ou mais Itens ofertados pelo PARCEIRO.
        </p>
        <p>
          6.3.3. A VIA poderá, a qualquer tempo, com o intuito de minimizar riscos de falhas humanas
          e/ou uso indevido do Site, impor limites de venda para o PARCEIRO que incluirão, dentre
          outros aspectos, valores praticados, quantidades ofertadas e frequência de oferta dos
          Itens.
        </p>
        <p>
          6.3.4. A VIA, a seu exclusivo critério, poderá impor limites para que um mesmo Cliente
          adquira um volume muito grande de um mesmo Produto, sendo que a definição do número de
          unidades a serem vendidas, bem como as regras de limitação serão informadas ao PARCEIRO
          oportunamente, conforme demandado ao longo da execução do Contrato, estando o PARCEIRO de
          pleno acordo com tais condições se obrigando a colaborar com os termos ora ajustados.
        </p>
      </div>
      <p className="font-bold underline">7. RELACIONAMENTO COM O CLIENTE</p>
      <div>
        <p>
          7.1. O PARCEIRO somente terá contato direto com o Cliente via ferramenta de atendimento da
          Plataforma, ou mediante integração previamente autorizada, pela VIA, da ferramenta de
          atendimento do PARCEIRO.
        </p>
        <p>
          7.2. É expressamente proibido o envio de informações de contato direto do PARCEIRO
          (e-mail, telefone, entre outros) aos Clientes.
        </p>
        <p>
          7.2.1. Todo o contato, entre o PARCEIRO, VIA e Cliente, será monitorado e auditado pela
          VIA, devendo ser sempre registrado e cadastrado pelo PARCEIRO na Plataforma, conforme
          orientação da VIA.
        </p>
        <p>
          7.2.2. O PARCEIRO se compromete a dar o tratamento adequado e eficiente a todos os
          contatos feitos pelos Clientes via Plataforma, devendo solucionar todas e quaisquer
          reclamações em 48 (quarenta e oito) horas, conforme Acordo de Nível de Serviços, e em no
          máximo, 02 (duas) interações com o Cliente.
        </p>
        <p>
          7.3. É vedada ao PARCEIRO a solicitação de quaisquer dados cadastrais, financeiros e/ou
          bancários junto aos Clientes.
        </p>
        <p>
          7.4. Ocorrendo reclamações de Clientes que demandem trocas de produtos com vícios de
          fabricação, nos termos dos artigos 18 e 26 do Código de Defesa do Consumidor, caberá ao
          PARCEIRO a retirada tais Produtos, bem como realizar a substituição do produto viciado, se
          responsabilizando ainda pelos pagamentos dos fretes, sem impor nenhum ônus para o Cliente.
        </p>
        <p>
          7.5. Caso o Cliente exerça seu direito de arrependimento, previsto no artigo 49 da Lei
          8.078/90, o PARCEIRO deverá tomar as providências para coletar o produto,
          independentemente de apresentação de qualquer evidência e/ou justificativa pelo Cliente,
          obrigando-se a reembolsar os valores efetivamente pagos pelos Clientes, a qualquer título,
          inclusive as despesas com impostos e frete. Nesta hipótese, caso a VIA seja acionada
          diretamente pelo Cliente para o exercício do direito de arrependimento, a VIA processará
          imediatamente o cancelamento do pedido, sendo de responsabilidade do PARCEIRO providenciar
          a coleta do produto.
        </p>
        <p>
          7.5.1. Quando se tratar de produto comprovadamente essencial (fogão, geladeira, máquina de
          lavar, aparelhos celulares, televisão, entre outros), o PARCEIRO realizará imediatamente a
          troca, sendo que, se por algum motivo o PARCEIRO não efetuar a troca, a VIA a processará,
          repassando todo e qualquer custo ou despesas ao PARCEIRO por meio de Retenção.
        </p>
        <p>
          7.6. A VIA não é proprietária dos Itens oferecidos pelo PARCEIRO, não realiza oferta de
          vendas na relação entre PARCEIRO e Cliente. Desse modo, não intervém na negociação ou na
          entrega de tais Itens, exceto, pela VVLOG, quando a entrega do Produto for realizada pelo
          Envvias.
        </p>
        <p>
          7.7. A VIA poderá, conforme seu exclusivo critério, intervir nas demandas ou reclamações
          formuladas pelos Clientes, em especial e sem se limitar, àquelas demandas que não tenham
          sido respondidas pelo PARCEIRO e/ou em que o Cliente tenha solicitado intervenção da VIA,
          de maneira a solucionar problemas resultantes da relação destes Clientes com o PARCEIRO
          visando a preservação de sua própria imagem, do Site ou do Marketplace, devendo o
          PARCEIRO, arcar com todos os ônus decorrentes. Não obstante, o PARCEIRO reconhece que
          eventual intervenção da VIA se dará por liberalidade, uma vez que o PARCEIRO é o único
          responsável por seus Itens.
        </p>
        <p>
          7.8. Em caso de descumprimento, pelo PARCEIRO, das condições pactuadas com o Cliente,
          incluindo questões relativas às condições e prazos de entrega, o PARCEIRO se compromete a
          realizar o reembolso dos valores pagos pelo Cliente, a qualquer título, incluindo as
          despesas de frete, sem nenhum ônus para o Cliente, sem prejuízo de arcar com quaisquer
          sanções administrativas e indenizações que sejam imputadas à VIA em razão da falha do
          PARCEIRO.
        </p>
        <p>
          7.9. A VIA poderá transferir pedidos de Clientes a outros parceiros, caso o PARCEIRO, não
          atenda aos requisitos do pedido, como estoque, preço e demais condições comerciais, sendo
          que o PARCEIRO ficará responsável pelo pagamento da Remuneração à VIA, bem como, sujeito
          ao pagamento das despesas incorridas pela VIA, inclusive a diferença do valor total do
          pedido (Produto + Frete) que venha a ocorrer em decorrência da precificação diferenciada
          do outro parceiro.
        </p>
      </div>
      <p className="font-bold underline">8. RELACIONAMENTO E RESPONSABILIDADE DAS PARTES</p>
      <div>
        <p>
          8.1. Este Contrato não estabelece quaisquer responsabilidades trabalhistas e/ou
          previdenciárias entre os gerentes, empregados, trabalhadores, prepostos e consultores do
          PARCEIRO (e quaisquer terceiros contratados por ele) e a VIA. Sendo que o PARCEIRO será
          responsável pelo cumprimento de todas as obrigações trabalhistas, responsabilizando-se por
          quaisquer custos e despesas resultantes ou relacionadas aos seus empregados que venham a
          ser envolvidos, direta ou indiretamente, na execução da Parceria, inclusive os custos e
          responsabilidades estabelecidos pela legislação trabalhista, ou relacionados com a
          Previdência e Seguridade Social, ou a qualquer lei em vigor, ressarcindo e mantendo a VIA
          nos termos desta Cláusula, sob pena de caracterização de inadimplemento das Condições do
          Marketplace.
        </p>
        <p>
          8.2. A Parceria não pressupõe ou gera qualquer relação de consumo, nos termos do Código de
          Defesa do Consumidor, entre a VIA e o PARCEIRO, servindo as ferramentas ora contratadas
          como instrumentos para o exercício, pelo PARCEIRO, de suas atividades empresariais.
        </p>
        <p>
          8.3. Exceto com relação às atividades de Gestão de Pagamentos, a VIA não participa das
          transações que se realizam entre o PARCEIRO e Clientes do Site, sendo que a
          responsabilidade por obrigações fiscais, trabalhistas, de consumo ou de qualquer outra
          natureza, decorrente das transações originadas através do Site é exclusiva do PARCEIRO.
        </p>
        <p className="font-bold">Responsabilidade das Partes</p>
        <p>
          8.4. O PARCEIRO deverá executar a Parceria, cumprindo todas as obrigações impostas pela
          legislação, responsabilizando-se pelas consequências decorrentes de qualquer
          descumprimento das Condições do Marketplace, Manual do Envvias, das condições ajustadas na
          Plataforma, nos Termos de Uso do Site, nas Políticas da VIA e/ou da legislação aplicável.
        </p>
        <p>
          8.5. O PARCEIRO responderá pelo uso indevido de qualquer funcionalidade do Site e/ou do
          Marketplace, sendo responsável pelo uso da Plataforma provida pela VIA, observando a
          legislação brasileira em vigor.
        </p>
        <p>
          8.6. O PARCEIRO se responsabilizará por todas e quaisquer perdas e/ou prejuízos de
          qualquer natureza causado à VIA, no âmbito administrativo ou judicial, de caráter cível
          (particulares ou de órgãos públicos), trabalhista, propriedade intelectual, criminal ou
          tributário, resultantes de quaisquer descumprimentos nos termos das Cláusulas anteriores
          e/ou de quaisquer reclamações de terceiros, nos termos da Cláusula 8.7.3 a seguir
          (“Demanda”).
        </p>
        <p>
          8.7. Recebidas quaisquer Demandas, a VIA adotará o fluxo de demandas previsto na Cláusulas
          seguintes:
        </p>
        <p>
          8.7.1. O PARCEIRO se obriga a assumir quaisquer Demandas, decorrentes de ato ou omissão de
          responsabilidade do PARCEIRO relacionados à execução da Parceria, que sejam formuladas
          contra a VIA, na esfera judicial, administrativa ou por meio de reclamação extrajudicial
          de terceiro, espontaneamente comparecendo aos autos para requerer seu ingresso e a
          exclusão da VIA do processo ou, no caso de reclamação extrajudicial, respondendo ao
          terceiro acerca da total isenção de responsabilidade da VIA.
        </p>
        <p>
          8.7.2. Caso a VIA permaneça nos autos, elegerá seus advogados para sua defesa, sendo que
          todos os documentos e informações solicitados para subsídios e condução processual deverão
          ser disponibilizados pelo PARCEIRO no prazo máximo de 02 (dois) dias a contar da
          solicitação realizada pela Plataforma e/ou por e-mail pela VIA, todas as despesas
          pertinentes serão de responsabilidade do PARCEIRO.
        </p>
        <p>
          8.7.3. Fica certo e ajustado entre as Partes que os Encargos despendidos pela Parte que,
          nos termos desta Cláusula, for demandada indevidamente (“Parte Indenizada”), em casos em
          que se verifique a responsabilidade da outra Parte (“Parte Indenizadora”), a Parte
          Indenizadora ressarcirá integralmente a Parte Indenizada todos e quaisquer valores por ela
          despendidos em razão de qualquer Demanda (incluindo, sem limitação, condenação, multa,
          penalidade, custas e despesas processuais, honorários de sucumbência, honorários
          advocatícios etc.).
        </p>
        <p>
          8.7.4. As Partes concordam que, na hipótese prevista na Cláusula 8.6 acima, a VIA, no mês
          que receber a Demanda, poderá, a seu exclusivo critério, reter do REPASSE do PARCEIRO, o
          valor correspondente ao valor médio dos Encargos relativos às Demandas semelhantes,
          conforme apurações da VIA, cujo valor será retido em parcela única, a título de garantia
          dos pagamentos dos Encargos (“Valores Retidos”).
        </p>
        <p>
          8.7.5. Após o término definitivo da Demanda, a VIA irá verificar se os Valores Retidos são
          suficientes para quitação das Despesas Judiciais. Caso os Valores Retidos ultrapassem as
          Despesas Judiciais, a VIA deverá pagar, na data do próximo REPASSE do PARCEIRO, o valor
          correspondente à diferença entre os Valores Retidos e as Despesas Judiciais. Caso os
          Valores Retidos não sejam suficientes para quitação integral das Despesas Judiciais, a VIA
          irá reter do REPASSE do PARCEIRO, o valor correspondente à diferença entre os Encargos e
          os Valores Retidos.
        </p>
        <p>
          8.8. O acompanhamento, pelo PARCEIRO, de todas as Demandas, Encargos devidos e Valores
          Retidos deverá ser realizado e registrado diretamente na Plataforma.
        </p>
        <p className="font-bold">Responsabilidade por Fraude</p>
        <p>
          8.9. O PARCEIRO responderá por qualquer fraude realizada por si ou por terceiros a ele
          vinculados, em meio virtual ou físico, de ações ou omissões culposas ou dolosas, ou ainda,
          decorrentes da violação de quaisquer condições estabelecidas neste Contrato.
        </p>
        <p>
          8.10. A VIA poderá reter ou não concretizar operações caso, a seu critério, verifique
          evidências e/ou a possibilidade de estarem sendo cometidas fraudes, ou outros crimes, em
          meio virtual ou físico, relacionados aos Clientes ou ao PARCEIRO, devendo comunicar o
          PARCEIRO sobre tal ato ilícito.
        </p>
        <p>
          8.11. A VIA envidará seus maiores esforços ao combate de fraudes de pagamentos e outros
          crimes referentes as transações de pagamento, providenciando a instalação e utilização de
          ferramentas direcionadas ao combate dos crimes quando da utilização do Site e demais
          ferramentas do Marketplace.
        </p>
        <p>
          8.12. A VIA se compromete a realizar o Repasse ao PARCEIRO de vendas que tenham sido
          canceladas em razão de fraude no pagamento e resultem em
          <span className="italic">chargeback</span> (devolução/estorno dos valores das transações
          cujo pagamento ocorreu cartão de crédito em razão de alegado desconhecimento da transação
          transação pelo Cliente), desde que o PARCEIRO comprove a entrega do Produto e/ou a
          concretização da prestação dos Serviços e não tenha sido cientificado do ocorrido antes da
          entrega dos Itens.
        </p>
        <p className="font-bold">Limitações de Responsabilidade</p>
        <p>
          8.13. A VIA não será responsável pelo uso indevido de seu Site e/ou Plataforma, bem como
          por quaisquer perdas e danos sofridos pelo Cliente, PARCEIRO ou terceiro em decorrência de
          culpa e/ou dolo do PARCEIRO, concordando o PARCEIRO em manter a VIA livre de qualquer ônus
          ou responsabilidade decorrente de demandas relacionadas a tal uso indevido, nos termos das
          Cláusulas acima.
        </p>
        <p>
          8.14. A VIA não garante o funcionamento ininterrupto, livre de erros e inviolável do
          Marketplace, do Site ou da Plataforma, bem como não se responsabiliza por quaisquer
          problemas de acesso, instabilidades na conexão e/ou oriundos de problemas de
          telecomunicações. Em caso de indisponibilidade dos sistemas da VIA, o PARCEIRO deverá
          enviar um comunicado ao canal de comunicação da VIA para análise e, se for o caso,
          providências para resolução do problema.
        </p>
        <p>
          8.15. A VIA não será responsável, em hipótese nenhuma, por
          <span className="italic">chargeback</span> decorrente de fraude de transportadoras,
          autofraude e/ou fraudes no pós-venda, bem como pelos
          <span className="italic">chargebacks</span>
          gerados em razão de desacordo comercial, hipóteses em que o PARCEIRO assumirá a
          responsabilidade e a VIA poderá realizar a Retenção dos valores dessas transações.
        </p>
        <p>
          8.16. Nenhuma das Partes responde pelos insucessos comerciais da outra Parte e/ou por
          lucros cessantes, exceto nos casos em que for constatado dolo.
        </p>
        <p>
          8.17. Todos os pagamentos dos Encargos, pelo PARCEIRO à VIA, decorrentes das previsões
          constantes neste Capítulo 8 ou no Apêndice I, serão realizados nos termos da Cláusula 9.9.
          abaixo.
        </p>
        <p>
          8.18. Todas as obrigações previstas neste Capítulo 8 e no Apêndice I continuarão vigentes
          mesmo após o encerramento da Parceria, por qualquer motivo.
        </p>
      </div>
      <p className="font-bold underline">9. REPASSE, REMUNERAÇÃO E CONDIÇÕES DE PAGAMENTO</p>
      <div>
        <p>
          9.1. A Remuneração VIA será resultante do cálculo dos percentuais fixados entre as Partes
          conforme tabela de Remuneração VIA incluída e cadastrada para PARCEIRO, pela VIA, na
          Plataforma multiplicado pelo valor do Item adquirido somado o valor do frete pago pelo
          Cliente.
        </p>
        <p>
          9.1.1. As Partes concordam que futuras alterações na tabela de Remuneração VIA ou a
          cobrança de novos Encargos pela VIA do PARCEIRO, poderão ser acordados entre as Partes,
          através da Plataforma.
        </p>
        <p>
          9.1.2. Caso ocorra a venda de Itens, que não estejam pré-estabelecidos na tabela de
          Remuneração VIA e as Partes não acordem novos percentuais de Remuneração VIA, através da
          Plataforma, fica desde já estabelecida alíquota de Remuneração VIA no percentual padrão de
          20% (vinte por cento).
        </p>
        <p>
          9.2. Quando aplicável o Envvias, a Remuneração VVLOG será o preço do frete disponibilizado
          ao Cliente no momento da aquisição do Produto e/ou Serviço, junto ao Parceiro, no
          Marketplace.
        </p>
        <p>
          9.2.1. O PARCEIRO poderá subsidiar, total ou parcialmente, o valor do frete como benefício
          ao Cliente. Nesta hipótese, o PARCEIRO será informado sobre o valor do frete do respectivo
          Pedido subsidiado e o PARCEIRO será responsável pelo pagamento do valor total da
          Remuneração VVLOG à VVLOG que realizará a Retenção.
        </p>
        <p>
          9.2.2. A VVLOG emitirá o Conhecimento de Transporte Eletrônico (CT-e) em face do PARCEIRO,
          de forma que o PARCEIRO se responsabiliza pelo cumprimento das obrigações fiscais
          relacionadas aos Pagamentos e envio dos documentos regulares e legítimos aos Clientes.
        </p>
        <p>
          9.3. O PARCEIRO concorda que a Remuneração VIA e/ou VVLOG será(ão) devida(s) e os Repasses
          serão calculados com base nas operações concretizadas, descontados a Remuneração VIA, a
          Remuneração VVLOG (quando aplicável) e Encargos incidentes, sendo efetivamente creditados
          na conta corrente do PARCEIRO após a comprovação da entrega dos Itens ao Cliente.
        </p>
        <p>
          9.4. Na hipótese de cancelamento do pedido por iniciativa do Cliente, a Remuneração VIA
          poderá, a critério da VIA, ser devolvida ao PARCEIRO ou abatida no próximo Repasse ao
          PARCEIRO.
        </p>
        <p>
          9.5. Sem prejuízo das previsões constantes no Apêndice I, com relação aos Serviços de
          Entrega, caso o Cliente solicite a devolução e/ou cancelamento na hipótese prevista na
          Cláusula 7.5, ou por motivo de avaria no produto, atraso na entrega ou por receber o
          Produto divergente, por qualquer outra falha do PARCEIRO, este ficará obrigado ainda ao
          pagamento da Remuneração à VIA e demais Encargos incidentes.
        </p>
        <p>
          9.6. Em caso de dúvidas com relação ao Repasse e à Remuneração VIA ajustadas na
          Plataforma, o PARCEIRO poderá acessar o manual do lojista, disponível no{' '}
          <a href="https://pas.viavarejo.com.br/" target="_blank" rel="noopener noreferrer">
            https://pas.viavarejo.com.br/
          </a>
          ;
        </p>
        <p className="font-bold">Condições de Pagamento</p>
        <p>
          9.7. A VIA se compromete a, nos termos previsto nas regras de Repasse estabelecidas entre
          as Partes e constantes na Plataforma, realizar a transferência dos valores devidos ao
          PARCEIRO mediante crédito em conta corrente indicada no Formulário de Adesão de
          titularidade do PARCEIRO.
        </p>
        <p>
          9.7.1. A conta corrente informada pelo PARCEIRO, nos termos da Cláusula acima, deverá ser
          de titularidade e cadastrada no mesmo CNPJ indicado na Plataforma, bem como deverá ser
          domiciliada em Banco aderente à regulamentação do Banco Central do Brasil relacionada à
          liquidação centralizada pela Câmara Interbancária de Pagamentos (“CIP”), Sistema CERC,
          Sistema de Registro da TAG IMF e ou outras que vierem a substituí-las no Sistema de
          Pagamento Brasileiro (“SPB”).
        </p>
        <p>
          9.8. O PARCEIRO se compromete a manter atualizadas suas informações bancárias para
          concretização do Repasse. Em caso de solicitação, pelo PARCEIRO, de alteração dos dados
          bancários cadastrados nos sistemas da VIA, o PARCEIRO, desde já, fica ciente que a
          atualização sistêmica será processada no prazo de até 30 (trinta) dias, e concorda que,
          neste período, os Repasses continuarão sendo realizados de acordo com as informações
          bancárias anteriores à alteração comunicada, sem que VIA incorra em inadimplência.
        </p>
        <p>
          9.9. As Partes ajustam que o PARCEIRO deverá realizar todo e qualquer pagamento de valores
          devidos à VIA/VVLOG, seja em razão da Remuneração VIA e/ou VVLOG, seja em razão de
          ressarcimentos e indenizações decorrentes de danos e/ou prejuízos causados à VIA /VVLOG,
          previstos no Capítulo 8 e/ou no Apêndice I das Condições do Marketplace, ou, ainda,
          qualquer valor devido por qualquer razão à VIA/VVLOG, nas condições e nos prazos previstos
          neste Contrato.
        </p>
        <p>
          9.9.1. Para aquelas obrigações de pagamento que não possuam condições de pagamento
          expressas nas Condições do Marketplace e/ou não havendo possibilidade de Retenção do valor
          pela VIA/VVLOG, o PARCEIRO se obriga a realizar o pagamento no prazo de 10 (dez) dias
          contados da data de recebimento da solicitação e do boleto para pagamento e/ou da
          informação dos dados bancários para efetuar o respectivo crédito, ainda que este Contrato
          tenha sido encerrado por qualquer motivo.
        </p>
        <p>
          9.9.2. O atraso do pagamento, nos termos da cláusula acima, sujeitará o PARCEIRO ao
          pagamento de multa no valor correspondente a 2% (dois por cento) ao mês, mais juros de
          mora no importe de 1% (um por cento) ao mês, calculado desde a data do vencimento até a
          data do efetivo pagamento.
        </p>
        <p>
          9.9.3. Em razão do atraso do pagamento, a VIA/VVLOG poderá(ão), a seu exclusivo critério,
          inscrever a denominação social e o número de CNPJ do PARCEIRO nos órgãos de proteção ao
          crédito (SPC, SERASA e outros).
        </p>
      </div>
      <p className="font-bold underline">10. PRAZO, ENCERRAMENTO E SUSPENSÃO CONTRATO</p>
      <div>
        <p>
          10.1. O presente Contrato vigorará por prazo indeterminado e terá o início de sua vigência
          na data de aceitação, pela VIA, da Adesão promovida pelo PARCEIRO.
        </p>
        <p>
          10.2. Qualquer uma das Partes poderá resilir este Contrato imotivadamente, sem ônus,
          mediante notificação escrita com antecedência mínima de 5 (cinco) dias, devendo cumprir
          com as obrigações pertinentes para a concretização dos pedidos já realizados e realização
          dos Repasses devidos ao PARCEIRO, observado o pagamento das Remunerações e pendências
          devidas à VIA e à VVLOG.
        </p>
        <p>
          10.3. Será considerado resolvido de maneira amigável o presente Contrato caso o PARCEIRO
          não concorde com alterações realizadas nos termos da Cláusula 17.2., desde que observado o
          prazo de manifestação previsto na Cláusula 17.2.1..
        </p>
        <p>
          10.4. A Parceria poderá ser rescindida pela VIA, mediante envio de comunicado por escrito
          com efeitos imediatos, nas hipóteses abaixo:
        </p>
        <p>a) Pedido de decretação de falência ou recuperação judicial pelo PARCEIRO;</p>
        <p>
          b) Em caso de descumprimento, pelo PARCEIRO, de quaisquer condições relacionadas à
          Parceria, incluindo, sem se limitar nas hipóteses previstas nas Cláusulas 2.9.1; 4.5.3, 5,
          6.1, 6.2.1, 8.4, 14.5; e,
        </p>
        <p>c) Demais hipóteses previstas em Lei.</p>
        <p>
          10.5. Em qualquer uma das hipóteses de descumprimento contratual pelo PARCEIRO e/ou nas
          hipóteses previstas na Cláusula 10.4 acima, a VIA poderá, a seu exclusivo critério, antes
          de considerar a Parceria rescindida, suspender de imediato a comercialização dos Itens do
          PARCEIRO no Marketplace, realizando, se aplicável, a Retenção dos Repasses. Após adotar
          estes procedimentos, a VIA enviará notificação ao PARCEIRO, via Plataforma, para que no
          prazo de 2 (dois) dias providencie o saneamento ou justificativa para as irregularidades
          constatadas.
        </p>
        <p>
          10.5.1. Não sendo sanada a irregularidade ou apresentada a respectiva justificativa no
          prazo assinalado, poderá a VIA considerar o Contrato plenamente rescindido.
        </p>
        <p>
          10.6. Qualquer sucessão ou alteração no quadro societário ou na forma societária das
          Partes contratantes não afetará a manutenção da relação estabelecida no presente contrato,
          não fundamentando, portanto, motivo para término.
        </p>
        <p>
          10.7. Em caso de término antecipado da Parceria, por qualquer razão, o PARCEIRO estará
          sujeito aos efeitos abaixo previstos:
        </p>
        <p>a) Imediata retirada de todos os Itens do Site;</p>
        <p>b) Encerramento de seu cadastro;</p>
        <p>
          c) Responsabilizar-se por todos os pedidos já concretizados devendo cumprir com as
          Condições do Marketplace até entrega de todos os Itens;
        </p>
        <p>d) Vencimento antecipado de todas as obrigações previstas neste Contrato.</p>
        <p>
          10.8. Para todos os pagamentos devidos pelo PARCEIRO à VIA em razão do término da
          Parceria, aplicar-se-ão as previsões da Cláusula 9.9 e seguintes das Condições de
          Marketplace.
        </p>
        <p>
          10.9. Não obstante o prazo de vigência e execução da Parceria, as obrigações pendentes,
          cláusulas expressamente acordadas por prazo indeterminado e cláusulas que por sua natureza
          tiverem caráter perene permanecerão válidas, mesmo após o seu término.
        </p>
      </div>
      <p className="font-bold underline">11. CONFIDENCIALIDADE</p>
      <div>
        <p>
          11.1. As Partes se comprometem, mutuamente, a zelar pela manutenção do sigilo de todos os
          segredos comerciais, conhecimentos técnicos e outras informações que venham a tomar
          conhecimento uma da outra, não podendo usar quaisquer dessas informações confidenciais, a
          não ser quando autorizadas previamente e por escrito para tanto por seu titular. Nesse
          sentido, cada parte deverá envidar seus melhores esforços a fim de que seus sócios,
          empresas afiliadas, administradores, prepostos, empregados e/ou quaisquer outras pessoas
          sob sua responsabilidade (direta ou indireta) mantenham em sigilo todos os termos e
          condições do presente instrumento contratual.
        </p>
        <p>
          11.2. As Partes concordam que descrições de produtos, preços, condições de pagamento,
          formas de entrega e outras informações relacionadas aos produtos não são informações
          confidenciais.
        </p>
        <p>
          11.3. Ademais, as Partes concordam que as condições comerciais e específicas relacionadas
          a este Contrato, previstas na Plataforma, são estritamente confidenciais e não serão
          divulgadas a quaisquer indivíduos, sociedades ou instituições, exceto se: (i) a informação
          se tornar de domínio público; ou, (ii) a divulgação for exigida por autoridade
          governamental ou por ordem de Tribunal competente, sob pena de ser caracterizada
          desobediência ou outra penalidade. Nestas hipóteses, o material a ser revelado deverá ser
          objeto de toda a proteção governamental ou judicial possível, devendo a Parte que estiver
          obrigada a revelar tais informações, prontamente notificar a outra para que a mesma possa
          adotar as medidas cabíveis junto à autoridade ou Tribunal para proteção das informações.
        </p>
        <p>
          11.4. As Partes se comprometem e se obrigam a tomar todas as medidas necessárias à
          proteção das informações confidenciais da outra parte, evitando e prevenindo revelação a
          terceiros, exceto se devidamente autorizadas por escrito pela própria parte. As
          informações confidenciais, contudo, poderão ser reveladas aos funcionários que precisem
          conhecê-las para os fins deste contrato, sendo certo que tais funcionários serão
          devidamente avisados acerca da natureza confidencial das mencionadas informações,
          especialmente quanto às suas cláusulas de sigilo, comprometendo-se por escrito e
          pessoalmente à manutenção da confidencialidade das informações nos termos deste contrato.
        </p>
        <p>
          11.5. O PARCEIRO deverá, quando da guarda, armazenamento e tratamento de dados pessoais
          fornecidos pela VIA, observar as diretrizes sobre padrões de segurança dispostos no
          Decreto nº 8.771/2016 e em todas as legislações que tratam de proteção de dados,
          incluindo, sem se limitar a Lei 13.709/18, bem como o disposto nas cláusulas seguintes.
        </p>
        <p>
          11.5.1. Em caso de incidente de vazamento de dados pessoais e/ou confidenciais, o PARCEIRO
          deverá enviar comunicação à VIA por escrito, certificando-se do recebimento, no prazo
          máximo de 24 (vinte e quatro) horas contado a partir da ciência do vazamento, contendo, no
          mínimo, as seguintes informações: (i) data e hora do incidente; (ii) data e hora da
          ciência pelo PARCEIRO; (iii) relação dos tipos de dados afetados pelo incidente; (iv)
          relação de titulares afetados pelo vazamento; e (v) indicação de medidas que estiverem
          sendo tomadas para reparar o dano e evitar novos incidentes.
        </p>
        <p>
          11.5.2. Caso seja identificada a necessidade de contratação de empresa independente
          especializada em perícia técnica para efetuar a apuração do incidente de vazamento de
          dados pessoais, todos os custos serão integralmente arcados pelo PARCEIRO.
        </p>
        <p>
          11.5.3. O PARCEIRO deverá permitir, colaborar e dar suporte à execução de auditoria
          técnica comissionadas pela VIA, com escopo definido para averiguação de padrões de
          segurança da informação, adequação a legislações vigentes e identificação de eventuais
          vulnerabilidades dos sistemas (“Auditoria”), dando todo o acesso necessário para execução
          de tal trabalho.
        </p>
        <p>
          11.5.4. Ao PARCEIRO será garantido acesso a todos os resultados de eventual Auditoria, com
          o objetivo de criação de plano de ação e mitigação de riscos por parte do PARCEIRO (“Plano
          de Ação”).
        </p>
        <p>
          11.5.5. O Plano de Ação deverá ser enviado à VIA no prazo máximo de 5 (cinco) dias,
          contados a partir do recebimento pelo PARCEIRO do relatório preliminar da Auditoria. Devem
          constar no Plano de Ação a ação concreta proposta pelo PARCEIRO, com data de início e
          término da ação, bem como outras informações que a VIA julgar necessárias para justificar
          a ação como adequada para resolução dos riscos identificados.
        </p>
        <p>
          11.5.6. Cabe à VIA avaliar e julgar a adequação do Plano de Ação proposto frente os
          eventuais riscos expostos no relatório da Auditoria, retornando ao PARCEIRO o seu parecer
          em relação ao Plano de Ação no prazo máximo de 10 (dez) dias úteis.
        </p>
        <p>
          11.6. Havendo o término do Contrato, o PARCEIRO se obriga a efetuar, se aplicável, o
          expurgo das informações confidenciais e dados pessoais de forma segura.
        </p>
        <p>
          11.7. As obrigações deste Capítulo subsistirão pelo período de 05 (cinco) anos contados da
          data do término ou rescisão da Parceria, ficando ainda ajustado que em caso de violação
          destas obrigações por qualquer uma das Partes, por si ou por seus empregados, prepostos ou
          representantes, a parte prejudicada estará legitimada a reclamar e exigir da outra Parte,
          na forma da legislação vigente, a correspondente indenização por todos os danos e
          prejuízos ocasionados.
        </p>
      </div>
      <p className="font-bold underline">12. MARCAS E DIREITOS DE PROPRIEDADE INDUSTRIAL</p>
      <div>
        <p>
          12.1. <span className="font-bold">Licença de Uso de Marca Registrada</span>: A VIA/VVLOG
          estará, sem prejuízo de outras disposições contidas nas Condições do Marketplace,
          autorizada a utilizar a título gratuito e sem qualquer tipo de contraprestação os nomes
          comerciais e marcas registradas do PARCEIRO nos Site e demais ferramentas do Marketplace,
          inclusive em sites e páginas direcionados a publicidade e divulgação dos Itens.
        </p>
        <p>
          12.2. O PARCEIRO somente poderá usar os nomes comerciais e marcas registradas da VIA/VVLOG
          mediante prévia e expressa autorização por escrito.
        </p>
        <p>
          12.3. <span className="font-bold">Direitos</span>: Cada Parte reconhece que a utilização
          que venha a fazer das Marcas da outra Parte não criará para si, nem representará que esta
          tenha qualquer direito, titularidade ou participação sobre essas Marcas, ou com relação a
          elas, exceto as licenças expressamente concedidas neste instrumento. Cada Parte concorda
          em não tomar qualquer medida que venha a contestar ou prejudicar os direitos de marca
          registrada da outra Parte.
        </p>
        <p>
          12.4.{' '}
          <span className="font-bold">
            Processo Judicial por Violação de Direitos sobre Marcas ou sobre Propriedade Intelectual
          </span>
          . Cada Parte concorda em notificar prontamente a outra Parte, por meio da Plataforma,
          sobre qualquer uso não autorizado das Marcas ou sobre Propriedade Intelectual da outra
          Parte do qual tenha conhecimento efetivo. Caberá exclusivamente a cada Parte o direito e
          opção de iniciar processo judicial por violação de direitos sobre as Marcas ou sobre
          Propriedade Intelectual dessa Parte ou por concorrência desleal em relação a essas Marcas;
          observado, entretanto, que cada Parte concorda em prestar à outra Parte cooperação e
          assistência cabíveis com relação a quaisquer processos judiciais por violação de direitos
          sobre Marcas e sobre Propriedade Intelectual.
        </p>
        <p>
          12.5. <span className="font-bold">Propriedade intelectual</span>: O PARCEIRO reconhece que
          toda a propriedade intelectual no que tange o desenvolvimento técnico para inclusão do
          PARCEIRO no Marketplace, incluindo todas as ferramentas ali disponibilizadas ou que venham
          a ser criadas e disponibilizadas, incluindo o sistema para capturar os dados das lojas, a
          manutenção da taxonomia, o motor de busca, os dicionários de sinônimos e o banco de dados
          de produtos são de propriedade da VIA.
        </p>
        <p>
          12.6. O PARCEIRO declara neste ato não existirem quaisquer contratos, obrigações, gravames
          ou ônus que as impeçam executar a Parceria e firmar este Contrato, estando assim,
          inteiramente livres e desembaraçadas para assumir e cumprir todos os direitos e obrigações
          dele resultantes.
        </p>
      </div>
      <p className="font-bold underline">13. COMUNICAÇÕES</p>
      <div>
        <p>
          13.1. As comunicações e notificações previstas nas Condições do Marketplace ou em razão da
          Parceria serão feitas por escrito através de meio eletrônico (“e-mail”) ou por qualquer
          ferramenta e/ou plataforma que seja indicada e utilizada pela VIA para gestão do
          Marketplace, restando acordado entre as partes que tal forma de comunicação será
          suficiente para os fins deste Contrato, salvo quando de outra forma for estipulado
          expressamente em lei.
        </p>
        <p>
          13.2. As autorizações para uso de Marcas e Direitos de Propriedade Industrial da VIA
          somente poderão ser feitas por escrito em meio físico não se aplicando a regra prevista
          neste Capítulo.
        </p>
        <p>
          13.3. As comunicações direcionadas ao PARCEIRO serão realizadas por meio do endereço
          eletrônico indicado no Formulário de Adesão ou no e-mail que estiver constando na
          Plataforma, respondendo o PARCEIRO pela manutenção e atualização de todos seus dados
          cadastrais e de contato, devendo comunicar por escrito toda e qualquer modificação à VIA
          /VVLOG no prazo máximo de 05 (cinco) dias a contar da data da alteração.
        </p>
        <p>
          13.4. As comunicações para a VIA/VVLOG deverão ser realizadas por meio de abertura de
          chamado no{' '}
          <a href={LINK_FOR_CALLED} target="_blank" rel="noopener noreferrer">
            Suporte ao lojista
          </a>
          .
        </p>
      </div>
      <p className="font-bold underline">14. CÓDIGO DE CONDUTA, DECLARAÇÕES E ANTICORRUPÇÃO</p>
      <div>
        <p>14.1. O PARCEIRO declara e garante que:</p>
        <p>
          a) Exerce suas atividades em total conformidade com a legislação em vigor e possui todas
          as aprovações, licenças, alvarás, permissões e/ou autorizações necessárias.
        </p>
        <p>
          b) Adota as ações necessárias à preservação do meio ambiente, exercendo suas atividades de
          forma sustentável e cumprindo com todas as normas ambientais aplicáveis nas esferas
          federal, estadual ou municipal.
        </p>
        <p>
          c) Cumpre rigorosamente o Estatuto da Criança e do Adolescente (Lei nº 8.069/90) e demais
          normas legais ou regulamentares em vigor, e não emprega mão de obra infantil, ou de menor
          de 18 anos, inclusive menor aprendiz, em locais prejudiciais à sua formação, ao seu
          desenvolvimento físico, psíquico, moral e social, bem como em locais e serviços perigosos
          ou insalubres, em horários que não permitam a frequência à escola e, ainda, em horário
          noturno (entre as 22h e 5h), e não mantém acordo comercial ou de qualquer espécie com
          empresas que utilizam, exploram ou empregam trabalho infantil ou de menor de 18 anos sem
          observância dos ditames legais.
        </p>
        <p>
          d) Não explora e não explorará qualquer forma de trabalho degradante ou análoga à condição
          de escravo, respeitando a Declaração Universal dos Direitos Humanos, assim como as
          Convenções nº 29 e 105 da Organização Internacional do Trabalho - OIT, a Declaração de
          Princípios e Direitos Fundamentais no Trabalho da OIT e pela Convenção Americana sobre
          Direitos Humanos.
        </p>
        <p>
          e) Não utiliza práticas de discriminação e limitativas ao acesso na relação de emprego ou
          a sua manutenção, em decorrência de sexo, origem, raça, cor, condição física, religião,
          estado civil, situação familiar ou qualquer outra condição.
        </p>
        <p>
          f) O seu ambiente de trabalho não coloca em risco a integridade física ou a saúde dos
          empregados, colocando em prática constantemente ações para reduzir acidentes e para
          melhorar as condições de trabalho de seus empregados. No ambiente de trabalho, os
          empregados têm acesso a água potável, banheiros limpos em quantidade adequada, ventilação
          adequada, saídas de emergência, iluminação apropriada e condições de segurança.
        </p>
        <p>
          g) Adota todas as medidas e procedimentos necessários ao cumprimento integral de todas as
          determinações impostas pela Lei de Proteção de Dados Pessoais nº 13.709/18.
        </p>
        <p>
          h) Não contrata com terceiros que não exerçam suas atividades em conformidade com os
          preceitos acima mencionados.
        </p>
        <p>
          14.2. O PARCEIRO declara conhecer e se compromete a observar e garantir que seus
          colaboradores respeitem o Código de Conduta Ética da VIA, naquilo que lhe diz respeito, e
          integralmente a Norma Anticorrupção da VIA, ambos disponibilizados no endereço eletrônico
          (
          <a href="http://ri.viavarejo.com.br" target="_blank" rel="noopener noreferrer">
            http://ri.viavarejo.com.br
          </a>
          ).
        </p>
        <p>
          14.3. O PARCEIRO declara que não é entidade relacionada com a VIA ou empresas do seu Grupo
          Econômico, nem o são quaisquer de seus sócios, administradores ou empregados, nos termos
          da Deliberação CVM nº 642/2010 e, portanto, declara que seus administradores, sócios e
          empregados, incluindo seus parentes até o 3º grau: (i) não são sócios de qualquer empresa
          do Grupo Econômico da VIA, com participação acionária superior a 5% (cinco por cento);
          (ii) não ocupam cargo que possa influenciar a presente contratação em empresa que pertence
          ao Grupo Econômico da VIA; e/ou (iii) não possuem relação de dependência econômica ou
          subordinação de qualquer natureza com qualquer empresa do Grupo Econômico da VIA; (iv) não
          têm conhecimento de qualquer situação, condição ou evento com qualquer administrador ou
          sócio - com participação acionária superior a 5% (cinco por cento) – de empresa do Grupo
          Econômico da VIA, que permita ao PARCEIRO obter vantagem comercial em qualquer transação
          ou relação comercial, potencial ou real com qualquer empresa do Grupo Econômico da VIA.
        </p>
        <p>
          14.4. O PARCEIRO obriga-se a cumprir, e fazer cumprir, por si, suas afiliadas ou seus
          proprietários, acionistas, funcionários ou eventuais subcontratados, a Lei Anticorrupção
          (Lei nº 12.846/13) e outras regulamentações correlatas, devendo (i) adotar as melhores
          práticas de integridade e controles internos, com o objetivo de prevenir atos de
          corrupção, fraude, práticas ilícitas ou lavagem de dinheiro; (ii) abster-se de praticar
          atos de corrupção e de agir de forma lesiva à administração pública, no interesse ou para
          benefício, exclusivo ou não, da VIA; em especial, não dar, oferecer ou prometer, direta ou
          indiretamente, qualquer coisa de valor ou vantagem a agente público ou pessoa a eles
          relacionada, com o objetivo de obter vantagem indevida, influenciar ato ou decisão ou
          direcionar negócios ilicitamente; e (iii) caso tenha conhecimento de qualquer ato ou fato
          que viole as normas citadas, comunicar imediatamente a VIA, por meio dos canais de
          denúncia: 0800-4504504, e-mail:{' '}
          <a href="mailto:canaldedenuncia@via.com.br">canaldedenuncia@via.com.br</a>, ou pelo site{' '}
          <a href="https://canalconfidencial.com.br/via/" target="_blank" rel="noopener noreferrer">
            https://canalconfidencial.com.br/via/{' '}
          </a>
          que sejam tomadas as providências que entender necessárias.
        </p>
        <p>
          14.5. O PARCEIRO declara-se ciente de que, caso a VIA constate que qualquer das
          declarações e garantias acima são descumpridas, poderá suspender seus acessos e a
          comercialização no Marketplace e, a seu exclusivo critério, rescindir imediatamente a
          Parceria sem que lhe seja aplicada qualquer penalidade, tendo, ainda, direito a ser
          ressarcida caso tal descumprimento lhe cause qualquer dano, nos termos do Capítulo 10.4
          das Condições do Marketplace.
        </p>
      </div>
      <p className="font-bold underline">15. DISPOSIÇÕES GERAIS</p>
      <div>
        <p>
          15.1. O PARCEIRO, desde já, fica ciente e concorda que todas e quaisquer reclamações
          decorrentes deste Contrato e da Parceria das Partes, incluindo, sem se limitar, a
          contestação de valores de crédito e débito havido entre as Partes, deverão ser registradas
          junto à VIA no prazo máximo de 90 (noventa) dias a contar da data de ocorrência do fato
          objeto da reclamação do PARCEIRO. Após este prazo, operar-se-á a perda do direito de
          reclamar, dando o PARCEIRO à VIA total quitação quanto ao cumprimento de todas as suas
          obrigações contratuais no período indicado.
        </p>
        <p>
          15.2. Nos termos da Cláusula acima, a VIA poderá, a seu exclusivo critério, enviar ao
          PARCEIRO, semestralmente, um termo de quitação que deverá ser prontamente assinado e
          devolvido à VIA.
        </p>
        <p>
          15.3. O PARCEIRO, desde já, autoriza a VIA/VVLOG a compartilhar todos os seus dados
          cadastrais e resultados de suas Avaliações, com empresas parceiras que realizam a
          Avaliação e/ou auditorias; com instituições financeiras e/ou de pagamento que participem
          do fluxo de recebimento e Repasses dos pagamentos pela VIA/VVLOG ao PARCEIRO; empresas
          transportadoras e/ou subcontratadas para prestação dos Serviços de Entrega, quando
          aplicável; com empresas do mesmo grupo econômico da VIA para viabilizar e enriquecer dados
          de análise de
          <span className="italic">KYC (Know your customer</span> – conheça seu cliente),ainda,
          permitir campanhas de marketing e oferta de produtos e serviços pelas pelas demais
          empresas do grupo econômico da VIA.
        </p>
        <p>
          15.4. Este Contrato não estabelece nenhum caráter de exclusividade entre as Partes, nem
          entre as empresas controladoras, coligadas e/ou controladas, seus representantes legais,
          conselheiros, diretores e/ou funcionários das Partes.
        </p>
        <p>
          15.5. O não exercício por qualquer das Partes de quaisquer direitos ou faculdades que lhes
          sejam conferidos por este contrato ou pela Lei, bem como a eventual tolerância contra
          infrações contratuais cometidas pela outra Parte, não importará em renúncia a qualquer dos
          seus direitos contratuais ou legais, novação ou alteração de cláusulas deste contrato,
          podendo a Parte, a seu exclusivo critério, exercê-los a qualquer momento.
        </p>
        <p>
          15.6. O PARCEIRO não poderá ceder e, de nenhuma forma, transferir, total ou parcialmente o
          presente contrato, ou quaisquer direitos decorrentes deste, sem o consentimento por
          escrito da VIA. A VIA poderá, conforme seu critério e interesse, ceder os créditos
          oriundos deste Contrato, com o que, desde já, o PARCEIRO concorda e autoriza.
        </p>
        <p>
          15.7. Este Contrato consolida toda e qualquer prévia negociação ou acordo, verbal ou por
          escrito, referente ao seu objeto, sobrepondo-se e substituindo, portanto, a todos os
          contratos, entendimentos, negociações e conversas anteriores, nos termos da Cláusula 17.1.
        </p>
        <p>
          15.8. Se, em decorrência de qualquer decisão judicial irrecorrível, qualquer disposição ou
          termo deste contrato for sentenciada nula ou anulável, tal nulidade ou anulabilidade não
          afetará as demais cláusulas deste contrato, as quais permanecerão em pleno vigor,
          obrigando ambas as Partes.
        </p>
        <p>
          15.9. As Partes deste acordo são independentes e nada aqui citado deve ser interpretado
          como criador de vínculo empregatício, relação de representatividade, joint-venture,
          sociedade de fato ou de direito ou consórcio entre as Partes. Nenhuma delas tem qualquer
          direito, poder ou autoridade de assumir qualquer obrigação ou responsabilidade em nome ou
          por conta da outra.
        </p>
        <p>
          15.10. O PARCEIRO se responsabiliza pelo pagamento de todo e qualquer tributo que incida
          ou venha a incidir na consecução do objeto do presente contrato, desde que para tanto se
          enquadre na posição legal de sujeito passivo da relação tributária.
        </p>
        <p>
          15.11. O PARCEIRO desde já acordam que este Instrumento, bem como demais documentos que
          venham a ser firmados entre as Partes em razão da Parceria, inclusive Notificações,
          poderão ser assinados eletronicamente, por meio de ferramenta a ser disponibilizada pela
          Via, sendo que as Partes reconhecem que serão válidas e eficazes as assinaturas
          eletrônicas dos seus representantes legais, nos termos do artigo 10, § 2º da Medida
          Provisória 2.200/2001-2.
        </p>
        <p>
          15.12. O PARCEIRO declara que este Instrumento (i) não viola qualquer disposição de seus
          documentos societários, (ii) não viola, infringe de qualquer forma, constitui, ou dá causa
          a inadimplemento a quaisquer disposições contratuais, compromissos ou outras obrigações
          relevantes dos quais as Partes sejam parte ou pelos quais estejam vinculados, (iii) não
          infringe qualquer disposição de lei, decreto, norma ou regulamento, ordem administrativa
          ou judicial ao qual qualquer das Partes estejam sujeitas, (iv) não exige qualquer
          consentimento, aprovação ou autorização de, aviso a, ou arquivamento ou registro junto a
          qualquer pessoa, física ou jurídica, ou tribunal, (v) não exige qualquer consentimento,
          aprovação ou autorização de, aviso a, ou arquivamento ou registro junto a qualquer pessoa,
          física ou jurídica, em virtude de negociações societárias, dentre estas, mas não se
          limitando, tratativas de fusão, cisão ou incorporação de empresas.
        </p>
      </div>
      <p className="font-bold underline">16. LEGISLAÇÃO APLICÁVEL E FORO</p>
      <div>
        <p>
          16.1. As Condições do Marketplace serão regidas pela legislação da República Federativa do
          Brasil.
        </p>
        <p>
          16.2. No caso de qualquer reivindicação ou controvérsia decorrente das Condições do
          Marketplace e da Parceria, a ele relacionada ou resultante de sua violação, as Partes
          envidarão seus melhores esforços para solucionar a questão de forma amigável. Para tanto,
          as Partes negociarão de forma a obter solução que seja justa e satisfatória para elas.
        </p>
        <p>
          16.3. Não chegando as Partes a um acordo, as partes elegem o foro da Comarca de São
          Caetano do Sul, do Estado de São Paulo, como competente para dirimir eventuais
          controvérsias, com prejuízo de qualquer outro, por mais privilegiado que seja ou venha a
          ser.
        </p>
      </div>
      <p className="font-bold underline">17. REGISTRO E ALTERAÇÕES DAS CONDIÇÕES DO MARKETPLACE</p>
      <div>
        <p>
          17.1. As Condições do Marketplace são firmadas, pela VIA, substituem e alteram os
          Contratos datados de 13 de agosto de 2014, registrado perante o 8° Cartório de Títulos e
          Documentos da Cidade de São Paulo sob nº de registro 1.359.980; de 05 de setembro de 2018,
          registrado perante o 2° Registro TDPJ São Caetano do Sul/SP sob nº de registro 47439; de
          23 de janeiro de 2020, registrado perante o 2° Registro TDPJ São Caetano do Sul/SP sob nº
          de registro 49396; e, de 29 de setembro de 2020, registrado perante o 2° Registro TDPJ São
          Caetano do Sul/SP sob nº de registro 49791 e também será levado ao registro público
          perante o 2° Registro TDPJ São Caetano do Sul/SP, nos termos da legislação vigente,
          passando a ter validade e eficácia perante terceiros, sendo exigível do PARCEIRO no
          momento de concretização de sua adesão.
        </p>
        <p>
          17.2. A VIA poderá alterar, a qualquer tempo e de maneira unilateral, os termos das
          Condições do Marketplace, nos Termos de Uso do Site ou nas condições ajustadas na
          Plataforma.
        </p>
        <p>
          17.2.1. A alteração de qualquer condição, inclusive de natureza comercial, poderá ser
          realizada a qualquer tempo pela VIA, mediante a comunicação prévia e por escrito do
          PARCEIRO, sendo as novas condições aplicáveis, no prazo de 10 (dez) dias após o
          recebimento do comunicado pelo PARCEIRO.
        </p>
        <p>
          17.2.2. Na hipótese de o PARCEIRO não concordar com alterações que venham a ser promovidas
          ou com qualquer uma das condições comerciais estabelecidas pela VIA, deverá notificar a
          VIA por escrito no prazo de 10 (dez) dias, contado do recebimento da mencionada
          notificação, a fim de resolver o presente contrato, sob pena de aceitação das novas
          condições estabelecidas pela VIA.
        </p>
        <p>
          17.3. Qualquer alteração nos termos deste Contrato será objeto de averbação e registro no
          Cartório de Registro de Títulos e Documentos.
        </p>
      </div>
      <p className="font-bold text-center">APÊNDICE I – ENVVIAS</p>
      <p className="font-bold text-center">SERVIÇOS DE ENTREGA</p>
      <p className="italic text-center">
        Este Apêndice I é parte integrante e inseparável das Condições Gerais para Comercialização
        em Ambiente Eletrônico – Marketplace da VIA
      </p>
      <p className="font-bold underline">1. OBJETO</p>
      <div>
        <p>
          1.1. O objeto deste Apêndice é detalhar os termos e condições para a prestação, pela VVLOG
          ao PARCEIRO, dos Serviços de Entrega dos Itens aos Clientes nos limites do Território,
          respeitadas as restrições em alguns perímetros de entrega da VVLOG.
        </p>
        <p>
          1.2. Sempre que determinada a entrega dos Produtos do PARCEIRO pelos Serviços de Entrega,
          as Partes ajustam e concordam que as condições previstas neste Apêndice, quando não
          complementarem, substituirão e prevalecerão às condições previstas nas Condições Gerais
          para Comercialização em Ambiente Eletrônico – Marketplace.
        </p>
      </div>
      <p className="font-bold underline">2. CONDIÇÕES OPERACIONAIS DO ENVVIAS</p>
      <div>
        <p>
          2.1. O PARCEIRO, desde já, concorda que teve acesso e tem ciência de todas as condições
          operacionais aplicáveis aos Serviços de Entrega através do Manual do Envvias, bem como se
          obriga a cumprir integralmente com todas as disposições ali constantes e sempre consultar
          suas condições atualizadas, considerando que a VVLOG poderá, a qualquer momento, alterar
          as definições previstas no Manual do Envvias, incluindo, sem se limitar, com relação à
          inclusão e/ou exclusão das categorias de Produtos contempladas nos Serviços de Entrega.
        </p>
        <p>
          2.2. Os Serviços de Entrega poderão ser prestados em duas modalidades: (i) postagem; ou,
          (ii) coleta. A VVLOG definirá qual modalidade será aplicada a cada PARCEIRO e poderá
          alterá-la conforme seus interesses
        </p>
        <p>
          2.2.1. Na modalidade postagem, a etiqueta de transporte para o envio do Item ao Cliente
          será disponibilizada via API (integração sistêmica) para impressão pelo PARCEIRO, que
          deverá etiquetar e entregar o Item a ser transportado em um posto de coleta dentre as
          opções previamente informadas pela VVLOG.
        </p>
        <p>
          2.2.2. Na modalidade coleta, a etiqueta de transporte para o envio do Item ao Cliente será
          disponibilizada via API (integração sistêmica) para impressão pelo PARCEIRO, que deverá
          etiquetar e disponibilizar o Item para que a VVLOG possa providenciar a coleta no endereço
          do PARCEIRO.
        </p>
        <p>
          2.2.2.1. O PARCEIRO poderá optar, quando disponibilizado pela VVLOG, pela entrega do Item
          em um dos endereços dos estabelecimentos da VIA para a coleta pela VVLOG para os Serviços
          de Entrega e/ou para retirada pelo próprio Cliente.
        </p>
        <p>
          2.2.2.2. Sempre que o Cliente adquirir mais de um Produto no mesmo pedido, serão
          disponibilizadas, nos termos das Cláusulas anteriores, tantas etiquetas quantos forem os
          Produtos adquiridos e individualmente considerados. Nesta hipótese, o PARCEIRO será
          responsável por cumprir todos os requisitos e condições de envio de cada Produto
          individualmente, da mesma forma, serão considerados a Remuneração VVLOG e o Repasse ao
          PARCEIRO, de modo que, ainda que o PARCEIRO inclua todos os Produtos em uma embalagem com
          etiquetagem única, será devida a Remuneração VVLOG por Produto.
        </p>
        <p>
          2.2.2.3. É vedado ao PARCEIRO utilizar toda e qualquer etiqueta disponibilizada para
          qualquer finalidade que não sejam os Serviços de Entrega da VVLOG, sendo integralmente
          responsável pela correta utilização das etiquetas nos termos deste Contrato.
        </p>
        <p>
          2.2.3. Em qualquer uma das modalidades acima previstas, o PARCEIRO se responsabiliza e se
          compromete por cumprir todas as condições e requisitos necessários para embalar e
          identificar o Item a ser entregue, nos termos indicados pela VVLOG, nos termos indicados
          pela VVLOG dispostos no Manual do Lojista.
        </p>
        <p>
          2.3. A definição do valor do frete a ser pago pelo Cliente, para a entrega do Item
          adquirido, no momento de efetivação do pedido no Marketplace é de exclusiva
          responsabilidade da VVLOG, que arcará com todas as despesas e custos, relacionados
          exclusivamente ao transporte do Produto, decorrentes dos Serviços de Entrega.
        </p>
        <p>
          2.3.1 Caso o valor do frete seja maior do que aquele informado pela VVLOG em razão de
          informações incorretas fornecidas pelo PARCEIRO, o valor da diferença será descontado pela
          VIA do valor a ser repassado ao PARCEIRO.
        </p>
        <p>
          2.4. Caso a opção de entrega não seja pela VVLOG, nos termos da Cláusula 4.11. das
          Condições do Marketplace, o PARCEIRO permanecerá obrigado a proceder com o envio do pedido
          ao Cliente, bem como arcar com todas as despesas e custos relativos à entrega, nos termos
          das Condições do Marketplace.
        </p>
        <p>
          2.4.1. Caso, nos termos da Cláusula 4.11. das Condições do Marketplace, o Produto se
          enquadre nos Serviços de Entrega e seja selecionada sistemicamente a entrega pela VVLOG,
          mas o PARCEIRO realize a entrega ao Cliente por meios próprios, responsabilizando-se por
          todas as despesas e custos relativos à entrega, a Retenção incluirá o valor da Remuneração
          VVLOG sem, contudo, possibilitar a prestação dos Serviços de Entrega, hipótese em que, o
          PARCEIRO se compromete e se responsabiliza por tomar todas as providências necessárias,
          mediante abertura dos respectivos chamados na Plataforma, para que a atualização das
          informações de entrega do pedido sejam diretamente realizadas no pedido do Cliente.
        </p>
        <p>
          2.5. As Partes ajustam que, quando aplicável, havendo a possibilidade de operacionalizar a
          logística reversa por meio da parceria entre a VVLOG e os Correios (ECT - Empresa
          Brasileira de Correios e Telégrafos), a VVLOG operacionalizará a logística reversa dos
          Produtos dos Serviços de Entrega.
        </p>
        <p>
          2.5.1. Na hipótese prevista na Cláusula acima, o PARCEIRO deverá tomar todas as
          providências para a operacionalização da logística reversa conforme as orientações que
          serão prestadas pela VVLOG e estarão disponíveis no Manual do Envvias, incluindo, sem se
          limitar, respeitar os limites estabelecidos para as dimensões da embalagem do Produto
          orientando o Cliente de forma adequada para que ele possa efetivar a postagem do Produto.
        </p>
        <p className="font-bold">Procedimentos de Envio</p>
        <p>
          2.6. Realizada a opção pelos Serviços de Entrega, a VVLOG orientará e indicará ao
          PARCEIRO, por meio da Plataforma, quais as condições para envio do Item ao Cliente. Todas
          as condições devem ser observadas e cumpridas em sua integralidade pelo PARCEIRO para
          garantia de execução dos Serviços de Entrega.
        </p>
        <p>
          2.7. A VVLOG disponibilizará ao PARCEIRO, na Plataforma, a etiqueta de transporte que
          deverá ser impressa e colada na parte externa da embalagem objeto da entrega, bem como
          todas as informações relativas aos Itens e entregas objeto dos Serviços de Entrega da
          VVLOG, disponibilizando ao Cliente a atualização do fluxo de entrega de seu pedido.
        </p>
        <p>
          2.7.1. O PARCEIRO tem ciência que as informações cadastradas para atualização do fluxo de
          entrega do pedido do Cliente poderão demorar até 48 (quarenta e oito) horas úteis para
          serem efetivadas em razão de integrações sistêmicas, serviços de telecomunicações, entre
          outros motivos que não são de responsabilidade da VVLOG, de forma que, o atraso na
          atualização das informações e status da entrega do pedido do Cliente não será considerada
          uma falha na prestação dos Serviços de Entrega.
        </p>
        <p>2.8. O PARCEIRO se obriga e garante que:</p>
        <p>
          a) Realizará o cadastro do Item para entrega informando de forma clara e exata as
          dimensões e o peso do Item;
        </p>
        <p>b) Emitirá a Nota Fiscal de Venda do Item e a afixará na parte externa da embalagem;</p>
        <p>
          c) Fará constar na embalagem as mesmas informações prestadas à VVLOG com relação aos dados
          de entrega indicados pelo Cliente, quais sejam, o nome e endereço completos;
        </p>
        <p>
          d) Utilizará embalagem adequada ao Item a ser entregue, de modo que, o invólucro proteja o
          Item de quaisquer avarias;
        </p>
        <p>
          e) Arcará com todos os custos e despesas relacionados à embalagem do Item e ao cumprimento
          das exigências para os Serviços de Entrega;
        </p>
        <p>
          f) Cumprirá todas as condições e exigências específicas de entrega, indicadas pela VVLOG,
          ainda que sejam exigências de terceiro subcontratado para prestação dos Serviços de
          Entrega.
        </p>
        <p>
          2.9. O PARCEIRO se obriga a disponibilizar o Item, de acordo com a modalidade de entrega
          definida pela VVLOG, no prazo indicado ao PARCEIRO na Plataforma. O Item deverá ser
          disponibilizado no prazo ajustado, cumprindo com os requisitos básicos indicados na
          Cláusula 2.8. acima, bem como as disposições do Manual do Envvias.
        </p>
        <p>
          2.9.1. Na hipótese prevista na Cláusula 2.2.2.1. deste Instrumento, a VIA disponibilizará
          espaço físico dedicado, sinalizado e destinado exclusivamente à guarda, entrega e recepção
          do Item do PARCEIRO, cuja ingerência será da VIA.
        </p>
        <p>
          2.9.2. Sem prejuízo das demais obrigações deste Contrato, os documentos fiscais dos Itens
          a serem retirados em estabelecimentos da VIA, deverão conter campos adicionais com os
          dados do endereço, número do CNPJ e Inscrição Estadual do estabelecimento da VIA escolhido
          como ponto de coleta, observando, ainda, eventuais obrigações adicionais constantes no
          Manual do Envvias.
        </p>
        <p>
          2.10. Em caso de descumprimento, pelo PARCEIRO, das condições e exigências previstas nas
          cláusulas acima, o PARCEIRO, desde já, está ciente e se compromete a arcar com todo e
          qualquer custo e/ou despesa exigido ou cobrado pela empresa transportadora. Caso a
          transportadora se recuse a realizar a entrega ao Cliente, por inconformidade, o PARCEIRO
          se responsabilizará pela entrega do pedido nos termos das Condições do Marketplace,
          hipótese em que o valor do frete será incluído no Repasse do PARCEIRO.
        </p>
        <p>
          2.10.1. Em nenhuma hipótese, a VVLOG e/ou a VIA, será(ão) responsável(is) pelos dados de
          entrega cadastrados diretamente pelo Cliente no momento da aquisição do Produto.
        </p>
        <p>
          2.11. A partir da disponibilização do Item à VVLOG para entrega, seja por entrega no ponto
          de coleta ou seja pela retirada pela transportadora no local indicado pelo PARCEIRO, a
          VVLOG realizará o atendimento aos Clientes que sejam exclusiva e diretamente relacionados
          aos Serviços de Entrega. Em caso de qualquer acionamento, pelo Cliente, antes da expedição
          do Item para VVLOG, o atendimento continuará sendo de responsabilidade do PARCEIRO, nos
          termos das Condições do Marketplace.
        </p>
        <p>
          2.12. Caso o cancelamento pelo Cliente ocorra após a entrega do Produto à empresa
          (subcontratada ou não) que realizará o Serviços de Entrega, a VVLOG realizará a retenção
          da Remuneração VVLOG do Repasse do PARCEIRO e não será devido qualquer reembolso do valor
          correspondente ao frete pela VVLOG ao PARCEIRO.
        </p>
        <p>
          2.13. Em caso de insucesso na entrega do Produto ao Cliente, seja por (i) recusa do
          Cliente ao recebimento do Produto por conta de qualquer não conformidade; ou, (ii) não ser
          possível entregar o Produto em razão de ausência do Cliente ou de alguém que possa receber
          o Produto em seu lugar; o Produto retornará ao ponto de coleta e a VVLOG comunicará o
          ocorrido ao PARCEIRO que deverá, no prazo máximo de 7 (sete) dias a contar da data de
          recebimento do comunicado, retirar o Produto no ponto de coleta. Expirado o prazo sem a
          retirada do Produto pelo PARCEIRO, a VIA poderá cobrar taxas e/ou repassar os custos pelo
          armazenamento do Produto, bem como, poderá, a seu exclusivo critério, descartar o Produto.
        </p>
        <p className="font-bold">Responsabilidades</p>
        <p>
          2.14. Sem prejuízo das disposições previstas nas Condições do Marketplace, as Partes
          ajustam as seguintes condições:
        </p>
        <p>
          2.14.1. <span className="font-bold">Atrasos</span>. O PARCEIRO se responsabilizará, nos
          termos da Cláusula 8 das Condições do Marketplace, pelo atraso na entrega do Item ao
          Cliente, em razão de descumprimento, pelo PARCEIRO, de suas obrigações contratuais, em
          especial, sem limitar, desta Cláusula 2. Caso o atraso da entrega resulte de qualquer ato
          ou omissão da VVLOG, a VVLOG será a parte responsável.
        </p>
        <p>
          2.14.2. <span className="font-bold">Obrigações Fiscais</span>. O PARCEIRO se
          responsabiliza integral e exclusivamente por todas as obrigações fiscais e/ou tributárias
          aplicáveis às suas atividades, incluindo, sem se limitar, às obrigações relacionadas à
          comercialização do Produto e respectiva emissão de Nota Fiscal, bem como quaisquer
          atividades que sejam relacionadas ao transporte do Produto e à liberação do Produto junto
          aos órgãos competentes para seu livre trânsito.
        </p>
        <p>
          2.14.2.1. Em caso de embaraço e/ou situações de impedimento de transporte dos Produtos
          pela VVLOG, o PARCEIRO, desde já, autoriza a VVLOG, a realizar os pagamentos que sejam
          necessários para liberação do transporte, a seu exclusivo critério, responsabilizando-se
          pelo posterior reembolso de tais valores à VVLOG nos termos deste Contrato.
        </p>
        <p>
          2.14.3. <span className="font-bold">Extravios e Avarias</span>. A VVLOG se responsabiliza
          por qualquer avaria, extravio, roubo ou furto que ocorra durante os Serviços de Entrega,
          isto é, a partir do momento da expedição do Item à VVLOG, nos termos da Cláusula 2.11.
          deste instrumento, até o momento da efetiva entrega ao Cliente. A VVLOG não se
          responsabilizará por quaisquer extravios ou avarias ocorridos no trajeto de logística
          reversa e/ou extravios/avarias que sejam resultado de quaisquer descumprimentos, pelo
          PARCEIRO, de suas obrigações contratuais.
        </p>
        <p>
          2.15. Em nenhuma hipótese a VVLOG se responsabilizará por danos indiretos em razão desta
          Parceria. Na hipótese prevista na Cláusula 2.14.3. acima, a VVLOG realizará o reembolso,
          ao PARCEIRO, do valor do Produto, objeto da ocorrência, constante na respectiva Nota
          Fiscal, conforme condições da cláusula seguinte. O valor deste reembolso deverá ser
          excluído em caso de qualquer ressarcimento de danos diretos comprovados posteriores que o
          PARCEIRO venha solicitar à VVLOG, sem prejuízo da análise quanto ao cabimento de qualquer
          ressarcimento adicional.
        </p>
        <p>
          2.15.1. Caso o Item extraviado seja localizado e devolvido ao PARCEIRO, a VVLOG fica,
          desde já, autorizada a realizar o estorno/desconto do valor pago ao PARCEIRO pela VVLOG, a
          título de reembolso e/ou ressarcimento, nos termos da Cláusula anterior, no primeiro
          Repasse subsequente à devolução do Produto ao PARCEIRO.
        </p>
        <p>
          2.16. O pagamento de quaisquer ressarcimentos devido pela VVLOG ao PARCEIRO será realizado
          juntamente com o primeiro Repasse a ser realizado no prazo de 90 (noventa) dias a contar
          da data em que tenha ocorrido o Extravio ou Avaria.
        </p>
        <p>
          2.17. A indicação de informações dos Produtos em desacordo com a realidade, assim como o
          uso indevido de etiquetas emitidas através do VVLOG Entrega, ensejará na aplicação das
          penalidades previstas neste Contrato, sem prejuízo da responsabilidade do PARCEIRO por
          eventuais custos adicionais relativos ao envio dos Produtos e danos causados à VVLOG ou a
          terceiros.
        </p>
      </div>
    </div>
  );
};

export default DefaultContract;
