import React from 'react';
import { ChevronDoubleUpIcon } from '@heroicons/react/solid';
import style from './index.styles';

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <style.Div
      className="h-9 w-9 rounded-full"
      onClick={scrollToTop}
      onKeyUp={scrollToTop}
      role="button"
      tabIndex={0}
    >
      <ChevronDoubleUpIcon className="h-5 w-5 text-white block" />
    </style.Div>
  );
};

export default BackToTop;
