import { ChangeEvent, ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form, Alert } from 'react-bootstrap';
import { If, Else, Then } from 'react-if';

import { setStorageWithTtl, getStorage } from '../../helpers/Storage';
import validations from '../../helpers/Validations';

import StepClass from '../../entities/Step';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import TooltipInfo from '../../components/Tooltip';
import Input from '../../components/Input';
import Password from '../../components/Password';
import LoadingDefault from '../../components/LoadingDefault';
import AuthService from '../../services/AuthService';
import ChatService from '../../services/ChatService.jsx';
import ServiceRegistration from '../../services/RegisterService';
import HtmlInput from '../../interfaces/HtmlInput';
import { User } from '../../interfaces/User';

import DefaultErrorData from './DefaultError';

import {
  gtmAttributesHiddenPassword,
  gtmAttributesWhatsappInfo,
  gtmAttributesTooltipEmail,
  gtmAttributesInvalidPassword,
  gtmAttributesFieldsRequired,
  gtmAttributesUpdateRegisterPage,
} from '../../utils/gtmVariables';

import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

import AppConfig from '../../config/AppConfig';

import './index.css';

const Chat = new ChatService();

const Register = () => {
  const [tooltip, showTooltip] = useState('');

  const [user, setUser] = useState<User>({
    cnpj: '',
    email: '',
    nome: '',
    cellphone: '',
    phone: '',
    password: '',
    optInWhats: false,
    soldBy: '',
  } as User);

  const [formValidation, setFormValidation] = useState({
    cellphone: false,
    phone: false,
    password: false,
  });

  const [check, setCheck] = useState(true);
  const [isLogged, setIsLogged] = useState(getStorage('isLogged'));
  const [variant, setVariant] = useState('success');
  const [disableButton, setDisableButton] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const search = new URLSearchParams(location.search);
  const tokenBase64 = search.get('token') || '';
  const coupon_name = search.get('coupon_name') || '';
  const commissioned_user_id = search.get('commissioned_user_id') || '';

  const serviceRegistration = new ServiceRegistration();
  const Step = new StepClass();
  const gtmEventsTracker = useGTMEventsTracker();
  const [btnText, setBntText] = useState('Continuar');
  let [messages, setMessages] = useState<any>([]);

  useEffect(() => {
    gtmEventsTracker(gtmAttributesUpdateRegisterPage);
  }, []);

  const loginSuccess = (seller: any) => {
    navigate('/pas/home', {
      state: { type: seller.contract.seller_entrance },
    });
  };

  const loginError = () => {
    navigate('/pas/error');
  };

  const sendToStep = () => {
    const data = JSON.parse(getStorage('seller'));
    const seller = data.seller;

    try {
      if (seller.step.currentStep === 'Modal ao PAS') {
        loginSuccess(seller);
      }

      if (seller.complianceStatus === 'reproved') {
        return navigate('/compliance/invalid/cnpj');
      }

      const isHunting = seller.contract.sellerEntrance === 'hunting';
      const isContractStep = seller.step.currentStep === 'Contrato';
      const isFreightStep = seller.step.currentStep === 'Frete';

      if (isHunting && isContractStep) {
        if (seller.contract.type) {
          return navigate('/hunting/contract', {
            state: { contract: seller.contract },
          });
        } else {
          AuthService.logout();
          return navigate('/hunting/email/confirmation');
        }
      }

      let url = Step.getStep(seller.step.currentStep)?.url ?? '/';

      let params = isContractStep ? { state: { contract: seller.contract } } : {};

      if (isFreightStep) {
        url += 'envvias';
      }
      return navigate(url, params);
    } catch {
      addMessage(
        'Ocorreu um erro inesperado no sistema.<strong> Por favor, tente novamente!</strong >',
        'warning'
      );
      return AuthService.logout();
    }
  };

  useEffect(() => {
    Chat.createScriptChat(AppConfig.get('CHAT_REGISTER_TOKEN_PRE_REGISTER'), 3000);
    if (tokenBase64) {
      serviceRegistration
        .activate(tokenBase64, {})
        .then((data) => {
          setIsLogged(true);
          AuthService.setLocalSeller(data);
          sendToStep();
        })
        .catch((err) => {
          const activated = err.response.data?.error === 'token_already_used';
          if (activated) {
            navigate('/confirmation-account');
          }
        });
      return;
    }
    if (isLogged) {
      sendToStep();
    }
  }, []);

  const clickCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setCheck(e.target.checked);
    setUser({
      ...user,
      optInWhats: e.target.checked,
    });
  };

  const isValidAttributes = {
    verifyAttribute: function (attribute: string) {
      gtmAttributesFieldsRequired.erro = `Voce precisa preencher o campo ${attribute}`;
      gtmEventsTracker(gtmAttributesFieldsRequired);
    },
  };

  const addMessage = (message: any, variant: string) => {
    setMessages((prevMessages: any) => [...prevMessages, { message, variant }]);
    clearMessages();
  };

  const removeMessage = (index: number) => {
    setMessages((prevMessages: any) => {
      const updatedMessages = [...prevMessages];
      updatedMessages.splice(index, 1);
      return updatedMessages;
    });
  };

  const clearMessages = () => {
    setTimeout(() => {
      setMessages([]);
    }, 4000);
  };

  const statusMessage = (error?: DefaultErrorData) => {
    let mainCause: string = error?.mensagem
      ? error.mensagem
      : 'Um erro inesperado aconteceu. Tente novamente mais tarde.';
    /**
     * includes('40') will catch all those status "240, 400, 404, 401, 402, 403, 405, 406, 407, 408, 409, 540"
     * "240" (Invalid Media Type) and "540" (Unknown Error), but they will not happen here
     */
    if (error?.codigo?.includes('40') && error.motivos.length > 0) {
      error.motivos.map((element) => {
        addMessage(element, 'danger');
      });
    } else {
      addMessage(mainCause, 'danger');
    }
    /* Atualiza o DOM */
    setTimeout(() => {
      setBntText('Continuar');
      setDisableButton(false);
    }, 3500);
  };

  const handleSubmit = async (event: any) => {
    setBntText('Aguarde...');
    event.preventDefault();

    if (!formValidation.password) {
      gtmEventsTracker(gtmAttributesInvalidPassword);
      setBntText('Continuar');
      addMessage('<strong>Senha inválida</strong>', 'warning');
    }

    if (!formValidation.phone || !formValidation.cellphone) {
      setBntText('Continuar');
      addMessage('Por favor, revise as informações do formulário', 'warning');
    }

    if (validateUser(user)) {
      setDisableButton(true);
      await registerSeller();
      return;
    }
    const valid = isValidAttributes['verifyAttribute'];
    Object.keys(user).forEach((value: any) => {
      // @ts-expect-error: Unreachable code error
      if (!user[value]) {
        valid(value);
      }
    });
    setDisableButton(false);
    setBntText('Continuar');
    addMessage('Preencha os campos do formulário', 'warning');
  };

  const validateUser = (user: User) => {
    return Object.values(user).every((value) => value !== '');
  };

  const registerSeller = async () => {
    const body = {
      coupon: {},
      document: user.cnpj,
      sold_by: user.soldBy,
      optinWhats: user.optInWhats ?? check,
      responsible: {
        cellphone: user.cellphone,
        email: user.email,
        name: user.nome,
        password: user.password,
        phone: user.phone,
      },
    };

    coupon_name && commissioned_user_id
      ? Object.assign(body.coupon, {
          coupon_name: coupon_name,
          commissioned_user_id: commissioned_user_id,
        })
      : Object.assign(body.coupon, {
          coupon_name: coupon_name,
        });

    if (body.optinWhats) {
      gtmEventsTracker(gtmAttributesWhatsappInfo);
    }

    const res = await serviceRegistration.createSeller(body, statusMessage);

    if (res === undefined) return;

    setStorageWithTtl('sellerEmail', user.email, 1440);
    setStorageWithTtl('sellerId', res?.data?.conteudo.id, 1440);
    setStorageWithTtl('sellerName', user.nome, 1440);
    setStorageWithTtl('seller', JSON.stringify(res?.data.conteudo), 1440);
    navigate('/confirmation-email');
  };

  const validateInputValue = (value: string, name: string) => {
    if (name == 'nome') return validations.onlyLetters(value);
    if (name == 'cnpj') return validations.onlyNumbers(value);
  };

  const handleChange = useCallback(
    (e: any) => {
      if (e.target && typeof e.target.value !== undefined) {
        setUser({
          ...user,
          [e.target.name]: ['nome', 'cnpj', 'email'].includes(e.target.name)
            ? validateInputValue(e.target.value, e.target.name)
            : e.target.value,
        });
      }
    },
    [user]
  );

  const setEmailValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target && typeof e.target.value !== undefined) {
      setUser({
        ...user,
        [e.target.name]: validations.toLowerCase(e),
      });
    }
  };

  const setPasswordValue = (e: any) => {
    if (e?.target?.value) {
      setUser({
        ...user,
        password: e.target.value,
      });
    }
    if (e?.isValid) {
      setFormValidation({ ...formValidation, password: e.isValid });
    }
  };

  const validCellphone = (e: any) => {
    if (e?.target?.value) {
      setUser({
        ...user,
        cellphone: validations.onlyNumbers(e.target.value),
      });
    }
    setFormValidation({ ...formValidation, cellphone: e.isValid });
  };

  const validPhone = (e: any) => {
    if (e?.target?.value) {
      setUser({
        ...user,
        phone: validations.onlyNumbers(e.target.value),
      });
    }
    setFormValidation({ ...formValidation, phone: e.isValid });
  };

  return (
    <If condition={isLogged}>
      <Then>
        <LoadingDefault />
      </Then>
      <Else>
        <div id="mp__register">
          <div className="mp__register-header">
            <span className="mp__register-header_title">Cadastro</span>
            <p className="mp__register-header_text">
              Pra começar, informe os dados solicitados. É necessário preencher todos os campos.
            </p>
          </div>
          <section className="mp__register-form">
            <Form noValidate onSubmit={handleSubmit} className="w-full">
              <div className="mp__register-form_content">
                <Input
                  id={'mp__input-register_email'}
                  label="Email"
                  type="email"
                  name="email"
                  value={user.email}
                  rules={[(val: HtmlInput) => validations.isValidEmail(val, true)]}
                  onChange={(e: any) => setEmailValue(e)}
                >
                  <TooltipInfo
                    className={`${tooltip} mp__input_register-email_tooltip`}
                    text={
                      <>
                        É com ele que você vai <b>acessar a sua loja</b> no portal do lojista.
                      </>
                    }
                    onClick={() => gtmEventsTracker(gtmAttributesTooltipEmail)}
                  />
                </Input>
              </div>
              <div className="mp__register-form_content">
                <Input
                  id={'mp__input-register_name'}
                  label="Nome e sobrenome"
                  type="text"
                  name="nome"
                  rules={[(val: HtmlInput) => validations.required(val, 'nome e sobrenome')]}
                  onChange={handleChange}
                  value={user.nome}
                />
              </div>
              <div className="mp__register-form_content grid sm:grid-cols-2 gap-3">
                <Input
                  id={'mp__input-register_cell'}
                  type="text"
                  name="cellphone"
                  onChange={(e: any) => validCellphone(e)}
                  maskType="cellphone"
                  label="Celular"
                  placeholder={'DD 99999 9999'}
                  rules={[
                    (val: HtmlInput) => validations.length(val, 15, 'celular'),
                    (val: HtmlInput) => validations.required(val, 'celular'),
                    (val: HtmlInput) => validations.isNotPhoneNumber(val, 'celular'),
                  ]}
                />
                <Input
                  id={'mp__input-register_phone'}
                  type="text"
                  name="phone"
                  onChange={(e: any) => validPhone(e)}
                  maskType="phone"
                  label="Telefone"
                  placeholder={'DD 9999 9999'}
                  rules={[
                    (val: HtmlInput) => validations.length(val, 14, 'telefone'),
                    (val: HtmlInput) => validations.required(val, 'telefone'),
                    (val: HtmlInput) => validations.isNotPhoneNumber(val, 'telefone'),
                  ]}
                />
              </div>
              <div className="mp__register-form_content">
                <Input
                  id={'mp__input-register_cnpj'}
                  type="text "
                  name="cnpj"
                  onChange={handleChange}
                  maskType="cnpj"
                  label="CNPJ"
                  placeholder={'99.999.999/0001-99'}
                  rules={[
                    (val: HtmlInput) => validations.length(val, 18, 'CNPJ'),
                    (val: HtmlInput) => validations.required(val, 'CNPJ'),
                  ]}
                />
              </div>
              <div className="mp__register-form_content">
                <Input
                  id={'mp__input-register_sold-delivered-by'}
                  type="text "
                  name="soldBy"
                  onChange={handleChange}
                  label="Vendido e entregue por"
                  rules={[(val: HtmlInput) => validations.required(val, 'Vendido entregue por')]}
                />
              </div>
              <div className="mp__register-form_content">
                <Password
                  onChange={(e: any) => setPasswordValue(e)}
                  loginPassword={false}
                  required
                  gtmAttributes={gtmAttributesHiddenPassword}
                />
              </div>
              <div className="mp__register-form_checkbox">
                <Checkbox
                  label="Aceito receber informações pelo WhatsApp"
                  value={check}
                  onChange={clickCheck}
                />
              </div>
              <div className="mp__register-form_action">
                <Button
                  id={'mp__btn-submit_register'}
                  text={btnText}
                  type={'submit'}
                  color={'blue'}
                  classNames={'mp__register-form_action-button'}
                  disabled={disableButton}
                />
              </div>
            </Form>
          </section>
          <div className="mp__register_notify-container">
            {messages.map((messageObj: any, index: number) => (
              <Alert
                key={index}
                variant={messageObj.variant}
                onClose={() => {
                  removeMessage(index);
                }}
                dismissible
              >
                <div dangerouslySetInnerHTML={{ __html: messageObj.message }} />
              </Alert>
            ))}
          </div>
        </div>
      </Else>
    </If>
  );
};

export default Register;
