import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import TextStyles from '../../components/TextStyles';
import Button from '../../components/Button';
import Image from '../../components/Image';

import error404 from '../../assets/images/404.png';

const PageNotFound = () => {
  const navigate = useNavigate();

  function handleClick() {
    window.location.href = 'https://so.via.com.br/';
  }

  return (
    <div className="not-found-container">
      <div className="text-content">
        <div className="title-wrapper">
          <TextStyles text="Parece que essa página sumiu!" className="title" />
        </div>
        <div className="subtitle-wrapper">
          <TextStyles
            text="Bora tentar de novo? Deixa que eu te ajudo a chegar onde precisa."
            className="subtitle"
          />
        </div>
        <div className="back-initial-btn-wrapper">
          <Button
            text="Voltar para a página inicial"
            type="button"
            color="blue"
            id="mp__not-found_back-initial-btn"
            classNames="mp__back-initial-btn"
            onClick={handleClick}
          />
        </div>
        <div className="back-previous-btn-wrapper">
          <Button
            text="Voltar para a página anterior"
            type="button"
            color="blue"
            outline={true}
            id="mp__not-found_back-previous-btn"
            classNames="mp__back-previous-btn"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="image-content">
        <Image images={[{ img: `${error404}`, alt: 'marketplace-logo' }]} />
      </div>
    </div>
  );
};

export default PageNotFound;
