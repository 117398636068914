import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import ProgressStep from '../../components/ProgressStep';
import ProgressBar from '../../components/ProgressBar';
import TooltipInfo from '../../components/Tooltip';
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';

import imgCard1 from '../../assets/svg/calculator.svg';
import imgCard2 from '../../assets/svg/location.svg';
import imgCard3 from '../../assets/svg/block.svg';
import imgCard4 from '../../assets/svg/dollar_sign.svg';

import StepInterface from '../../interfaces/Step';
import StepClass from '../../entities/Step';

import Card from './Card';

import RegisterService from '../../services/RegisterService';

import { gtmAttributesTooltipEnvvias } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';
import { gtmAttributesUpdateFreightEnvviasPage } from '../../utils/gtmVariables';

import AppConfig from '../../config/AppConfig';

const Step = new StepClass();
const Service = new RegisterService();

const Envvias: FC<any> = () => {
  const current: number = 5;
  const steps: Array<StepInterface> = Step.steps();
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateFreightEnvviasPage);

  const handleLastStep = () => {
    Service.registrationCompleted({}).then(() => {
      navigate('/pas/home');
    });
  };

  const cards = [
    <Card
      key="freight"
      img={imgCard1}
      alt="Frete"
      title="Frete mais barato"
      body="Não cobramos comissão sobre o frete."
    />,
    <Card
      key="tracking"
      alt="Rastreio"
      img={imgCard2}
      title="Rastreio certeiro"
      body="O rastreamento dos pedidos é automatizado."
    />,
    <Card
      key="cost"
      alt="Custo"
      img={imgCard3}
      title="Sem custo adicional"
      body="A adesão é totalmente gratuita."
    />,
    <Card
      key="warranty"
      alt="Garantia"
      img={imgCard4}
      title="Garantia de ressarcimento"
      body="Pra roubos, extravios e avarias."
    />,
  ];

  const carouselSettings = {
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="p-2">
      <div className="mp__contract-progressbar">
        <ProgressStep steps={steps} current={current} />
      </div>
      <div className="mp__contract-progress_mobile">
        <ProgressBar
          total={steps.length}
          step={current}
          subtitle={steps.find((e) => e.step === current)?.title ?? ''}
        />
      </div>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <div className="text-xl font-semibold">Logística Envvias</div>
        <TooltipInfo
          text={
            <>
              É a nossa solução logística, que ajuda na operação e gestão das entregas.
              <b> Conheça o Envvias</b>
            </>
          }
          className="ml-1"
          onClick={() => gtmEventsTracker(gtmAttributesTooltipEnvvias)}
        />
      </div>
      <div className="d-flex flex-column align-items-center text-xs">
        <p className="text-center">
          Aqui, você usa a plataforma de frete da Via. <br />
          <b>Com o Envvias Postagem, você conta com diversos benefícios:</b>
        </p>
      </div>
      <div className="mp__envvias-cards justify-content-center mt-5 mb-5">
        <Carousel items={cards} customSettings={carouselSettings} />
      </div>
      <div className="d-flex justify-content-center mb-5">
        <Button
          text="Finalizar"
          type="button"
          color="blue"
          classNames="mp__envvias-button"
          onClick={handleLastStep}
        />
      </div>
    </div>
  );
};

export default Envvias;
