import React from 'react';
import './index.css';

interface StrengthProps {
  strong: Boolean;
}

function Strength({ strong }: StrengthProps) {
  return (
    <div className="d-flex align-items-center my-2">
      <div
        className={`progress-strength position-relative mr-3 ${strong ? 'strong-password' : ''}`}
      />
      <span className="strength-password">Senha {strong ? 'forte' : 'fraca'}</span>
    </div>
  );
}

export default Strength;
