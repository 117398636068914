import styled from 'styled-components';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import color from '../../utils/colors';

const Label = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  line-height: 24px;
  color: ${color.neutral_dark};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ span {
    background-color: ${color.primary_dark};
  }
  &:checked ~ span:after {
    display: block;
  }
`;

const Span = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  border: 1px solid #929497;
  border-radius: 2px;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export default {
  Label,
  Input,
  Span,
};
