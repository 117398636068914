export default {
  primary_dark: '#5D4EE8',
  neutral_lightest: '#FCFDFC',
  neutral_darkest: '#202121',
  primary_darkest: '#17117A',
  secondary_darkest: '#00D3C3',
  white: '#FFFFFF',
  neutral_light: '#DBDCDC',
  neutral_dark: '#6D6E70',
};
