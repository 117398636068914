import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './index.css';

import InputCopy from '../../components/InputCopy';
import Button from '../../components/Button';
import ProgressStep from '../../components/ProgressStep';
import StepInterface from '../../interfaces/Step';
import StepClass from '../../entities/Step';
import ProgressBar from '../../components/ProgressBar';
import { gtmAttributesUpdateIntegrationTokenPage } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

interface LocationState {
  to: string;
  token: string;
}

const Step = new StepClass();

const Integration = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { to, token } = location.state as LocationState;
  const current: number = 4;
  const steps: Array<StepInterface> = Step.steps();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateIntegrationTokenPage);

  return (
    <>
      <div className="mp__integration-progress_desktop">
        <ProgressStep steps={steps} current={current} />
      </div>
      <div className="mp__integration-progress_mobile">
        <ProgressBar
          total={steps.length}
          step={current}
          subtitle={steps.find((e) => e.step === current)?.title ?? ''}
        />
      </div>
      <div className="mp__integration-container">
        <span className="mp__integration-title">A integradora da sua loja já está habilitada</span>
        <InputCopy buttonText={'Copiar token'} inputValue={token} id="mp__integration_input-copy" />
        <p className="mp__integration-text">
          Copie este código e cole no campo indicado no painel de sua integradora para ativar a
          integração e volte para finalizar seu cadastro.
        </p>
        <Button
          text={'Continuar'}
          type={'submit'}
          color={'blue'}
          classNames={'mp__integration-button'}
          onClick={() => navigate(to)}
        />
      </div>
    </>
  );
};

export default Integration;
