import React, { useEffect } from 'react';
import Step from '../../interfaces/Step';
import './index.css';
import { CheckIcon } from '@heroicons/react/solid';
import CircleIcon from '@mui/icons-material/Circle';

interface PropsInterface {
  steps: Array<Step>;
  current: number;
}

const ProgressStep = (props: PropsInterface) => {
  useEffect(() => {
    const progress = document.getElementById('progress');
    const circles = document.querySelectorAll('.circle');
    const actives = document.querySelectorAll('.mp__step-icon');

    progress!.style!.width = ((actives.length - 1) / (circles.length - 1)) * 83 + '%';
  }, [props.current]);

  return (
    <div className="mp__step-container">
      <div className="mp__step-wrapper">
        <div className="mp__step-progress" id="progress"></div>
        {props.steps.map((item, id) => (
          <div key={id} className="mp__step-space">
            {item.step < props.current ? (
              <span className="circle mp__step-icon">
                <CheckIcon className="w-3 h-3 text-primaryDark" />
              </span>
            ) : item.step === props.current ? (
              <span className="circle mp__step-icon active">
                <CircleIcon className="text-primaryDark" fontSize="small" />
              </span>
            ) : (
              <span className="circle mp__step-no_icon">
                <CheckIcon className="w-3 h-3 text-neutralLightest" />
              </span>
            )}
            <span className="mp__step-title">{item.title}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressStep;
