import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PageNotFound from '../pages/PageNotFound';
import Maintenance from '../pages/Freezing/Maintenance';
import { BlackFriday } from '../pages/Freezing/BlackFriday';
import { BlackFridayLayout } from '../layout/BlackFridayLayout';

interface FreezingProps {
  applicationStatus: boolean;
}

const Freezing = ({ applicationStatus }: FreezingProps) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<BlackFridayLayout />}>
          <Route path="/maintanance" element={<Maintenance maintenance={applicationStatus} />} />
          <Route index element={<BlackFriday />} />
          <Route path="*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default Freezing;
