import React from 'react';
import { gtmAttributesUpdateForgotPasswordEmailSentPage } from '../../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../../hooks/useGTMEventsTracker';

const SentStep = () => {
  const gtmEventsTracker = useGTMEventsTracker();
  gtmEventsTracker(gtmAttributesUpdateForgotPasswordEmailSentPage);

  return (
    <div className="mp__forgot_password-container sent">
      <span className="mp__forgot_password-title pr-12 sm:pr-0">Enviamos um email para você</span>
      <p className="mp__forgot_password-text">
        Por favor, verifique a sua caixa de entrada e de spam. Abra o link que está no email e
        redefina a senha de acesso.
      </p>
    </div>
  );
};

export default SentStep;
