const PAGE_NAME = '/onboarding';
const TEMPLATE_PAGE_ACCESS = 'acessomarketplace';
const TEMPLATE_PAGE_REGISTER = 'cadastromarketplace';
const TEMPLATE_PAGE_HOME = 'cadastromarketplace';
const GA_EVENT = 'ga_event';
const UPDATE_EVENT = 'update';
const EVENT_CATEGORY_VIA = 'via mktplace';
const EVENT_CATEGORY_REGISTER = 'cadastro mktplace';
const EVENT_CATEGORY_ACCESS = 'acesso mktplace';
const EVENT_ACTION_CLICK = 'clicou';
const EVENT_ACTION_ENABLE = 'habilitou';
const EVENT_ACTION_SHOW_ALERT = 'exibiu alerta';

const gtmAttributesGaEvent = (
  eventLabel: string,
  eventCategory = EVENT_CATEGORY_REGISTER,
  event = GA_EVENT,
  eventAction = EVENT_ACTION_CLICK
) => {
  return {
    event,
    eventCategory,
    eventAction,
    eventLabel,
  };
};

const gtmAttributesUpdateError = (error: string) => {
  return {
    event: UPDATE_EVENT,
    erro: error,
  };
};

const gtmAttributesUpdatePage = (path: string, templatePage = TEMPLATE_PAGE_REGISTER) => {
  return {
    event: UPDATE_EVENT,
    pagina: {
      nomePagina: `${PAGE_NAME}${path}`,
      templatePagina: templatePage,
    },
  };
};

export const gtmAttributesInstagram = gtmAttributesGaEvent(
  'acompanhe a gente instagram',
  EVENT_CATEGORY_VIA
);
export const gtmAttributesYoutube = gtmAttributesGaEvent(
  'acompanhe a gente youtube',
  EVENT_CATEGORY_VIA
);
export const gtmAttributesFacebook = gtmAttributesGaEvent(
  'acompanhe a gente facebook',
  EVENT_CATEGORY_VIA
);
export const gtmAttributesLinkedin = gtmAttributesGaEvent(
  'acompanhe a gente linkedin',
  EVENT_CATEGORY_VIA
);
export const gtmAttributesMakeLogin = gtmAttributesGaEvent('faca seu login', EVENT_CATEGORY_VIA);
export const gtmAttributesMakeLogout = gtmAttributesGaEvent('sair', EVENT_CATEGORY_VIA);
export const gtmAttributesCopyToken = gtmAttributesGaEvent('copiar token');
export const gtmAttributesAcceptedBanks = gtmAttributesGaEvent('confira os bancos aceitos');
export const gtmAttributesTooltipAgency = gtmAttributesGaEvent('tooltip agencia');
export const gtmAttributesTooltipAccount = gtmAttributesGaEvent('tooltip conta corrente');
export const gtmAttributesBankNotFound = gtmAttributesGaEvent('nao encontrei meu banco');
export const gtmAttributesReceiveEmail = gtmAttributesGaEvent('nao recebeu o email');
export const gtmAttributesSignedContract = gtmAttributesGaEvent(
  'contrato assinado com sucesso',
  EVENT_CATEGORY_REGISTER,
  UPDATE_EVENT,
  EVENT_ACTION_SHOW_ALERT
);
export const gtmAttributesTooltipEnvvias = gtmAttributesGaEvent('tooltip frete');
export const gtmAttributesInvalidEmail = gtmAttributesUpdateError('email invalido');
export const gtmAttributesRecoverPassword = gtmAttributesGaEvent(
  'aguarde enquanto processamos as suas informacoes',
  EVENT_CATEGORY_ACCESS,
  GA_EVENT,
  EVENT_ACTION_SHOW_ALERT
);
export const gtmAttributesError = gtmAttributesUpdateError(
  'a senha precisa conter pelo menos um numero'
);
export const gtmAttributesApiError = gtmAttributesUpdateError('email ou senha invalido');
export const gtmAttributesPassword = gtmAttributesGaEvent('ocultar senha', EVENT_CATEGORY_VIA);
export const gtmAttributesRememberUser = gtmAttributesGaEvent(
  'lembrar do meu usuario',
  EVENT_CATEGORY_ACCESS,
  GA_EVENT,
  EVENT_ACTION_ENABLE
);
export const gtmAttributesRegistration = gtmAttributesGaEvent(
  'ainda nao tenho cadastro',
  EVENT_CATEGORY_ACCESS
);
export const gtmAttributesSalesManagement = gtmAttributesGaEvent('saiba mais | gestão de vendas');
export const gtmAttributesAttendance = gtmAttributesGaEvent('saiba mais | atendimento');
export const gtmAttributesTrainings = gtmAttributesGaEvent('saiba mais | treinamentos aplicados');
export const gtmAttributesFinancial = gtmAttributesGaEvent('saiba mais | financeiro');
export const gtmAttributesAccessPas = gtmAttributesGaEvent(
  'acessar o portal do lojista',
  'home mktplace'
);
export const gtmAttributesAboutIntegration = gtmAttributesGaEvent('saiba mais sobre a integracao');
export const gtmAttributesHiddenPassword = gtmAttributesGaEvent('ocultar senha');
export const gtmAttributesWhatsappInfo = gtmAttributesGaEvent(
  'aceito receber informacoes pelo whatsapp',
  EVENT_CATEGORY_REGISTER,
  GA_EVENT,
  EVENT_ACTION_ENABLE
);
export const gtmAttributesTooltipEmail = gtmAttributesGaEvent('tooltip email');
export const gtmAttributesExistingEmail = gtmAttributesUpdateError('email ja cadastrado');
export const gtmAttributesInvalidPassword = gtmAttributesUpdateError('senha invalida');
export const gtmAttributesFieldsRequired = gtmAttributesUpdateError('Preencha todos os campos');
export const gtmAttributesActiveAlert = gtmAttributesGaEvent(
  'email enviado',
  EVENT_CATEGORY_REGISTER,
  UPDATE_EVENT,
  EVENT_ACTION_SHOW_ALERT
);
export const gtmAttributesProcessingShipping = gtmAttributesGaEvent(
  'estamos processando o seu pedido de reenvio',
  EVENT_CATEGORY_REGISTER,
  UPDATE_EVENT,
  EVENT_ACTION_SHOW_ALERT
);
export const gtmAttributesNotMyEmail = gtmAttributesGaEvent('esse nao e o meu email');
export const gtmAttributesResend = gtmAttributesGaEvent('reenviar email');

// Events update datalayer
export const gtmAttributesUpdatePasswordPage = gtmAttributesUpdatePage(
  '/acesso/recuperacao_senha/email_enviado/nova_senha',
  TEMPLATE_PAGE_ACCESS
);
export const gtmAttributesUpdateLoadingPage = gtmAttributesUpdatePage('/cadastro/loading');
export const gtmAttributesUpdatePasErrorPage = gtmAttributesUpdatePage('/cadastro/loading/erro');
export const gtmAttributesUpdateRegisterPage = gtmAttributesUpdatePage('/cadastro');
export const gtmAttributesUpdateTokenErrorPage = gtmAttributesUpdatePage(
  '/cadastro/integracao/token_enviado_email'
);
export const gtmAttributesUpdateForgotPasswordPage = gtmAttributesUpdatePage(
  '/acesso/recuperacao_senha'
);
export const gtmAttributesUpdateComplianceErrorPage = gtmAttributesUpdatePage(
  '/cadastro/inconsistencia_cnpj'
);
export const gtmAttributesUpdateIntegrationTokenPage = gtmAttributesUpdatePage(
  '/cadastro/integracao/token'
);
export const gtmAttributesUpdateForgotPasswordEmailSentPage = gtmAttributesUpdatePage(
  '/acesso/recuperacao_senha/email_enviado',
  TEMPLATE_PAGE_ACCESS
);
export const gtmAttributesUpdateConfirmationAccountPage = gtmAttributesUpdatePage(
  '/cadastro/conta_confirmada'
);
export const gtmAttributesUpdateConfirmationEmailPage = gtmAttributesUpdatePage(
  '/cadastro/confirmacao_email'
);
export const gtmAttributesUpdateContractPage = gtmAttributesUpdatePage('/cadastro/contrato');
export const gtmAttributesUpdatePasHomePage = gtmAttributesUpdatePage('/home', TEMPLATE_PAGE_HOME);
export const gtmAttributesUpdateProductManualIntegrationPage = gtmAttributesUpdatePage(
  '/cadastro/integracao/cadastro_manual'
);
export const gtmAttributesUpdateFreightEnvviasPage =
  gtmAttributesUpdatePage('/cadastro/frete_envvias');
export const gtmAttributesUpdateFreightDefaultPage = gtmAttributesUpdatePage(
  '/cadastro/frete_correios'
);
export const gtmAttributesUpdateResendEmailPage = gtmAttributesUpdatePage(
  '/cadastro/nao_recebeu_email'
);
export const gtmAttributesUpdateBankDataPage = gtmAttributesUpdatePage('/cadastro/dados_bancarios');
export const gtmAttributesUpdateLoginPage = gtmAttributesUpdatePage(
  '/acesso/login',
  TEMPLATE_PAGE_ACCESS
);
export const gtmAttributesUpdateProductIntegrationPage =
  gtmAttributesUpdatePage('/cadastro/integracao');
