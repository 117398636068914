import { ReactElement } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { InformationCircleIcon } from '@heroicons/react/solid';
import './index.css';

export interface TooltipProperties {
  text: ReactElement;
  link?: Object;
  title?: String;
  className?: String;
  onClick?: () => void;
}

const TooltipInfo = (props: TooltipProperties) => {
  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      key="top"
      placement="top"
      overlay={
        <Tooltip id="tooltip-top" className="rounded-lg shadow-xl">
          <>
            <span className="tooltip__text">{props.text}</span>
            {props.link}
          </>
        </Tooltip>
      }
    >
      <div className={`tooltip__content ${props.className ?? ''}`} onClick={props.onClick}>
        {props.title ? (
          <span className="tooltip__title">{props.title}</span>
        ) : (
          <InformationCircleIcon className="tooltip__icon" />
        )}
      </div>
    </OverlayTrigger>
  );
};

export default TooltipInfo;
