import axios from '../http';
import url from '../url';

export default class BankService {
  addBankData(bankData: any) {
    return axios.patch(`${url.bank.updateBank}`, {
      ...bankData,
    });
  }
  getBanks(params: object) {
    return axios.get(`${url.bank.getBanks}`, {
      params,
    });
  }
  async registerBankSuggestion(params: object, bankSuggestionValidation: Function) {
    let error = null;
    try {
      await axios.post(`${url.bank.suggestedBanks}`, { ...params });
    } catch (err) {
      error = err;
    } finally {
      bankSuggestionValidation(error);
    }
  }

  async downloadFile(fileUrl: string) {
    const response = await axios.get(fileUrl, {
      responseType: 'blob',
    });

    if (response.status != 200) {
      throw new Error('HTTP error ' + response.status);
    }

    const downloadUrl = URL.createObjectURL(response.data);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = 'Bancos-aceitos.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
