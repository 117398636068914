import { Button } from 'react-bootstrap';
import './index.css';

export interface ButtonProperties {
  id?: string;
  text: String;
  type: 'button' | 'submit' | 'reset' | undefined;
  onClick?: Function;
  color?: 'blue' | 'green' | undefined;
  outline?: Boolean;
  classNames?: String;
  props?: Object;
  disabled?: boolean;
}

const span = (loading: String) => {
  return loading === 'Aguarde...' ? (
    <>
      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
      <span role="status">{loading}</span>
    </>
  ) : (
    loading
  );
};

function Btn({
  id,
  text,
  type,
  color,
  outline,
  classNames,
  onClick,
  props,
  disabled,
}: ButtonProperties) {
  const buttonClass = outline ? `outline-${color}` : `bg-${color}`;

  return (
    <div id="mp__button">
      <Button
        id={id}
        className={`button ${buttonClass} ${classNames || ''}`}
        onClick={() => onClick instanceof Function && onClick()}
        type={type}
        {...props}
        disabled={disabled}
      >
        {span(text)}
      </Button>
    </div>
  );
}

export default Btn;
