import React, { ChangeEvent, useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

import './index.css';

import Input from '../../components/Input';
import Password from '../../components/Password';
import Button from '../../components/Button';
import CheckBox from '../../components/Checkbox';
import TextStyles from '../../components/TextStyles';

import HtmlInput from '../../interfaces/HtmlInput';
import validations from '../../helpers/Validations';
import AuthService from '../../services/AuthService';
import ChatService from '../../services/ChatService.jsx';
import { setStorageWithTtl, getStorage, removeStorage } from '../../helpers/Storage';
import {
  gtmAttributesApiError,
  gtmAttributesPassword,
  gtmAttributesRememberUser,
  gtmAttributesRegistration,
  gtmAttributesUpdateLoginPage,
} from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

const Chat = new ChatService();

const Login = () => {
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState('');
  const [message, setMessage] = useState<ReactElement>();
  const [email, setEmail] = useState<string | undefined>('');
  const [password, setPassword] = useState<string | undefined>('');
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  useEffect(() => {
    gtmEventsTracker(gtmAttributesUpdateLoginPage);
    Chat.resetScriptChat();
  }, []);

  const setFieldValue = (e: any, callback: Function) => {
    if (e.target && typeof e.target.value !== undefined) {
      callback(e.target.value);
    }
  };

  const userInactive = () => {
    setStorageWithTtl('sellerEmail', email ?? '', 1440);
    navigate('/confirmation-email');
  };

  const anotherError = () => {
    setVariant('warning');
    gtmEventsTracker(gtmAttributesApiError);
    setMessage(
      <>
        Email ou senha incorretos.
        <strong> Por favor, tente novamente!</strong>
      </>
    );
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 3000);
    return AuthService.logout();
  };

  const login = () => {
    if (email !== '' && password !== '') {
      AuthService.login({ email, password })
        .then(() => {
          navigate('/');
        })
        .catch((error: { response: { status: number; data: { message: string } } }) => {
          if (error.response && error.response.data.message === 'user_inactive') {
            return userInactive();
          }

          return anotherError();
        });
    } else {
      gtmEventsTracker(gtmAttributesApiError);
      setVariant('warning');
      setMessage(
        <>
          Email ou senha não informados.
          <strong> Por favor, tente novamente!</strong>
        </>
      );
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  };

  return (
    <div id="mp__login" className="login-container">
      <div className="login-content">
        <Input
          type="text"
          label="Email"
          name="email"
          className="email-input"
          value={email}
          rules={[(val: HtmlInput) => validations.isValidEmail(val, true)]}
          onChange={(e: any) => setFieldValue(e, setEmail)}
        />
        <Password
          onChange={(e: any) => setFieldValue(e, setPassword)}
          loginPassword={true}
          inputProps={{ value: password }}
          gtmAttributes={gtmAttributesPassword}
        />
        <Button
          type="submit"
          text="Acessar"
          classNames="login-button"
          outline={false}
          onClick={login}
          id="mp__login_access-button"
        />
        <TextStyles
          text="Ainda não tem seu cadastro?"
          className=""
          fatherClassName="not-yet-registered"
        >
          <a href="/" onClick={() => gtmEventsTracker(gtmAttributesRegistration)}>
            Clique aqui
          </a>
        </TextStyles>
      </div>
      <div className="notify-container">
        <Alert
          show={show}
          variant={variant}
          onClose={() => setShow(false)}
          dismissible
          closeVariant="white"
        >
          <p>{message}</p>
        </Alert>
      </div>
    </div>
  );
};

export default Login;
