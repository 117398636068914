import React, { useState } from 'react';
import './index.css';
import { ProgressBar } from 'react-bootstrap';

interface ProgressBarProps {
  step: number;
  total: number;
  subtitle: string;
}

function CustomProgressBar({ step, total, subtitle }: ProgressBarProps) {
  const getStep = () => {
    if (step > total) return total;
    if (step <= 0) return 1;
    return step;
  };

  return (
    <div>
      <div className="container-progress-bar">
        Passo {getStep()} de {total}:
        <span className="progress-bar-subtitle fw-bolder">{subtitle}</span>
      </div>
      <ProgressBar now={Math.round((getStep() / total) * 100)} />
    </div>
  );
}

export default CustomProgressBar;
