import styled from 'styled-components';

const Container = styled.div``;

const Text = styled.span``;

export default {
  Container,
  Text,
};
