import React from 'react';
import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from '../pages/ProtectedRoute';
import ConfirmationAccount from '../pages/ConfirmationAccount';
import ConfirmationEmail from '../pages/ConfirmationEmail';
import ResendEmail from '../pages/ResendEmail';
import UpdateEmail from '../pages/UpdateEmail';
import ForgotPassword from '../pages/ForgotPassword/Email';
import ResetPassword from '../pages/ForgotPassword/Password';
import Home from '../pages/Pas/Home';
import HuntingCreatePassword from '../pages/Hunting/CreatePassword';
import Integration from '../pages/Integration';
import InvalidCNPJ from '../pages/InvalidCNPJ';
import Login from '../pages/Login';
import PageNotFound from '../pages/PageNotFound';
import ProductIntegration from '../pages/ProductIntegration';
import ProductManualRegistration from '../pages/ProductManualRegistration';
import Register from '../pages/Register';
import EmailSent from '../pages/EmailSent';
import Contract from '../pages/Contract';
import PasErrorScreen from '../pages/Pas/ErrorScreen';
import BankData from '../pages/BankData';
import Envvias from '../pages/Envvias';
import IntegrationError from '../pages/IntegrationError';
import { NonApprovedIntegrators } from '../pages/NonApprovedIntegrators';
import { DefaultLayout } from '../layout/DefaultLayout';

const DefaultRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DefaultLayout />}>
          <Route
            path="/integration-error"
            element={<ProtectedRoute component={<IntegrationError />} />}
          />
          <Route index element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/password/recovery/:token" element={<ResetPassword />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/hunting/contract" element={<ProtectedRoute component={<Contract />} />} />
          <Route path="/step/contract" element={<ProtectedRoute component={<Contract />} />} />
          <Route path="/confirmation-account" element={<ConfirmationAccount />} />
          <Route path="/confirmation-email" element={<ConfirmationEmail />} />
          <Route
            path="/product/manual/registration"
            element={<ProtectedRoute component={<ProductManualRegistration />} />}
          />
          <Route
            path="/compliance/invalid/cnpj"
            element={<ProtectedRoute component={<InvalidCNPJ />} />}
          />
          <Route path="/resend-email" element={<ResendEmail />} />
          <Route path="/update-email" element={<UpdateEmail />} />
          <Route path="/hunting/email/confirmation" element={<EmailSent />} />
          <Route path="/hunting/" element={<HuntingCreatePassword />} />
          <Route path="/step/bank" element={<ProtectedRoute component={<BankData />} />} />
          <Route
            path="/step/freight/envvias"
            element={<ProtectedRoute component={<Envvias />} />}
          />
          <Route
            path="/step/product/integration"
            element={<ProtectedRoute component={<ProductIntegration />} />}
          />
          <Route
            path="/step/product/integration/token"
            element={<ProtectedRoute component={<Integration />} />}
          />
          <Route
            path="/non-approved-integrators"
            element={<ProtectedRoute component={<NonApprovedIntegrators />} />}
          />
          <Route path="/pas/error" element={<ProtectedRoute component={<PasErrorScreen />} />} />
          <Route path="/pas/home" element={<ProtectedRoute component={<Home />} />} />
        </Route>
      </Routes>
    </>
  );
};

export default DefaultRoutes;
