import React, { FC, ReactElement } from 'react';
import style from './index.styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider, { Settings } from 'react-slick';

interface ListInterface {
  items: ReactElement[];
  customSettings?: object;
}

const Carousel: FC<ListInterface> = ({ items, customSettings }) => {
  const settingsDefault: Settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    appendDots: (dots) => <style.Dots>{dots}</style.Dots>,
    responsive: [
      {
        breakpoint: 9999,
        settings: 'unslick',
      },
      {
        breakpoint: 1248,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const settings = {
    ...settingsDefault,
    ...customSettings,
  };

  return (
    <Slider className="d-flex gap-2 flex-wrap justify-content-center" {...settings}>
      {items}
    </Slider>
  );
};

export default Carousel;
