import React, { ChangeEvent, FC } from 'react';
import './Card.css';
import ImageCard from './slots/ImageCard';
import HeaderCard from './slots/HeaderCard';
import KnowMoreCard from './slots/KnowMore';
import BodyCard from './slots/BodyCard';
interface CardInterface {
  title: string;
  subtitle?: string;
  color: string;
  imageUrl: string;
  body: string;
  link: string;
  onClick?: Function;
}

const Card: FC<CardInterface> = (props: CardInterface) => {
  return (
    <div className="card-container">
      <div className="content">
        <div className="image-container">
          <ImageCard src={props.imageUrl} colorBackground={props.color} />
        </div>
        <div className="card-title mt-0">
          <HeaderCard title={props.title} subtitle={props.subtitle} />
        </div>
        <div className="body">
          <BodyCard body={props.body} />
        </div>
        <div className="card-view-more">
          <KnowMoreCard link={props.link} text="Saiba mais" onClick={props.onClick} />
        </div>
      </div>
    </div>
  );
};

export default Card;
