import styled from 'styled-components';
import 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import color from '../../utils/colors';

const Footer = styled.footer`
  width: 100%;
  position: relative;
`;

const ContentFooter = styled.div`
  background-color: ${color.primary_darkest};
  padding: 48px 121px 36px 121px;

  @media (max-width: 575px) {
    padding: 48px 20px 46px 20px;
  }
`;

const ContentFollowUs = styled.div`
  @media (max-width: 575px) {
    display: block;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 45px;
`;

const WrapperFooterLogo = styled.div``;

const WrapperSocialMedias = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    margin 58px 0 34px 0;
  }
`;

const TitleSocialMedias = styled.span`
  font-size: 14px;
  font-family: Poppins;
  color: ${color.white};

  @media (max-width: 575px) {
    font-size: 12px;
    margin-bottom: 9px;
  }
`;

const WrapperSocialMediasIcon = styled.div`
  @media (max-width: 575px) {
    width: 50%;
  }
  @media (max-width: 300px) {
    width: 100%;
  }
`;

const SocialMediasIcon = styled.img`
  @media (max-width: 575px) {
    margin-right: 8px;
  }
`;

const SeparatorGrid = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

const SeparatorLineDiv = styled.div`
  width: 100%;
`;

const SeparatorLine = styled.hr`
  color: ${color.neutral_light};
  opacity: 1;

  @media (max-width: 575px) {
    margin: 0;
  }
`;

const ContentText = styled.div`
  padding-top: 18px;

  @media (max-width: 575px) {
    display: block;
    padding-top: 0;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const WrapperLegalText = styled.div`
  max-width: 37%;

  @media (max-width: 575px) {
    margin: 48px 0 10px 0;
    max-width: 80%;
  }

  @media (max-width: 300px) {
    max-width: 100%;
  }

  @media (min-width: 1440px) {
    max-width: 33%;
  }
`;

const legalText = styled.span`
  @media (max-width: 575px) {
    font-size: 12px;
  }

  font-family: Poppins;
  color: ${color.white};
  font-size: 14px;
`;

const WrapperImportantLinks = styled.div`
  @media (max-width: 575px) {
    width: 100%;
  }
`;

const ImportantLinks = styled.div`
  display: flex;

  @media (max-width: 575px) {
    padding-left: 0;
  }

  a {
    font-family: Poppins;
    font-size: 12px;
    color: ${color.white};
    text-decoration: none;
    cursor: pointer;
    font-weight: normal;
  }
`;

const TermsUse = styled.a``;

const PrivacyPolicy = styled.a`
  margin-left: 20px;
`;

export default {
  Footer,
  ContentFooter,
  ContentFollowUs,
  WrapperFooterLogo,
  WrapperSocialMedias,
  TitleSocialMedias,
  WrapperSocialMediasIcon,
  SocialMediasIcon,
  SeparatorGrid,
  SeparatorLineDiv,
  SeparatorLine,
  ContentText,
  WrapperLegalText,
  legalText,
  WrapperImportantLinks,
  ImportantLinks,
  TermsUse,
  PrivacyPolicy,
};
