import { FC } from 'react';
import { Container } from 'react-bootstrap';
import AppConfig from '../../../../config/AppConfig';

import './Welcome.css';

import { getStorage } from '../../../../helpers/Storage';

import Btn from '../../../../components/Button';

import { gtmAttributesAccessPas } from '../../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../../hooks/useGTMEventsTracker';

const Welcome: FC<any> = () => {
  const gtmEventsTracker = useGTMEventsTracker();
  const pasAccess = AppConfig.get('REACT_APP_LINK_CADASTRAR_SENHA');

  const accessPortal = () => {
    gtmEventsTracker(gtmAttributesAccessPas);
    if (pasAccess) {
      window.open(pasAccess, '_blank');
    } else {
      console.error('A variável de ambiente não está definida ou é inválida.');
    }
  };
  const { seller } = JSON.parse(getStorage('seller'));

  const message = (
    <>
      <div className="pt-4">
        A sua loja já está ativa no Portal do Lojista, o PAS. Agora basta{' '}
        <strong>cadastrar uma senha</strong> e começar a vender!
      </div>
      <div className="pt-4">
        Ah, aproveite pra conferir os nossos treinamentos na Via Academy - Escola para Lojistas
      </div>
      <div className="pt-4">Boas vendas!</div>
    </>
  );

  return (
    <Container className="welcome">
      <div className="d-flex flex-column">
        <div className="welcome-title">
          <span className="welcome-title-text">Boas-vindas!</span>
        </div>
        <div className="welcome-body d-flex flex-wrap">{message}</div>
      </div>
      <div className="pt-4 button-portal">
        <Btn text="Cadastrar senha" type="button" color="blue" onClick={accessPortal} />
      </div>
    </Container>
  );
};

export default Welcome;
