import React, { FC } from 'react';

interface HeaderCardInterface {
  title: string;
  subtitle?: string;
}

const HeaderCard: FC<HeaderCardInterface> = ({ title, subtitle }) => {
  return (
    <>
      <div>{title}</div>
      <div>{subtitle}</div>
    </>
  );
};

export default HeaderCard;
