import CryptoJS from 'crypto-js';
import _envs from '../../config/envs.js';

export function setStorageWithTtl(key: string, value: string, ttl: number) {
  let obj = {
    ttl: Date.now() + ttl * 60 * 1000,
    value: value,
  };
  let objStr = JSON.stringify(obj);
  setStorageEncrypt(key, objStr);
}

export function getStorage(key: string) {
  let data = getDecryptedData(key);
  if (!data) {
    return null;
  }

  const item = JSON.parse(data);
  if (item.ttl && Date.now() > item.ttl) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

export function removeStorage(key: string) {
  localStorage.removeItem(key);
}

function setStorageEncrypt(key: string, value: string) {
  const encrypted = CryptoJS.AES.encrypt(
    value,
    _envs.REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY
  ).toString();
  localStorage.setItem(key, encrypted);
}

function getDecryptedData(key: string) {
  const encryptedData = localStorage.getItem(key);
  if (encryptedData !== null) {
    return CryptoJS.AES.decrypt(encryptedData, _envs.REACT_APP_LOCAL_STORAGE_ENCRYPT_KEY)
      .toString(CryptoJS.enc.Utf8)
      .toString();
  }
}
