import React, { ReactElement } from 'react';

import './index.css';

import Button from '../Button';
import Loader from '../Loader';
import TextStyles from '../TextStyles';
import Tooltip from '../Tooltip';
import './index.css';

type type = 'information' | 'loading' | 'confirmation';

interface Props {
  panelType: type;
  subtitle?: string;
  buttonId?: string;
  buttonText?: string;
  linkText?: string;
  onClick?: any;
  link?: string;
  imageMobile?: string;
  image?: string;
  tooltipText?: ReactElement;
  tooltipLink?: string;
  tooltipTextLink?: string;
  title: string;
  description: ReactElement | string;
  className?: string;
  children?: React.ReactNode;
  gtm?: any;
}

const Panel = ({
  panelType = 'confirmation',
  subtitle,
  buttonId,
  buttonText,
  linkText,
  onClick,
  link,
  imageMobile,
  image,
  tooltipText = <></>,
  tooltipLink,
  tooltipTextLink,
  title,
  description,
  className,
  children,
  gtm,
}: Props) => {
  return (
    <div id="mp__panel" className={`panel-container ${className}`}>
      <div className="panel-wrapper">
        <div className="image-mobile-wrapper">
          <img src={imageMobile} />
        </div>
        <div className="position-content">
          {panelType == 'loading' && (
            <div className="painel-content">
              <Loader show={true} />
            </div>
          )}
          <TextStyles text={title} className="panel-title">
            {panelType == 'information' && (
              <Tooltip
                text={tooltipText}
                link={
                  <a href={tooltipLink} target="_blank">
                    {tooltipTextLink}
                  </a>
                }
                onClick={gtm}
              />
            )}
          </TextStyles>
          {subtitle && <TextStyles text={subtitle} className="panel-subtitle" />}
          {children || <TextStyles text={description} className="panel-description" />}
          {buttonText && (
            <Button
              id={buttonId}
              type="button"
              text={buttonText}
              classNames="panel-button"
              onClick={onClick}
              color={'blue'}
            />
          )}
          {linkText && (
            <div className="link-container">
              <a id="panel-link" className="panel-link" href={link} onClick={onClick}>
                {linkText}
              </a>
            </div>
          )}
        </div>
        <div className="image-wrapper">
          <img src={image} />
        </div>
      </div>
    </div>
  );
};

export default Panel;
