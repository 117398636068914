import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import Image from '../../assets/svg/cadastroManualProdutos.svg';
import ImageMobile from '../../assets/svg/cadastroManualProdutosMobile.svg';
import Panel from '../../components/Panel';
import { gtmAttributesUpdateProductManualIntegrationPage } from '../../utils/gtmVariables';
import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

const ProductManualRegistration: FC<any> = () => {
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();

  gtmEventsTracker(gtmAttributesUpdateProductManualIntegrationPage);

  return (
    <div id="mp__product-manual-registration">
      <Panel
        title="Cadastro manual de produtos"
        description=""
        panelType="information"
        tooltipText={<>Você é responsável por cadastrar os produtos que a sua loja vai oferecer.</>}
        tooltipTextLink="Clique e saiba como funciona o cadastro de produtos manuais"
        tooltipLink="https://suportemarketplace.viavarejo.com.br/pt-BR/lojista/categoria/?id=CAT-01025"
        buttonText="Entendi"
        onClick={() => navigate('/step/freight/envvias')}
        imageMobile={ImageMobile}
        image={Image}
        className="cadastro-manual-produtos"
      >
        <div className="cadastro-manual-produtos-body">
          Se depois você decidir usar uma integradora,
          <strong> é só habilitar a integração no Portal do Lojista.</strong>
        </div>
      </Panel>
    </div>
  );
};

export default ProductManualRegistration;
