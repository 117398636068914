import axios from '../http';
import url from '../url';

export default class ApplicationStatusService {
  async getApplicationStatus(applicationStatusValidate: Function) {
    let success = false;
    let isFreezing = null;
    try {
      const response = await axios.get(`${url.applicationStatus.freezing}`);
      isFreezing = response.data.status;
      success = true;
    } catch (error) {
      console.log({ error });
    } finally {
      applicationStatusValidate({ isFreezing, success });
    }
  }
}
