import React from 'react';

import styled from './index.styles';
import Image from '../Image';
import TextStyles from '../TextStyles';

import instagram from '../../assets/svg/instagram.svg';
import youtube from '../../assets/svg/youtube.svg';
import facebook from '../../assets/svg/facebook.svg';
import linkedin from '../../assets/svg/linkedin.svg';
import BackToTop from '../BackToTop';
import {
  gtmAttributesInstagram,
  gtmAttributesYoutube,
  gtmAttributesFacebook,
  gtmAttributesLinkedin,
} from '../../utils/gtmVariables';

import useGTMEventsTracker from '../../hooks/useGTMEventsTracker';

interface FooterProps {
  logo: string;
  instagramSvg: string;
  youtubeSvg: string;
  facebookSvg: string;
  linkedinSvg: string;
  titleSocialMedias: string;
  legalText: string;
  TermsUse: string;
  PrivacyPolicy: string;
}

const GTMEventsTracker = useGTMEventsTracker();

const Footer = ({ logo, titleSocialMedias, legalText, TermsUse, PrivacyPolicy }: FooterProps) => {
  return (
    <styled.Footer>
      <styled.ContentFooter>
        <styled.ContentFollowUs>
          <styled.WrapperFooterLogo>
            <Image images={[{ link: '/', img: `${logo}`, alt: 'marketplace-logo' }]} />
          </styled.WrapperFooterLogo>
          <styled.WrapperSocialMedias>
            <styled.TitleSocialMedias>{titleSocialMedias}</styled.TitleSocialMedias>
            <styled.WrapperSocialMediasIcon>
              <Image
                images={[
                  {
                    link: 'https://www.instagram.com/marketplacevia',
                    img: `${instagram}`,
                    alt: 'instagram',
                    onClick: () => GTMEventsTracker(gtmAttributesInstagram),
                  },
                  {
                    link: 'https://www.youtube.com/c/ViaMarketplace',
                    img: `${youtube}`,
                    alt: 'youtube',
                    onClick: () => GTMEventsTracker(gtmAttributesYoutube),
                  },
                  {
                    link: 'https://www.facebook.com/marketplacevia',
                    img: `${facebook}`,
                    alt: 'facebook',
                    onClick: () => GTMEventsTracker(gtmAttributesFacebook),
                  },
                  {
                    link: 'https://www.linkedin.com/company/viaimaginecaminhos',
                    img: `${linkedin}`,
                    alt: 'linkedin',
                    onClick: () => GTMEventsTracker(gtmAttributesLinkedin),
                  },
                ]}
                styles="flex justify-between items-center mt-[18px]"
                target="_blank"
              />
            </styled.WrapperSocialMediasIcon>
          </styled.WrapperSocialMedias>
        </styled.ContentFollowUs>
        <styled.SeparatorGrid>
          <styled.SeparatorLineDiv>
            <styled.SeparatorLine />
          </styled.SeparatorLineDiv>
          <BackToTop />
        </styled.SeparatorGrid>
        <styled.ContentText>
          <styled.WrapperLegalText>
            <styled.legalText>{legalText}</styled.legalText>
          </styled.WrapperLegalText>
          <styled.WrapperImportantLinks>
            <styled.ImportantLinks>
              <styled.TermsUse>
                <TextStyles text={`${TermsUse}`} />
              </styled.TermsUse>
              <styled.PrivacyPolicy>
                <TextStyles text={`${PrivacyPolicy}`} />
              </styled.PrivacyPolicy>
            </styled.ImportantLinks>
          </styled.WrapperImportantLinks>
        </styled.ContentText>
      </styled.ContentFooter>
    </styled.Footer>
  );
};

export default Footer;
