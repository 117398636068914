import React, { useState } from 'react';

import { Form } from 'react-bootstrap';
import './index.css';

import inputMask, { MaskTypes } from '../../helpers/Masks/';

interface Props {
  label?: string;
  ariaLabel?: string;
  id?: string;
  placeholder?: string;
  type: string;
  readOnly?: boolean;
  disabled?: boolean;
  title?: string;
  maxLength?: number;
  value?: string;
  name: string;
  className?: string;
  onChange?: (e: any) => void;
  children?: React.ReactNode;
  maskType?: MaskTypes;
  rules?: Array<Function>;
  loginPassword?: boolean;
}

const Input = ({
  label,
  ariaLabel,
  id,
  placeholder,
  type,
  readOnly,
  disabled,
  title,
  maxLength,
  value,
  name,
  className,
  onChange,
  children,
  maskType,
  rules,
  loginPassword,
}: Props) => {
  const [errors, setErrors] = useState('');
  const [invalid, setInvalid] = useState(false);
  const [required, setRequired] = useState(false);

  const handleChangeInput = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (maskType) {
        const mask = inputMask[maskType];
        event.currentTarget.value = mask(event);
      }
      if (typeof onChange === 'function') {
        onChange(event);
      }
    },
    [maskType, onChange]
  );

  const validate = (val: any) => {
    const error: string[] = [];
    let invalid = false;

    setRequired(false);
    setErrors('');

    rules?.map((fn) => {
      const valid = fn(val);

      if (valid === true) {
        return;
      } else {
        if (valid.includes('obrigatório')) setRequired(true);
        error.push(valid);
      }
    });

    if (error.length > 0) {
      setErrors(error.join(' '));
      invalid = true;
    }

    setInvalid(invalid);

    if (typeof onChange === 'function') {
      onChange({ isValid: error.length === 0, ...val });
    }
  };

  return (
    <Form.Group className="group" onSubmit={validate}>
      <div>
        {label && (
          <Form.Label className="label" htmlFor={label}>
            {label}{' '}
            {loginPassword && (
              <a href="/forgot-password" className="forgot-password">
                Esqueci a minha senha
              </a>
            )}
          </Form.Label>
        )}
        <Form.Control
          className={`input ${className || ''}`}
          id={id}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          readOnly={readOnly}
          title={title}
          required={required}
          maxLength={maxLength}
          value={value}
          name={name}
          onChange={handleChangeInput}
          aria-label={ariaLabel}
          isInvalid={invalid}
          onBlur={validate}
        />
        <Form.Control.Feedback className="validated" type="invalid">
          {errors}
        </Form.Control.Feedback>
        {children}
      </div>
    </Form.Group>
  );
};

export default Input;
