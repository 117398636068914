import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './index.css';

import Password from '../../../components/Password';
import Button from '../../../components/Button';

import ServiceRegistration from '../../../services/RegisterService';

const HuntingCreatePassword = () => {
  const [showConfirmation, setConfirmation] = useState(false);
  const [password, setPassword] = useState<string | undefined>('');
  const [disableButton, setDisableButton] = useState(false);

  const serviceRegistration = new ServiceRegistration();

  const navigate = useNavigate();

  const param = useLocation();
  const search = new URLSearchParams(param.search);
  const token = search.get('token')?.toString();

  const change = () => {
    setDisableButton(true);
    if (token && password && showConfirmation) {
      serviceRegistration
        .activateHunting(token, password)
        .then(() => {
          navigate('/hunting/email/confirmation');
        })
        .catch(() => {
          navigate('/confirmation-account');
        });
    }
    setDisableButton(false);
  };

  const changePassword = (e: any) => {
    setConfirmation(e?.isValid);
    if (e.target) {
      setPassword(e.target.value);
    }
  };

  return (
    <div id="mp__hunting-password" className="wrapper">
      <span className="mp__hunting_password-title">Cadastro de senha</span>
      <p className="mp__hunting_password-p pt-4 pb-3">
        Para continuar com o seu cadastro aqui na Via e por motivos de segurança, crie uma senha no
        campo abaixo.
      </p>
      <p className="mp__hunting_password-p pt-3 pb-4">
        É com ela que você vai acessar a sua área no nosso marketplace no futuro. Depois de
        confirmar, você vai receber um email com os próximos passos.
      </p>
      <div className="mp__hunting_password-form">
        <Password onChange={(e: any) => changePassword(e)} />
      </div>
      <div className="mp__hunting_password-action">
        <Button
          text={'Cadastrar'}
          type={'submit'}
          color={'blue'}
          classNames={'mp__hunting_password-action_button'}
          onClick={change}
          disabled={disableButton}
        />
      </div>
    </div>
  );
};

export default HuntingCreatePassword;
