import AppConfig from '../config/AppConfig';

class ChatService {
  createScriptChat(appId, delay = 0) {
    const microsoftWidgetOmnichannel = document.getElementById('Microsoft_Omnichannel_LCWidget');
    if (microsoftWidgetOmnichannel?.dataset?.appId !== appId) {
      this.resetScriptChat();

      setTimeout(() => {
        this.addScriptInHead(appId);
      }, delay);
    }
  }

  addScriptInHead(appId) {
    const chatAttr = {
      id: 'Microsoft_Omnichannel_LCWidget',
      src: 'https://oc-cdn-public-sam.azureedge.net/livechatwidget/scripts/LiveChatBootstrapper.js',
      'data-app-id': appId,
      'data-lcw-version': 'prod',
      'data-org-id': AppConfig.get('CHAT_SERVICE_TOKEN'),
      'data-org-url': AppConfig.get('CHAT_SERVICE_URL'),
    };

    const chatScript = document.createElement('script');
    for (const a in chatAttr) {
      chatScript.setAttribute(a, chatAttr[a]);
    }
    document.head.appendChild(chatScript);
  }

  resetScriptChat() {
    const microsoftWidgetOmnichannel = document.getElementById('Microsoft_Omnichannel_LCWidget');
    const microsoftWidgetChat = document.getElementById(
      'Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window'
    );

    if (typeof Microsoft !== 'undefined') Microsoft.Omnichannel.LiveChatWidget.SDK.closeChat();

    if (microsoftWidgetOmnichannel) microsoftWidgetOmnichannel.remove();

    if (microsoftWidgetChat) microsoftWidgetChat.remove();
  }
}

export default ChatService;
