import React, { FC } from 'react';
import './index.css';

interface CardInterface {
  title: string;
  body: string;
  img: string;
  alt: string;
}

const Card: FC<any> = (props: CardInterface) => {
  return (
    <div className="mp__card-envvias-container">
      <div className="px-5 pb-5 m-auto">
        <div className="card-img pt-5">
          <img src={props.img} alt={props.alt} />
        </div>
        <div className="mp__card-envvias-title mb-2 font-bold pt-1">{props.title}</div>
        <div className="mp__card-envvias-body">{props.body}</div>
      </div>
    </div>
  );
};

export default Card;
