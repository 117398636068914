import React from 'react';
import { useNavigate } from 'react-router-dom';

import './index.css';

import Panel from '../../../components/Panel';
import PanelImage from '../../../assets/svg/panelImage2.svg';
import AuthService from '../../../services/AuthService';
import { gtmAttributesUpdatePasErrorPage } from '../../../utils/gtmVariables';
import useGTMEventsTracker from '../../../hooks/useGTMEventsTracker';

const ErrorScreen = () => {
  const navigate = useNavigate();
  const gtmEventsTracker = useGTMEventsTracker();
  gtmEventsTracker(gtmAttributesUpdatePasErrorPage);

  const logout = () => {
    AuthService.logout();
    navigate('/', { replace: true });
  };

  return (
    <div className="mp__pas-panel_wrapper">
      <Panel
        panelType="confirmation"
        title="Vamos configurar a área da sua loja"
        className="mp__pas-panel"
        description={
          <>
            Nossa equipe vai finalizar a configuração da área de gestão da sua loja. Assim que
            estiver tudo pronto,{' '}
            <strong>
              vamos enviar um email pra avisar que você já pode acessar o Portal do lojista{' '}
            </strong>
            pra cadastrar os produtos e começar a vender no nosso marketplace.{' '}
          </>
        }
        image={PanelImage}
        buttonText="Fechar e sair"
        onClick={logout}
        buttonId="mp__pas-error-screen_button"
      />
    </div>
  );
};

export default ErrorScreen;
