import React from 'react';
import styled from './index.styles';

interface Props {
  text: string | object;
  fatherClassName?: string;
  className?: string;
  children?: React.ReactNode;
}

const TextStyles = ({
  text,
  fatherClassName = '',
  className = 'text-xs font-normal font-["Poppins"] text-white',
  children,
}: Props) => {
  return (
    <styled.Container className={`${fatherClassName}`}>
      <styled.Text className={`${className}`}>
        <>
          {text} {children}
        </>
      </styled.Text>
    </styled.Container>
  );
};

export default TextStyles;
