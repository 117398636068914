import React, { ChangeEventHandler, FC } from 'react';
import './KnowMore.css';

interface KnowMoreCardInterface {
  link: string;
  text: string;
  onClick?: Function;
}

const KnowMoreCard: FC<KnowMoreCardInterface> = ({ link, text, onClick }) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener"
      className="know-more"
      onClick={() => onClick instanceof Function && onClick()}
    >
      {text}
    </a>
  );
};

export default KnowMoreCard;
